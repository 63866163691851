import React, { useCallback } from "react";
import { Box, Button, Layer, Text } from "grommet";
import { Trash } from "grommet-icons";
import { useOrganizationRemoveOwnerMutation } from "api";
import { useModal, useNotification } from "hooks";
import { tk, useTranslation } from "translations";

interface Props {
  organizationId: string;
  id: string;
  firstName: string;
  lastName: string;
}

export const ManagerRemove = ({ organizationId, id, firstName, lastName }: Props) => {
  const { t } = useTranslation();

  const [mutate] = useOrganizationRemoveOwnerMutation();
  const { visible, show, hide } = useModal();
  const notification = useNotification();

  const handleSubmit = useCallback(async () => {
    hide();
    try {
      await mutate({ variables: { input: { organization: organizationId, user: id } } });
      await notification.show(t(tk.organization.managers.remove.success));
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [hide, mutate, organizationId, id, notification, t]);

  return (
    <Box>
      <Box
        alignSelf={"end"}
        pad={"small"}
        hoverIndicator={true}
        round={"xsmall"}
        title={t(tk.organization.managers.remove.removeManager)}
        onClick={show}
      >
        <Trash size={"small"} />
      </Box>

      {visible && (
        <Layer onEsc={hide} onClickOutside={hide}>
          <Box width={"medium"} pad={"medium"} round={"xsmall"} elevation={"medium"}>
            <Box align={"start"}>
              <Text weight={"bold"}>{t(tk.organization.managers.remove.removeManager)}</Text>
            </Box>
            <Box margin={{ top: "small" }}>
              <Text size={"small"}>
                {t(tk.organization.managers.remove.confirm)}{" "}
                <strong>
                  {firstName} {lastName}
                </strong>
                ?
              </Text>
            </Box>
            <Box direction={"row"} justify={"end"} margin={{ top: "medium" }} gap={"small"}>
              <Button label={t(tk.common.cancel)} size={"small"} onClick={hide} />
              <Button
                label={t(tk.organization.managers.remove.removeManager)}
                size={"small"}
                primary={true}
                color={"status-critical"}
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  );
};
