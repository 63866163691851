import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useMe, useSpaceState } from "hooks";
import { mapConnection, sortByName, SpaceState, useOrganizationSpacesQuery } from "api";
import { tk, useTranslation } from "translations";
import { routes, setRouteParams, SpaceParams } from "routes";

type StateFilter = "all" | "concept" | "active" | "inactive";

export const useSpaces = () => {
  const { t } = useTranslation();

  useTitle(t(tk.spaces.heading) + t(tk.common.documentTitleSuffix));

  const history = useHistory();

  const { activeOrganization } = useMe();

  const { data, loading: loadingOrganizationSpaces } = useOrganizationSpacesQuery({
    variables: { id: activeOrganization?.id || "" },
    fetchPolicy: "cache-and-network",
    skip: !activeOrganization?.id,
  });

  const { setState, loading: loadingSetState } = useSpaceState();

  const loading = useMemo(
    () => loadingOrganizationSpaces || loadingSetState,
    [loadingOrganizationSpaces, loadingSetState]
  );

  const spaces = useMemo(() => {
    return sortByName(
      mapConnection(data?.organization?.spaces).map(({ members, managerCount, ...rest }) => ({
        ...rest,
        members: {
          regular: (members.totalCount || 0) - managerCount,
          manager: managerCount,
        },
      }))
    );
  }, [data]);

  const hasSpaces = useCallback(() => spaces.length > 0, [spaces]);

  /** Search */
  const [query, setQuery] = useState("");
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value), []);

  /** State filter */
  const [stateFilter, setStateFilter] = useState<StateFilter>("all");
  const [stateFilterVisible, setStateFilterVisible] = useState(false);

  const showStateFilter = useCallback(() => setStateFilterVisible(true), []);
  const hideStateFilter = useCallback(() => setStateFilterVisible(false), []);

  const handleChangeStateFilter = useCallback((value: string) => setStateFilter(value as StateFilter), []);

  /** Filter data */
  const filteredSpaces = useMemo(() => {
    if (!query && stateFilter === "all") return spaces;

    return spaces.filter(({ name, state }) => {
      let _query = !query ? true : name.toLowerCase().includes(query.toLowerCase());
      let _state =
        stateFilter === "all"
          ? true
          : stateFilter === "concept"
          ? state === SpaceState.Draft
          : stateFilter === "active"
          ? state === SpaceState.Active
          : state === SpaceState.Archived;

      return _query && _state;
    });
  }, [query, spaces, stateFilter]);

  /** Actions */
  const goToCreate = useCallback(() => history.push(routes.spacesCreate), [history]);

  const goToDetail = useCallback(
    (id: string) => {
      const route = setRouteParams<SpaceParams>(routes.space, { id });
      history.push(route);
    },
    [history]
  );

  const edit = useCallback(
    (id: string) => {
      const route = setRouteParams<SpaceParams>(routes.spaceEdit, { id });
      history.push(route);
    },
    [history]
  );

  const activate = useCallback(
    async (id: string) => {
      const from = spaces.find((s) => s.id === id)?.state;
      if (from) await setState(id, from, SpaceState.Active);
    },
    [setState, spaces]
  );

  const deactivate = useCallback(
    async (id: string) => {
      const from = spaces.find((s) => s.id === id)?.state;
      if (from) await setState(id, from, SpaceState.Archived);
    },
    [setState, spaces]
  );

  return {
    data: { filteredSpaces, hasSpaces },
    state: { loading, query, stateFilterVisible, stateFilter },
    handlers: {
      handleChangeSearch,
      handleChangeStateFilter,
      showStateFilter,
      hideStateFilter,
      goToCreate,
      goToDetail,
      edit,
      activate,
      deactivate,
    },
  };
};
