import { ApolloClient, ApolloLink, fromPromise, InMemoryCache } from "apollo-boost";
import { onError } from "apollo-link-error";
import { authLink } from "api/client/authLink";
import { refreshTokenHttpLink } from "api/client/httpLink";
import { clearToken, clearTokens, getRefreshToken, getToken, setToken } from "api/auth";
import { RefreshTokenDocument, RefreshTokenMutation, RefreshTokenMutationVariables } from "api/generated";
import { routes } from "routes";

const ERROR_EXPIRED_TOKEN = "Signature has expired";

const link = ApolloLink.from([authLink, refreshTokenHttpLink]);
const cache = new InMemoryCache();
const client = new ApolloClient({ link, cache });

let pendingRequests: (() => any)[] = [];
const appendPendingRequests = (resolve: (value: unknown) => any) => pendingRequests.push(() => resolve(null));

let isRefreshing = false;
const startRefreshing = () => (isRefreshing = true);
const finishRefreshing = () => (isRefreshing = false);

export const refreshTokenLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError || !graphQLErrors) return;

  for (let err of graphQLErrors) {
    if (err.message !== ERROR_EXPIRED_TOKEN) continue;

    let forward$;

    if (isRefreshing) {
      // Push incoming requests while refreshing token into queue to be resolved after the token is refreshed
      // The requests will be called only once to prevent infinite loop
      forward$ = fromPromise(new Promise(appendPendingRequests));
    } else {
      startRefreshing();

      clearToken();

      forward$ = fromPromise(
        fetchNewToken()
          .then((token) => {
            setToken(token);
            resolvePendingRequests();
            return token;
          })
          .catch(logout)
          .finally(finishRefreshing)
      ).filter((value) => Boolean(value));
    }

    // Set new token to pending requests and forward them
    return forward$.flatMap(() => {
      operation.setContext(({ headers = {} }: Record<string, any>) => {
        const token = getToken();
        return { headers: { ...headers, authorization: token ? `JWT ${token}` : "" } };
      });

      return forward(operation);
    });
  }
});

const resolvePendingRequests = (): void => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const fetchNewToken = async (): Promise<string> => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) throw new Error("no_refresh_token");

  const { data } = await client.mutate<RefreshTokenMutation, RefreshTokenMutationVariables>({
    mutation: RefreshTokenDocument,
    variables: { refreshToken },
  });

  return data?.tokenRefresh.token || "";
};

const logout = (): void => {
  clearTokens();
  window.location.replace(routes.login);
};
