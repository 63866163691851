import { useCallback, useMemo, useState } from "react";
import { useTitle } from "react-use";
import { useHistory } from "react-router-dom";
import { useActiveOrganizationChange, useDeleteOrganizationMember, useDeleteSpaceMember, useMe } from "hooks";
import { mapConnection, SpaceState, UserFragment, UserMembershipFragment, useUserQuery } from "api";
import { tk, useTranslation } from "translations";
import { MemberParams, routes, setRouteParams, SpaceParams } from "routes";

export const useMember = (id: string) => {
  const { t } = useTranslation();

  useTitle(t(tk.common.memberDetail) + t(tk.common.documentTitleSuffix));

  const history = useHistory();

  const { activeOrganization, activeOrganizationSpaceCount, refetch: refetchMe } = useMe();

  // On organization change, go to members
  useActiveOrganizationChange(() => history.replace(routes.members));

  const { data, loading, refetch } = useUserQuery({
    variables: { id, organization: activeOrganization?.id || "" },
    skip: !activeOrganization,
    fetchPolicy: "cache-and-network",
  });

  const { user, memberships, currentSpaceIds } = useMemo(() => {
    if (!data || !data.user) return { user: undefined, memberships: [], currentSpaceIds: [] };

    const user: UserFragment = data.user;

    const memberships = mapConnection<UserMembershipFragment>(data.user?.memberships || undefined).map(
      (membership) => ({
        id: membership.id,
        user: user,
        role: membership.role,
        position: membership.position,
        space: {
          id: membership.space.id,
          name: membership.space.name,
          logo: membership.space.logo,
          active: membership.space.state !== SpaceState.Archived,
        },
        contactGroups: mapConnection(membership.contactGroups || undefined)
          .map(({ name }) => name)
          .sort((a, b) => a.localeCompare(b))
          .join(", "),
      })
    );

    const currentSpaceIds = memberships.map(({ space }) => space.id);

    return { user, memberships, currentSpaceIds };
  }, [data]);

  const [membershipToEdit, setMembershipToEdit] = useState<typeof memberships[0] | undefined>(undefined);
  const handleEditMembershipCancel = useCallback(() => setMembershipToEdit(undefined), []);
  const handleEditMembershipSuccess = useCallback(() => setMembershipToEdit(undefined), []);
  const editMembership = useCallback(
    (id: string) => setMembershipToEdit(memberships.find((membership) => membership.id === id)),
    [memberships]
  );

  const canAddToSpace = useMemo(
    () => currentSpaceIds.length < activeOrganizationSpaceCount,
    [activeOrganizationSpaceCount, currentSpaceIds.length]
  );

  const handleDeleteOrganizationMemberSuccess = useCallback(async () => {
    await refetchMe();
    history.replace(routes.members);
  }, [history, refetchMe]);

  const deleteOrganizationMember = useDeleteOrganizationMember(
    !user ? [] : [user],
    [],
    handleDeleteOrganizationMemberSuccess
  );

  const deleteMember = useCallback(() => deleteOrganizationMember.deleteMember(id), [deleteOrganizationMember, id]);

  const handleDeleteSpaceMemberSuccess = useCallback(async () => {
    if (memberships.length === 1) {
      history.replace(routes.members);
      return;
    }

    await refetch();
  }, [history, memberships.length, refetch]);

  const deleteSpaceMember = useDeleteSpaceMember(memberships, [], handleDeleteSpaceMemberSuccess);

  const [memberToAddToSpace, setMemberToAddToSpace] = useState<UserFragment | undefined>(undefined);

  const addToSpace = useCallback(() => setMemberToAddToSpace(user), [user]);

  const handleAddMemberToSpaceCancel = useCallback(() => setMemberToAddToSpace(undefined), []);

  const handleAddMemberToSpaceSuccess = useCallback(async () => {
    await refetch();
    setMemberToAddToSpace(undefined);
  }, [refetch]);

  const goBack = useCallback(() => history.goBack(), [history]);

  const edit = useCallback(() => {
    const route = setRouteParams<MemberParams>(routes.memberEdit, { id });
    history.push(route);
  }, [history, id]);

  const goToSpace = useCallback(
    (id: string) => {
      const route = setRouteParams<SpaceParams>(routes.space, { id });
      history.push(route);
    },
    [history]
  );

  return {
    data: { user, memberships, currentSpaceIds, activeOrganizationId: activeOrganization?.id || "", canAddToSpace },
    state: { loading, memberToAddToSpace, membershipToEdit },
    handlers: {
      goBack,
      edit,
      deleteMember,
      goToSpace,
      addToSpace,
      editMembership,
      handleAddMemberToSpaceCancel,
      handleAddMemberToSpaceSuccess,
      handleEditMembershipCancel,
      handleEditMembershipSuccess,
    },
    deleteOrganizationMember,
    deleteSpaceMember,
  };
};
