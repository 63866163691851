import React, { useCallback, useRef, useState } from "react";
import { Box, Button, Drop, DropProps, Text } from "grommet";
import { tk, useTranslation } from "translations";
import { GroupsRowAction } from "components/Groups/GroupsRowAction";
import { AddCircle } from "grommet-icons";

const buttonId = "groups-drop-button";

interface Props {
  groups: { id: string; name: string }[];
  onAdd: (id: string) => any;
}

export const GroupUserAddByGroup = ({ groups, onAdd }: Props) => {
  const { t } = useTranslation();

  const dropTargetRef = useRef(null);

  const [dropVisible, setDropVisible] = useState(false);

  const handleClickButton = useCallback(() => setDropVisible((prevState) => !prevState), []);

  const handleEsc = useCallback(() => setDropVisible(false), []);

  const handleClickOutside = useCallback<Exclude<DropProps["onClickOutside"], undefined>>((e) => {
    if (!(e.target instanceof HTMLElement)) return;
    if (e.target.id !== buttonId) setDropVisible(false);
  }, []);

  const handleClickAdd = useCallback(
    (id: string) => {
      setDropVisible(false);
      onAdd(id);
    },
    [onAdd]
  );

  return (
    <Box width={"100%"} align={"start"}>
      <Button
        id={buttonId}
        label={t(tk.groups.detail.addMember.byExistingGroup)}
        size={"small"}
        primary={true}
        color={"neutral-3"}
        disabled={!groups.length}
        onClick={handleClickButton}
      />

      <Box ref={dropTargetRef} width={"100%"} margin={{ top: "xsmall" }} />

      {dropVisible && (
        <Drop
          align={{ top: "bottom", left: "left" }}
          target={dropTargetRef.current || undefined}
          onEsc={handleEsc}
          onClickOutside={handleClickOutside}
        >
          <Box height={{ min: "60px", max: "390px" }} overflow={{ vertical: "auto" }} pad={"small"}>
            {groups.map(({ id, name }) => (
              <Box
                key={id}
                flex={{ shrink: 0 }}
                width={"100%"}
                direction={"row"}
                justify={"between"}
                align={"center"}
                margin={{ vertical: "2px" }}
              >
                <Text size={"small"}>{name}</Text>
                <GroupsRowAction
                  label={t(tk.groups.detail.addMember.use)}
                  icon={<AddCircle size={"small"} />}
                  onClick={() => handleClickAdd(id)}
                />
              </Box>
            ))}
          </Box>
        </Drop>
      )}
    </Box>
  );
};
