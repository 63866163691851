import React, { useCallback } from "react";
import { Box, Button, Text } from "grommet";
import { Group, Home, Multiple, Nodes } from "grommet-icons";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { routes } from "routes";
import { useTranslation, tk } from "translations";

export const MainMenu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Box margin={{ top: "small" }}>
      <MenuButton
        submenu={false}
        icon={<Multiple />}
        label={t(tk.common.spaces)}
        route={routes.spaces}
        active={!!matchPath(pathname, { path: routes.spaces })}
      />
      <MenuButton
        submenu={true}
        icon={<Nodes />}
        label={t(tk.common.groups)}
        route={routes.groups}
        active={!!matchPath(pathname, { path: routes.groups })}
      />
      <MenuButton
        submenu={false}
        icon={<Group />}
        label={t(tk.common.members)}
        route={routes.members}
        active={!!matchPath(pathname, { path: routes.members })}
      />
      <MenuButton
        submenu={false}
        icon={<Home />}
        label={t(tk.common.organizationProfile)}
        route={routes.organization}
        active={!!matchPath(pathname, { path: routes.organization })}
      />
    </Box>
  );
};

interface MenuButtonProps
  extends React.PropsWithChildren<{
    submenu: boolean;
    icon?: JSX.Element;
    label: string;
    route: string;
    active: boolean;
  }> {}

const MenuButton = ({ submenu, icon, label, route, active, ...rest }: MenuButtonProps) => {
  const { push } = useHistory();

  const handleClick = useCallback(() => push(route), [push, route]);

  return (
    <Button active={active} hoverIndicator="background" {...rest} onClick={handleClick}>
      <Box
        margin={submenu ? { left: "small" } : undefined}
        direction="row"
        align="center"
        pad={{ horizontal: "medium", vertical: "xsmall" }}
      >
        {icon}
        <Text margin={{ left: "small" }}>{label}</Text>
      </Box>
    </Button>
  );
};
