import React, { useCallback } from "react";
import { Box, Button, Layer, Text } from "grommet";
import { tk, useTranslation } from "translations";

interface Props {
  open: boolean;
  rowsCount: number;
  handleClose: () => any;
  handleSubmit: (replace: boolean) => any;
}

export const CsvImportDialog = ({ open, rowsCount, handleClose, handleSubmit }: Props) => {
  const { t } = useTranslation();

  const handleClickReplace = useCallback(() => handleSubmit(true), [handleSubmit]);

  const handleClickAdd = useCallback(() => handleSubmit(false), [handleSubmit]);

  if (!open) return null;

  return (
    <Layer onEsc={handleClose} onClickOutside={handleClose}>
      <Box width={{ max: "550px" }} pad={"medium"} round={"xsmall"} elevation={"medium"}>
        <Box align={"start"}>
          <Text weight={"bold"}>{t(tk.membersAdd.selectMembers.csvImportDialog.heading)}</Text>
        </Box>

        <Box margin={{ top: "small" }}>
          <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvImportDialog.info)}</Text>
          <Text size={"small"} margin={{ top: "small" }}>
            <strong>{t(tk.membersAdd.selectMembers.csvImportDialog.foundRows)}</strong> {rowsCount}
          </Text>
        </Box>

        <Box direction={"row"} justify={"between"} margin={{ top: "medium" }} gap={"small"}>
          <Button label={t(tk.common.cancel)} size={"small"} onClick={handleClose} />

          <Box direction={"row"} gap={"small"}>
            <Button
              label={t(tk.membersAdd.selectMembers.csvImportDialog.replace)}
              size={"small"}
              primary={true}
              onClick={handleClickReplace}
            />
            <Button
              label={t(tk.membersAdd.selectMembers.csvImportDialog.add)}
              size={"small"}
              primary={true}
              onClick={handleClickAdd}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
