import { useCallback, useState } from "react";

export type SpaceMembership = {
  id: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
};

export const useDeleteSpaceMember = (members: SpaceMembership[], checked: string[], onSuccess: () => any) => {
  const [membersToDelete, setMembersToDelete] = useState<SpaceMembership[]>([]);

  const deleteMember = useCallback(
    (id: string) => {
      setMembersToDelete(members.filter((member) => member.id === id));
    },
    [members]
  );

  const deleteMembers = useCallback(() => {
    setMembersToDelete(members.filter((member) => checked.includes(member.id)));
  }, [checked, members]);

  const handleDeleteSpaceMemberCancel = useCallback(() => setMembersToDelete([]), []);

  const handleDeleteSpaceMemberSuccess = useCallback(() => {
    setMembersToDelete([]);
    onSuccess();
  }, [onSuccess]);

  return {
    membersToDelete,
    deleteMember,
    deleteMembers,
    handleDeleteSpaceMemberCancel,
    handleDeleteSpaceMemberSuccess,
  };
};
