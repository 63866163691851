import React, { memo } from "react";
import { BoxProps, Text } from "grommet";

interface Props {
  value: string;
  required?: boolean;
  margin?: BoxProps["margin"];
}

export const Header = memo(({ value, required = false, margin }: Props) => (
  <Text size={"xsmall"} weight={"bold"} margin={margin}>
    {value}
    {required && <span style={{ position: "relative", top: 2, fontSize: "18px" }}>*</span>}
  </Text>
));
