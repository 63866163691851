import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useAuth, useMeValidationLazyQuery } from "api";
import { routes } from "routes";
import { tk, useTranslation } from "translations";

const inputs = { email: "email", password: "password" };

type Value = typeof inputs;

const defaultValue = { [inputs.email]: "", [inputs.password]: "" } as Value;

export const useLogin = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useTitle(t(tk.login.heading) + t(tk.common.documentTitleSuffix));

  const { isLoggedIn, login, loading, resetStore } = useAuth();

  const [value, setValue] = useState<Value>(defaultValue);
  const [error, setError] = useState(false);
  const [permissionsError, setPermissionsError] = useState(false);

  const handleSuccess = useCallback(() => history.push(routes.dashboard), [history]);
  const handleError = useCallback(() => setError(true), []);

  const [meValidation] = useMeValidationLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.me?.ownedOrganizations.pageInfo.startCursor) return setPermissionsError(true);
      handleSuccess();
    },
  });

  const handleSubmit = useCallback(async () => {
    if (loading) return;

    await login(value.email, value.password, meValidation, handleError);
  }, [loading, login, value.email, value.password, meValidation, handleError]);

  const handleChange = useCallback((_value: any) => setValue(_value), []);

  /** Always reset apollo cache */
  useEffect(resetStore, [resetStore]);

  /** If logged in, redirect to dashboard */
  useEffect(() => (isLoggedIn() ? history.push(routes.dashboard) : undefined), [isLoggedIn, history]);

  return {
    inputs,
    state: {
      loading,
      value,
      error,
      permissionsError,
    },
    handlers: {
      handleChange,
      handleSubmit,
    },
  };
};
