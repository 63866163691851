import React from "react";
import { Anchor, Box, Button, Form, FormField, Image, Main, Text, TextInput } from "grommet";
import { Spinner, Tooltip } from "components";
import { useLogin } from "controllers";
import { useTooltip } from "hooks";
import { emailRegExp, getEnv } from "helpers";
import { useTranslation, tk } from "translations";
import logo from "../assets/images/moyobo.png";

const resetUrl = getEnv("REACT_APP_RESET_PASSWORD_URL");

export const Login = () => {
  const { t } = useTranslation();
  const tooltip = useTooltip();

  const { inputs, state, handlers } = useLogin();

  return (
    <Main align={"center"} fill={true} pad={"medium"}>
      <Text size={"large"}>{t(tk.login.heading)}</Text>

      <Image src={logo} margin={{ top: "large" }} width={200} />
      <Text margin={{ top: "small" }}>{t(tk.login.subheading)}</Text>

      <Box width="medium" margin={{ top: "large" }}>
        <Form
          value={state.value}
          validate={"blur"}
          messages={{ required: t(tk.form.messages.required), invalid: t(tk.form.messages.invalid) }}
          onChange={handlers.handleChange}
          onSubmit={handlers.handleSubmit}
        >
          <FormField
            name={inputs.email}
            label={t(tk.common.email)}
            required={true}
            validate={{ regexp: emailRegExp, message: t(tk.form.email.messages.invalid) }}
          >
            <TextInput name={inputs.email} type="email" disabled={state.loading} />
          </FormField>

          <FormField name={inputs.password} label={t(tk.form.password.label)} required={true}>
            <TextInput name={inputs.password} type="password" disabled={state.loading} />
          </FormField>

          <Anchor href={resetUrl} size={"small"} target={"_blank"} {...tooltip.targetProps}>
            {t(tk.form.password.reset)}
          </Anchor>

          <Tooltip value={t(tk.form.password.tooltip)} visible={tooltip.visible} target={tooltip.target} />

          {(state.error || state.permissionsError) && (
            <Box margin={{ top: "small" }}>
              <Text size={"small"} color={"status-critical"}>
                {state.error ? t(tk.login.messages.invalid) : t(tk.login.messages.permissions)}
              </Text>
            </Box>
          )}

          <Box direction={"row"} justify={"center"} gap={"small"} margin={{ top: "large" }}>
            <Button type="submit" primary={true} label={t(tk.common.login)} disabled={state.loading} />
            {state.loading && <Spinner />}
          </Box>
        </Form>
      </Box>
    </Main>
  );
};
