import React, { ComponentProps, memo, useCallback, useRef, useState } from "react";
import { Box, Drop, DropProps, Text } from "grommet";
import { tk, useTranslation } from "translations";
import { TagInput } from "components/TagInput";

interface Props {
  contactGroups: string;
  suggestions: string[];
  onChange: (value: string) => any;
}

export const ContactGroups = memo(({ contactGroups, suggestions, onChange }: Props) => {
  const { t } = useTranslation();

  const [tags, setTags] = useState<string[]>(
    contactGroups
      .split(",")
      .map((g) => g.trim())
      .filter((g) => g.length > 0)
  );

  const [dropVisible, setDropVisible] = useState(false);

  const dropTargetRef = useRef(null);

  const handleClick = useCallback(() => setDropVisible((prevState) => !prevState), []);

  const handleChange = useCallback<ComponentProps<typeof TagInput>["onChange"]>(
    (e) => {
      const _tags = e.target.value;
      setTags(_tags);
      onChange(_tags.join(", "));
    },
    [onChange]
  );

  const handleClickOutside = useCallback<Exclude<DropProps["onClickOutside"], undefined>>((e) => {
    if (!(e.target instanceof HTMLElement)) return;

    if (e.target.id !== "tags-preview" && e.target.id !== "tags-preview-box") setDropVisible(false);
  }, []);

  const handleEsc = useCallback(() => setDropVisible(false), []);

  return (
    <Box
      ref={dropTargetRef}
      id={"tags-preview-box"}
      border={true}
      round={"4px"}
      width={{ min: "140px", max: "140px" }}
      pad={"4px"}
      background={"white"}
      onClick={handleClick}
    >
      <Text id={"tags-preview"} size={"xsmall"} color={!tags.length ? "status-unknown" : undefined} truncate={true}>
        {tags.join(", ") || t(tk.common.groups)}
      </Text>
      {dropVisible && (
        <Drop
          align={{ top: "bottom", right: "right" }}
          target={dropTargetRef.current || undefined}
          onClickOutside={handleClickOutside}
          onEsc={handleEsc}
        >
          <Box width={"300px"}>
            <TagInput
              name={"groups-input"}
              value={tags}
              suggestions={suggestions}
              placeholder={t(tk.common.groups)}
              autoFocus={true}
              onChange={handleChange}
            />
          </Box>
        </Drop>
      )}
    </Box>
  );
});
