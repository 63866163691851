import React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import { Box, Collapsible, ResponsiveContext } from "grommet";
import { Menu } from "grommet-icons";
import * as Page from "pages";
import { Navigation, Notification } from "components";
import { useDashboard } from "hooks";
import { routes } from "routes";

interface Props extends RouteComponentProps {}

export const Dashboard = (props: Props) => {
  const { render, navigation, toggleNavigation } = useDashboard(props);

  if (!render) return null;

  return (
    <Box fill>
      <Notification />
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box direction={size === "small" ? "column" : "row"} flex={true} overflow={{ horizontal: "hidden" }}>
            {/** Left panel - Navigation */}
            {size !== "small" ? (
              <Box width={size === "medium" ? "275px" : "medium"} background={"light-2"} elevation={"small"}>
                <Navigation />
              </Box>
            ) : (
              <Box>
                <Box pad={"small"} onClick={toggleNavigation}>
                  <Menu />
                </Box>
                <Collapsible open={navigation}>
                  <Box fill={true} elevation={"small"}>
                    <Navigation />
                  </Box>
                </Collapsible>
              </Box>
            )}

            {/** Right panel - Content */}
            <Box flex align="center" justify="center">
              <Switch>
                <Route path={routes.organization} component={Page.Organization} exact={true} />
                <Route path={routes.organizationEdit} component={Page.OrganizationEdit} exact={true} />

                <Route path={routes.members} component={Page.Members} exact={true} />

                <Route path={routes.membersAddSelectSpace} component={Page.MembersAddSelectSpace} exact={true} />
                <Route path={routes.membersAddSelectMembers} component={Page.MembersAddSelectMembers} exact={true} />
                <Route path={routes.membersAddConfirm} component={Page.MembersAddConfirm} exact={true} />

                <Route path={routes.member} component={Page.Member} exact={true} />
                <Route path={routes.memberEdit} component={Page.MemberEdit} exact={true} />

                <Route path={routes.spaces} component={Page.Spaces} exact={true} />

                <Route path={routes.groups} component={Page.Groups} exact={true} />

                <Route path={routes.spacesCreate} component={Page.SpacesCreate} exact={true} />

                <Route path={routes.space} component={Page.Space} exact={true} />
                <Route path={routes.spaceEdit} component={Page.SpaceEdit} exact={true} />
              </Switch>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Box>
  );
};
