const base = {
  root: "/",
  login: "/login",
  dashboard: "/dashboard",
  error: "/error/:error",
};

const dashboard = {
  organization: base.dashboard + "/organization",
  organizationEdit: base.dashboard + "/organization/edit",
  members: base.dashboard + "/members",
  membersAddSelectSpace: base.dashboard + "/members/add/select-space",
  membersAddSelectMembers: base.dashboard + "/members/add/select-members",
  membersAddConfirm: base.dashboard + "/members/add/confirm",
  member: base.dashboard + "/members/:id",
  memberEdit: base.dashboard + "/members/:id/edit",
  spaces: base.dashboard + "/spaces",
  spacesCreate: base.dashboard + "/spaces/create",
  space: base.dashboard + "/spaces/:id",
  spaceEdit: base.dashboard + "/spaces/:id/edit",
  groups: base.dashboard + "/spaces/groups",
};

export type MemberParams = {
  id: string;
};

export type SpaceParams = {
  id: string;
};

export const routes = {
  ...base,
  ...dashboard,
};

export const setRouteParams = <Params extends { [key: string]: string }>(route: string, params: Params): string => {
  let url = route;
  for (let key of Object.keys(params)) {
    url = url.replace(`:${key}`, params[key].toString());
  }

  return url;
};
