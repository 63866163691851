import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import useStore, { createStore } from "global-hook-store";
import { Row } from "components/MembersAddTable/useTable";
import { routes } from "routes";

type Space = { id: string; name: string };

const membersAddStore = createStore(
  {
    space: undefined as Space | undefined,
    members: undefined as Row[] | undefined,
    origin: undefined as string | undefined,
  },
  {
    setSpace: (_state, space: Space | undefined) => ({ ..._state, space }),
    setMembers: (_state, members: Row[] | undefined) => ({ ..._state, members }),
    setOrigin: (_state, origin: string | undefined) => ({ ..._state, origin }),
    clear: () => ({ space: undefined, members: undefined, origin: undefined }),
  }
);

export const useMembersAddStore = (clear: boolean = false) => {
  const { state, actions } = useStore(membersAddStore);

  const { pathname } = useLocation();

  useEffect(() => {
    if (
      clear &&
      !matchPath(pathname, {
        path: [routes.membersAddSelectSpace, routes.membersAddSelectMembers, routes.membersAddConfirm],
        exact: true,
      })
    ) {
      actions.clear().finally();
    }
  }, [actions, clear, pathname]);

  return { ...state, ...actions };
};
