import React from "react";
import { Blank, IconProps } from "grommet-icons";

export const MaskOffIcon = (props: IconProps & React.SVGProps<SVGSVGElement>) => (
  <Blank {...props}>
    <svg
      viewBox="0 0 24 24"
      aria-hidden={true}
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd" }}
    >
      <path
        d="M12.878,15.876c-0.522,-0.311 -1.175,-0.311 -1.696,0c-1.161,0.982 -2.71,2.801 -4.354,2.801c-3.679,0 -6.078,-3.057 -6.078,-6.721c0.007,-3.646 3.014,-6.641 6.66,-6.633l9.18,0c3.646,-0.008 6.653,2.987 6.66,6.633c0,3.664 -2.399,6.721 -6.078,6.721c-1.642,0 -3.135,-1.821 -4.294,-2.801Zm-2.541,-4.107c-0.686,-0.863 -1.73,-1.366 -2.833,-1.366c-1.094,0 -2.13,0.495 -2.818,1.344c-0.068,0.069 -0.107,0.162 -0.107,0.259c0,0.097 0.039,0.19 0.107,0.259c0.697,0.844 1.736,1.334 2.831,1.334c1.099,0 2.142,-0.493 2.838,-1.344c0.12,-0.141 0.112,-0.353 -0.018,-0.486Zm3.38,-0.022c0.687,-0.849 1.723,-1.344 2.817,-1.344c1.102,0 2.146,0.503 2.834,1.366c0.129,0.133 0.135,0.345 0.015,0.486c-0.696,0.851 -1.74,1.344 -2.838,1.344c-1.095,0 -2.134,-0.49 -2.831,-1.334c-0.067,-0.069 -0.105,-0.161 -0.105,-0.256c0,-0.098 0.04,-0.191 0.109,-0.26l-0.001,-0.002Z"
        style={{ fill: "none", strokeWidth: 2 }}
      />
    </svg>
  </Blank>
);
