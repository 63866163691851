import React, { useEffect, useMemo } from "react";
import { Box, Button, DataTable, DataTableProps } from "grommet";
import { Add, Edit, Save, Trash } from "grommet-icons";
import { ActionsMenu } from "components/ActionsMenu";
import { Header } from "components/MembersAddTable/Header";
import { Value } from "components/MembersAddTable/Value";
import { Input } from "components/MembersAddTable/Input";
import { RoleSelect } from "components/MembersAddTable/RoleSelect";
import { ContactGroups } from "components/MembersAddTable/ContactGroups";
import { Row, useTable } from "components/MembersAddTable/useTable";
import { Roles } from "api";
import { tk, useTranslation } from "translations";

const requiredFields: (keyof Row)[] = ["firstName", "lastName", "email", "role", "contactGroups"];

interface Props {
  defaultData?: Row[];
  importedData?: Row[];
  spaceGroups: string[];
  disabled?: boolean;
  onChange: (data: Row[]) => any;
  onAddRow?: () => any;
}

export const EditableTable = ({ defaultData, importedData, spaceGroups, disabled, onChange, onAddRow }: Props) => {
  const { t } = useTranslation();

  const { rows, edit, handleRowChange, editRow, addRow, deleteRow, saveRow } = useTable(
    defaultData,
    importedData,
    onChange,
    onAddRow
  );

  useEffect(() => {
    if (disabled && edit !== undefined) saveRow();
  }, [edit, disabled, saveRow]);

  const columns = useMemo<DataTableProps["columns"]>(() => {
    return [
      {
        property: "id",
        align: "center",
        header: <Header value={t(tk.membersAdd.table.header.id)} margin={{ left: "xsmall" }} />,
        render: ({ id }: Row) => <Value value={id} />,
      },
      columnConfig("firstName", t(tk.membersAdd.table.header.firstName), handleRowChange, edit),
      columnConfig("lastName", t(tk.membersAdd.table.header.lastName), handleRowChange, edit),
      columnConfig("email", t(tk.membersAdd.table.header.email), handleRowChange, edit),
      columnConfig("phoneNumber", t(tk.membersAdd.table.header.phoneNumber), handleRowChange, edit),
      columnConfig("notificationNumber", t(tk.membersAdd.table.header.notificationNumber), handleRowChange, edit),
      columnConfig("position", t(tk.membersAdd.table.header.position), handleRowChange, edit, "start", 50),
      {
        property: "role",
        header: <Header value={t(tk.common.role)} required={true} />,
        render: ({ id, role }: Row) => {
          if (id === edit) {
            return <RoleSelect role={role} onChange={(value) => handleRowChange(id, "role", value)} />;
          }

          return <Value value={t(tk.role[role as Roles])} />;
        },
      },
      {
        property: "contactGroups",
        header: <Header value={t(tk.common.groups)} required={true} />,
        align: "start",
        render: ({ id, contactGroups }) => {
          if (id === edit)
            return (
              <ContactGroups
                contactGroups={contactGroups}
                suggestions={spaceGroups}
                onChange={(value) => handleRowChange(id, "contactGroups", value)}
              />
            );

          return <Value value={contactGroups} />;
        },
      },
      {
        property: "actions",
        align: "center",
        header: <Header value={t(tk.membersAdd.table.header.actions)} margin={{ right: "xsmall" }} />,
        render: ({ id }: Row) => (
          <ActionsMenu
            id={id}
            items={[
              {
                label: t(tk.common.save),
                icon: <Save size={"small"} />,
                disabled: id !== edit || disabled,
                onClick: saveRow,
              },
              {
                label: t(tk.common.edit),
                icon: <Edit size={"small"} />,
                disabled: id === edit || disabled,
                onClick: editRow,
              },
              { label: t(tk.common.delete), icon: <Trash size={"small"} />, disabled, onClick: deleteRow },
            ]}
            align={{ top: "bottom" }}
          />
        ),
      },
    ];
  }, [t, handleRowChange, edit, spaceGroups, saveRow, editRow, deleteRow, disabled]);

  return (
    <Box margin={{ top: "medium" }} flex={"grow"}>
      <Box
        elevation={"small"}
        round={"xsmall"}
        style={{ whiteSpace: "nowrap" }}
        overflow={{ horizontal: "auto", vertical: "visible" }}
      >
        <DataTable
          columns={columns}
          data={rows}
          pad={{ vertical: "small", horizontal: "xsmall" }}
          border={{ body: { side: "bottom", color: "light-4" }, header: { side: "bottom", color: "light-4" } }}
          rowProps={{ [edit || "never"]: { background: "light-1" } }}
          replace={true}
          step={rows.length >= 50 ? 50 : 49}
        />
      </Box>

      <Box align={"start"} margin={{ top: "small", bottom: "xxsmall" }}>
        <Button
          label={t(tk.membersAdd.selectMembers.addRow)}
          icon={<Add size={"small"} />}
          primary={true}
          size={"small"}
          disabled={disabled}
          onClick={addRow}
        />
      </Box>
    </Box>
  );
};

const columnConfig = (
  property: keyof Row,
  header: string,
  onChange: (id: string, property: keyof Row, value: string) => any,
  edit?: string | "never",
  align: "center" | "start" | "end" = "start",
  maxLength?: number
): {
  align?: "center" | "start" | "end";
  header?: string | React.ReactNode | { aggregate?: boolean };
  property: string;
  render?: (...args: any[]) => any;
} => ({
  property: property,
  header: <Header value={header} required={requiredFields.includes(property)} />,
  align: align,
  render: (row: Row) => {
    if (edit === row.id) {
      return (
        <Input
          property={property}
          value={row[property]}
          placeholder={header}
          maxLength={maxLength}
          onChange={(value) => onChange(row.id, property, value)}
        />
      );
    }

    return <Value value={row[property]} />;
  },
});
