import { useCallback, useMemo, useState } from "react";

export const useTooltip = () => {
  const [visible, setVisible] = useState(false);
  const [target, setTarget] = useState<any>(null);

  const targetRef = useCallback((ref: unknown) => setTarget(ref), []);
  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);

  const targetProps = useMemo(
    () => ({
      ref: targetRef,
      onMouseOver: show,
      onMouseLeave: hide,
    }),
    [hide, show, targetRef]
  );

  return { visible, target, targetProps };
};
