import React from "react";
import { Box, BoxProps, Image, Text } from "grommet";

interface Props {
  color: string;
  background?: string;
  logo?: string;
  name: string;
  officialName: string;
  margin?: BoxProps["margin"];
}

export const SpaceHeader = ({ color, background, logo, name, officialName, margin }: Props) => {
  // Inline styles to match Mobile App

  return (
    <Box
      align={"center"}
      justify={"center"}
      width={"350px"}
      background={{ color, image: `url(${background})`, position: "top" }}
      round={"xsmall"}
      margin={margin}
      pad={{ horizontal: "30px", top: "35px", bottom: "30px" }}
    >
      <Image src={logo} height={89} />

      <Text
        size={"xlarge"}
        textAlign={"center"}
        color={"white"}
        margin={{ top: "14px" }}
        style={{ fontSize: "38px", lineHeight: "44px", fontWeight: 900 }}
      >
        {name}
      </Text>

      <Text
        textAlign={"center"}
        color={"white"}
        margin={{ top: "8px" }}
        style={{ fontSize: "16px", lineHeight: "24px", fontWeight: 400 }}
      >
        {officialName}
      </Text>
    </Box>
  );
};
