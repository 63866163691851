import React, { useMemo } from "react";
import { Box, DataTable, DataTableProps } from "grommet";
import { Header } from "components/MembersAddTable/Header";
import { Value } from "components/MembersAddTable/Value";
import { Row } from "components/MembersAddTable/useTable";
import { Roles } from "api";
import { tk, useTranslation } from "translations";
import { boxAnimation } from "styles";

interface Props {
  data: Row[];
}

export const SimpleTable = ({ data }: Props) => {
  const { t } = useTranslation();

  const columns = useMemo<DataTableProps["columns"]>(() => {
    return [
      {
        property: "id",
        align: "center",
        header: <Header value={t(tk.membersAdd.table.header.id)} margin={{ left: "xsmall" }} />,
        render: ({ id }: Row) => <Value value={id} />,
      },
      columnConfig("firstName", t(tk.membersAdd.table.header.firstName)),
      columnConfig("lastName", t(tk.membersAdd.table.header.lastName)),
      columnConfig("email", t(tk.membersAdd.table.header.email)),
      columnConfig("phoneNumber", t(tk.membersAdd.table.header.phoneNumber)),
      columnConfig("notificationNumber", t(tk.membersAdd.table.header.notificationNumber)),
      columnConfig("position", t(tk.membersAdd.table.header.position)),
      {
        property: "role",
        header: <Header value={t(tk.common.role)} />,
        render: ({ role }: Row) => <Value value={t(tk.role[role as Roles])} />,
      },
      columnConfig("contactGroups", t(tk.common.groups)),
    ];
  }, [t]);

  return (
    <Box margin={{ top: "medium", bottom: "xsmall" }} flex={"grow"}>
      <Box
        elevation={"small"}
        round={"xsmall"}
        animation={boxAnimation}
        overflow={{ horizontal: "auto", vertical: "visible" }}
        style={{ whiteSpace: "nowrap" }}
      >
        <DataTable
          columns={columns}
          data={data}
          pad={{ vertical: "small", horizontal: "xsmall" }}
          border={{ body: { side: "bottom", color: "light-4" }, header: { side: "bottom", color: "light-4" } }}
          replace={true}
          step={50}
        />
      </Box>
    </Box>
  );
};

const columnConfig = (
  property: keyof Row,
  header: string,
  align: "center" | "start" | "end" = "start"
): {
  align?: "center" | "start" | "end";
  header?: string | React.ReactNode | { aggregate?: boolean };
  property: string;
  render?: (...args: any[]) => any;
} => ({
  property: property,
  header: <Header value={header} />,
  align: align,
  render: (row: Row) => <Value value={row[property]} />,
});
