import { useCallback, useMemo } from "react";
import { useNotification } from "hooks";
import { SpaceState, useSpaceArchiveMutation, useSpacePublishMutation, useSpaceUnarchiveMutation } from "api";
import { tk, useTranslation } from "translations";

export const useSpaceState = () => {
  const { t } = useTranslation();
  const notification = useNotification();

  const [publish, { loading: loadingPublish }] = useSpacePublishMutation();
  const [archive, { loading: loadingArchive }] = useSpaceArchiveMutation();
  const [unarchive, { loading: loadingUnarchive }] = useSpaceUnarchiveMutation();

  const loading = useMemo(() => loadingPublish || loadingArchive || loadingUnarchive, [
    loadingArchive,
    loadingPublish,
    loadingUnarchive,
  ]);

  const setState = useCallback(
    async (id: string, from: SpaceState, to: SpaceState) => {
      try {
        if (from === SpaceState.Draft && to === SpaceState.Active) {
          await publish({ variables: { input: { space: id } } });
          await notification.show(t(tk.space.notifications["stateChangeActive"]));
        }

        if (from === SpaceState.Active && to === SpaceState.Archived) {
          await archive({ variables: { input: { space: id } } });
          await notification.show(t(tk.space.notifications["stateChangeInactive"]));
        }

        if (from === SpaceState.Archived && to === SpaceState.Active) {
          await unarchive({ variables: { input: { space: id } } });
          await notification.show(t(tk.space.notifications["stateChangeActive"]));
        }
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [archive, notification, publish, t, unarchive]
  );

  return { setState, loading };
};
