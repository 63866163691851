import React, { ChangeEvent, FocusEvent, memo, useCallback, useMemo, useState } from "react";
import { TextInput } from "grommet";
import { Row } from "components/MembersAddTable/useTable";
import { isEmail, isPhoneNumber } from "helpers";
import { theme } from "styles";

interface Props {
  property: keyof Row;
  value: string;
  placeholder: string;
  onChange: (value: string) => any;
  maxLength?: number;
}

export const Input = memo(({ property, value, placeholder, maxLength, onChange }: Props) => {
  const [valid, setValid] = useState(true);

  const type = useMemo(() => {
    if (property === "email") return "email";
    if (property === "phoneNumber") return "tel";
    if (property === "notificationNumber") return "tel";
    return "text";
  }, [property]);

  const required = useMemo(
    () => (["firstName", "lastName", "email", "role", "contactGroups"] as (keyof Row)[]).includes(property),
    [property]
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value), [onChange]);

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;

      if (required && !value) return setValid(false);
      if (property === "email" && !isEmail(value)) return setValid(false);
      if (property === "phoneNumber" && !isPhoneNumber(value)) return setValid(false);
      if (property === "notificationNumber" && !isPhoneNumber(value)) return setValid(false);

      setValid(true);
    },
    [property, required]
  );

  const style = useMemo<React.CSSProperties>(() => {
    const borderColor = theme.global?.colors ? (theme.global.colors["status-critical"] as string) : "red";
    return { minWidth: 60, maxWidth: 200, padding: 4, background: "white", ...(valid ? {} : { borderColor }) };
  }, [valid]);

  return (
    <TextInput
      type={type}
      value={value}
      placeholder={placeholder}
      required={required}
      size={"xsmall"}
      style={style}
      autoFocus={property === "firstName"}
      maxLength={maxLength}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});
