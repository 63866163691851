import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Button, Form, FormField, Image, Main, Text, TextArea, TextInput } from "grommet";
import { useMemberEdit } from "controllers";
import { ResponsiveContainer, Spinner, Tooltip } from "components";
import { tk, useTranslation } from "translations";
import { MemberParams } from "routes";
import { useTooltip } from "hooks";
import { CircleQuestion } from "grommet-icons";
import { phoneNumberRegExp } from "helpers";

interface Props extends RouteComponentProps<MemberParams> {}

export const MemberEdit = ({ match }: Props) => {
  const { t } = useTranslation();

  const { inputs, media, data, state, handlers } = useMemberEdit(match.params.id);

  const emailTooltip = useTooltip();
  const notificationNumberTooltip = useTooltip();

  if (!data.user) return null;

  return (
    <Main align={"center"} pad={"medium"}>
      <Text size={"large"}>{t(tk.common.editMember)}</Text>

      <ResponsiveContainer width={"small"} align={"center"} margin={{ top: "medium" }}>
        <Text size={"small"} alignSelf={"start"} margin={{ left: "small" }}>
          <strong>{t(tk.common.logo)}</strong> {t(tk.memberEdit.form.logo.info)}
        </Text>

        <Image src={media.preview || data.user.profileImage} height={120} margin={{ top: "small" }} />

        <input {...media.fileInputProps} />

        <Button
          size={"medium"}
          primary={true}
          disabled={state.loading}
          label={t(tk.common.uploadImage)}
          margin={{ top: "small" }}
          onClick={media.openFileBrowser}
        />

        <Box width={"100%"} margin={{ top: "medium" }}>
          <Form
            value={state.value}
            validate={"blur"}
            messages={{ required: t(tk.form.messages.required), invalid: t(tk.form.messages.invalid) }}
            onChange={handlers.handleChange}
            onSubmit={handlers.handleSubmit}
          >
            <Box direction={"row"} gap={"medium"} width={"100%"}>
              <Box flex={true}>
                <FormField name={inputs.firstName} label={t(tk.common.firstName) + "*"} required={true}>
                  <TextInput name={inputs.firstName} disabled={state.loading} />
                </FormField>
              </Box>
              <Box flex={true}>
                <FormField name={inputs.lastName} label={t(tk.common.lastName) + "*"} required={true}>
                  <TextInput name={inputs.lastName} disabled={state.loading} />
                </FormField>
              </Box>
            </Box>

            <FormField
              name={inputs.email}
              label={
                <Box direction={"row"} align={"center"} width={"xsmall"} {...emailTooltip.targetProps}>
                  <Text size={"small"} weight={"bold"} margin={{ right: "xxsmall" }}>
                    {t(tk.common.email)}
                  </Text>
                  <CircleQuestion size={"small"} />
                  <Tooltip
                    value={t(tk.memberEdit.form.email.tooltip)}
                    visible={emailTooltip.visible}
                    target={emailTooltip.target}
                    align={{ left: "left", top: "bottom" }}
                    maxWidth={"255px"}
                  />
                </Box>
              }
              required={true}
            >
              <TextInput name={inputs.email} disabled={true} />
            </FormField>

            <FormField
              name={inputs.phoneNumber}
              label={t(tk.common.phoneNumber)}
              validate={{ regexp: phoneNumberRegExp, message: t(tk.form.phone.messages.invalid) }}
            >
              <TextInput name={inputs.phoneNumber} disabled={state.loading} />
            </FormField>

            <FormField
              name={inputs.notificationNumber}
              label={
                <Box direction={"row"} align={"center"} width={"small"} {...notificationNumberTooltip.targetProps}>
                  <Text size={"small"} weight={"bold"} margin={{ right: "xxsmall" }}>
                    {t(tk.common.notificationNumber)}
                  </Text>
                  <CircleQuestion size={"small"} />
                  <Tooltip
                    value={t(tk.memberEdit.form.notificationNumber.tooltip)}
                    visible={notificationNumberTooltip.visible}
                    target={notificationNumberTooltip.target}
                    align={{ left: "left", top: "bottom" }}
                    maxWidth={"255px"}
                  />
                </Box>
              }
              validate={{ regexp: phoneNumberRegExp, message: t(tk.form.phone.messages.invalid) }}
            >
              <TextInput name={inputs.notificationNumber} disabled={state.loading} />
            </FormField>

            <FormField name={inputs.about} label={t(tk.common.aboutMe)}>
              <TextArea name={inputs.about} maxLength={10000} disabled={state.loading} />
            </FormField>

            <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "medium" }}>
              <Button
                label={t(tk.common.cancel)}
                size={"medium"}
                disabled={state.loading}
                onClick={handlers.handleCancel}
              />

              <Button type="submit" primary={true} size={"medium"} disabled={state.loading} label={t(tk.common.save)} />

              {state.loading && <Spinner />}
            </Box>
          </Form>
        </Box>
      </ResponsiveContainer>
    </Main>
  );
};
