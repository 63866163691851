import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Text } from "grommet";
import { Add } from "grommet-icons";
import { Group, Space } from "controllers";
import { GroupAddRow, GroupRow, Spinner, Tooltip } from "components";
import { useTooltip } from "hooks";
import { tk, useTranslation } from "translations";

interface Props {
  space?: Space;
  groups: Group[];
  group?: Group;
  loadingGroups?: boolean;
  onGroupAdd: (name: string) => any;
  onGroupSelect: (group: Group) => any;
  onGroupRename: (id: string, name: string) => any;
  onGroupTogglePrivate: (id: string, value: boolean) => any;
  onGroupDelete: (id: string) => any;
}

export const GroupList = ({
  space,
  groups,
  group,
  loadingGroups = false,
  onGroupAdd,
  onGroupSelect,
  onGroupRename,
  onGroupTogglePrivate,
  onGroupDelete,
}: Props) => {
  const { t } = useTranslation();
  const tooltip = useTooltip();

  const [add, setAdd] = useState(false);

  const handleClickAdd = useCallback(() => (!add ? setAdd(true) : undefined), [add]);
  const handleAddCancel = useCallback(() => setAdd(false), []);
  const handleAddSave = useCallback(
    (name: string) => {
      setAdd(false);
      onGroupAdd(name);
    },
    [onGroupAdd]
  );

  const noSpaceSelected = useMemo(() => !space, [space]);
  const loading = useMemo(() => loadingGroups, [loadingGroups]);
  const noGroupsInSpace = useMemo(() => !loadingGroups && !!space && !groups.length, [
    groups.length,
    loadingGroups,
    space,
  ]);
  const renderGroups = useMemo(() => !loadingGroups && groups.length > 0, [groups.length, loadingGroups]);

  return (
    <Box elevation={"small"} round={"xsmall"} margin={{ top: "medium" }} pad={"medium"}>
      {/** Header */}
      <Box direction={"row"} justify={"between"} align={"center"}>
        <Text size={"small"} weight={"bold"}>
          {t(tk.groups.groups.heading)}
        </Text>

        <Tooltip value={t(tk.groups.groups.addTooltip)} visible={!space && tooltip.visible} target={tooltip.target} />
        <Button
          label={t(tk.groups.groups.add)}
          icon={<Add size={"small"} />}
          size={"small"}
          plain={true}
          disabled={!space}
          onClick={handleClickAdd}
          {...tooltip.targetProps}
        />
      </Box>

      {/** List */}
      <Box
        align={"center"}
        justify={(!groups.length || loadingGroups) && !add ? "center" : "start"}
        height={{ min: "small" }}
        margin={{ top: "small" }}
      >
        {noSpaceSelected && <Text size={"small"}>{t(tk.groups.groups.noSpace)}</Text>}

        {loading && <Spinner />}

        {noGroupsInSpace && !add && <Text size={"small"}>{t(tk.groups.groups.noGroups)}</Text>}

        {add && <GroupAddRow onSave={handleAddSave} onCancel={handleAddCancel} />}

        {renderGroups &&
          groups.map((g) => (
            <GroupRow
              key={g.id}
              group={g}
              active={g.id === group?.id}
              onSelect={onGroupSelect}
              onRename={onGroupRename}
              onTogglePrivate={onGroupTogglePrivate}
              onDelete={onGroupDelete}
            />
          ))}
      </Box>
    </Box>
  );
};
