import { useCallback, useEffect, useState } from "react";
import { matchPath, RouteComponentProps } from "react-router-dom";
import { useAuth } from "api";
import { useMe, useMembersAddStore } from "hooks";
import { routes } from "routes";

export const useDashboard = ({ history, location }: RouteComponentProps) => {
  const [render, setRender] = useState(false);

  /** mobile menu open state */
  const [navigation, setNavigation] = useState(false);
  const toggleNavigation = useCallback(() => setNavigation((navigation) => !navigation), []);

  const { isLoggedIn } = useAuth();
  const { me } = useMe(true);

  /** clears members add process store if user leaves the process */
  useMembersAddStore(true);

  useEffect(() => {
    /** if not logged in, redirect to login */
    if (!isLoggedIn()) return history.replace(routes.login);

    /** redirect to organization from dashboard root url (after login) */
    const isDashboardRoot = !!matchPath(location.pathname, { path: routes.dashboard, exact: true });
    if (isDashboardRoot) history.replace(routes.organization);
  }, [history, isLoggedIn, location.pathname]);

  /** render after me is ready */
  useEffect(() => {
    if (!me) return;

    setRender(true);
  }, [me]);

  return { render, navigation, toggleNavigation };
};
