import React, { useCallback, useState } from "react";
import { Box, Button, Image, Layer, Text } from "grommet";
import { tk, useTranslation } from "translations";
import { Trans } from "react-i18next";

const CSV_TEMPLATE_URL = process.env.PUBLIC_URL + "/csv/moyobo_import_<locale>.csv";

const getCsvUrl = (locale: string) => CSV_TEMPLATE_URL.replace("<locale>", locale);

interface Props {
  open: boolean;
  handleClose: () => any;
}

export const CsvDownloadDialog = ({ open, handleClose }: Props) => {
  const { t, i18n } = useTranslation();

  const [tab, setTab] = useState<0 | 1>(0);
  const toggleTab = useCallback(() => setTab((t) => (t === 0 ? 1 : 0)), []);

  const locale = i18n.language.substr(0, 2);

  if (!open) return null;

  return (
    <Layer margin={{ vertical: "small" }} onEsc={handleClose} onClickOutside={handleClose}>
      <Box width={{ max: "750px" }} pad={"medium"} round={"xsmall"} elevation={"medium"} overflow={"auto"}>
        <Box flex={"grow"}>
          <Box align={"start"}>
            <Text weight={"bold"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.heading)}</Text>
          </Box>

          <Text size={"small"} margin={{ top: "small" }}>
            <strong>{t(tk.membersAdd.selectMembers.csvDownloadDialog.requirementsInfo)}</strong>
          </Text>

          <ul>
            <li>
              <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.requirement1)}</Text>
            </li>
            <li>
              <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.requirement2)}</Text>
            </li>
            <li>
              <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.requirement3)}</Text>
            </li>
            <li>
              <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.requirement4)}</Text>
            </li>
          </ul>

          <Box direction={"row"} justify={"center"} gap={"xxsmall"}>
            <Button
              label={t(tk.membersAdd.selectMembers.csvDownloadDialog.importToExcel)}
              size={"small"}
              primary={true}
              color={"neutral-3"}
              active={tab !== 0}
              onClick={toggleTab}
            />
            <Button
              label={t(tk.membersAdd.selectMembers.csvDownloadDialog.importToNumbers)}
              size={"small"}
              primary={true}
              color={"neutral-3"}
              active={tab !== 1}
              onClick={toggleTab}
            />
          </Box>

          {/* Excel */}
          {tab === 0 && (
            <Box>
              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>
                  <Trans i18nKey={t(tk.membersAdd.selectMembers.csvDownloadDialog.excel1)} components={[<strong />]} />
                </Text>
                <Image src={require(`assets/images/csv/excel_1_${locale}.png`)} width={"100%"} />
              </Box>
              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>
                  <Trans i18nKey={t(tk.membersAdd.selectMembers.csvDownloadDialog.excel2)} components={[<strong />]} />
                </Text>
                <Image src={require(`assets/images/csv/excel_2_${locale}.png`)} width={"100%"} />
              </Box>
              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>
                  <Trans i18nKey={t(tk.membersAdd.selectMembers.csvDownloadDialog.excel3)} components={[<strong />]} />
                </Text>
                <Image src={require(`assets/images/csv/excel_3_${locale}.png`)} width={"100%"} />
              </Box>
              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>
                  <Trans i18nKey={t(tk.membersAdd.selectMembers.csvDownloadDialog.excel4)} components={[<strong />]} />
                </Text>
                <Image src={require(`assets/images/csv/excel_4_${locale}.png`)} width={"100%"} />
              </Box>
              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.excel5)}</Text>
              </Box>
            </Box>
          )}

          {/* Numbers */}
          {tab === 1 && (
            <Box>
              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>
                  <Trans
                    i18nKey={t(tk.membersAdd.selectMembers.csvDownloadDialog.numbers1)}
                    components={[<strong />]}
                  />
                </Text>
                <Image src={require(`assets/images/csv/numbers_1_${locale}.png`)} width={"100%"} />
              </Box>

              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.numbers2)}</Text>
              </Box>

              <Box>
                <Text size={"small"}>{"+420 123 456 789"}</Text>
                <Text size={"small"}>{"420 123 456 789"}</Text>
                <Text size={"small"}>{"00420 123 456 789"}</Text>
              </Box>

              <Box margin={{ top: "medium" }}>
                <Text size={"small"}>{t(tk.membersAdd.selectMembers.csvDownloadDialog.numbers3)}</Text>
              </Box>
            </Box>
          )}

          <Box direction={"row"} justify={"between"} margin={{ top: "medium" }} gap={"small"}>
            <Button label={t(tk.common.cancel)} size={"small"} onClick={handleClose} />
            <Button
              label={t(tk.membersAdd.selectMembers.downloadCSV)}
              size={"small"}
              primary={true}
              onClick={handleClose}
              href={getCsvUrl(locale)}
              target={"_blank"}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
