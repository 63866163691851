import React, { useMemo } from "react";
import { Box, Button, Main, Select, Text } from "grommet";
import { useMembersAddSelectSpace } from "controllers";
import { ProgressBar } from "components";
import { tk, useTranslation } from "translations";
import { boxAnimation } from "styles";

export const MembersAddSelectSpace = () => {
  const { t } = useTranslation();

  const { data, state, handlers } = useMembersAddSelectSpace();

  const options = useMemo(() => data.spaces.map(({ id, name }) => ({ value: id, label: name })), [data.spaces]);

  if (!options.length) return null;

  return (
    <Main align={"center"} pad={"medium"}>
      <Text size={"large"}>{t(tk.common.addMembersToSpace)}</Text>

      <ProgressBar
        steps={[t(tk.membersAdd.steps.space), t(tk.membersAdd.steps.members), t(tk.membersAdd.steps.confirm)]}
        current={0}
        margin={{ top: "medium" }}
      />

      <Box width={"large"} margin={{ top: "medium" }} animation={boxAnimation}>
        <Text size={"large"}>{t(tk.membersAdd.selectSpace.subheading)}</Text>
        <Text size={"small"} margin={{ top: "small" }}>
          {t(tk.membersAdd.selectSpace.info)}
        </Text>

        <Text size={"small"} weight={"bold"} margin={{ top: "medium", left: "small" }}>
          {t(tk.common.spaceName)}
        </Text>
        <Select
          id={"spaceSelect"}
          name={"spaceSelect"}
          placeholder={t(tk.common.selectSpace)}
          labelKey={"label"}
          valueKey={"value"}
          value={options.find(({ value }) => value === state.space)}
          options={options}
          margin={{ top: "xsmall" }}
          onChange={({ value }: { value: typeof options[0] }) => handlers.handleChange(value.value)}
        />

        <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "medium" }}>
          <Button label={t(tk.common.cancel)} size={"medium"} onClick={handlers.handleCancel} />

          <Button
            primary={true}
            size={"medium"}
            disabled={!state.space}
            label={t(tk.common.continue)}
            onClick={handlers.handleSubmit}
          />
        </Box>
      </Box>
    </Main>
  );
};
