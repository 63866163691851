import { useCallback, useState } from "react";

export type OrganizationMember = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const useDeleteOrganizationMember = (members: OrganizationMember[], checked: string[], onSuccess: () => any) => {
  const [membersToDelete, setMembersToDelete] = useState<OrganizationMember[]>([]);

  const deleteMember = useCallback(
    (id: string) => {
      setMembersToDelete(members.filter((member) => member.id === id));
    },
    [members]
  );

  const deleteMembers = useCallback(() => {
    setMembersToDelete(members.filter((member) => checked.includes(member.id)));
  }, [checked, members]);

  const handleDeleteMemberCancel = useCallback(() => setMembersToDelete([]), []);

  const handleDeleteMemberSuccess = useCallback(() => {
    setMembersToDelete([]);
    onSuccess();
  }, [onSuccess]);

  return {
    membersToDelete,
    deleteMember,
    deleteMembers,
    handleDeleteMemberCancel,
    handleDeleteMemberSuccess,
  };
};
