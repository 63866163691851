import React, { PropsWithChildren } from "react";
import { Box, BoxProps, ResponsiveContext } from "grommet";
import { boxAnimation } from "styles";

type Width = "small" | "medium" | "large" | "xlarge";

interface Props {
  margin?: BoxProps["margin"];
  align?: BoxProps["align"];
  width?: Width;
}

export const ResponsiveContainer = ({ children, margin, align, width = "small" }: PropsWithChildren<Props>) => {
  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box
          width={widths[width]}
          flex={{ shrink: 0 }}
          align={align}
          margin={margin}
          pad={{ horizontal: pads[size][width] }}
          animation={boxAnimation}
        >
          {children}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

/** Key is width prop */
const widths = { small: "large", medium: "large", large: "xlarge", xlarge: "xxlarge" };

/** First level is ResponsiveContext size, second level is width prop */
const pads: { [key: string]: { [key: string]: string } } = {
  large: { small: "xlarge", medium: "medium", large: "xlarge", xlarge: "medium" },
  medium: { small: "large", medium: "small", large: "medium", xlarge: "small" },
  small: { small: "medium", medium: "small", large: "small", xlarge: "small" },
};
