import { grommet, ThemeType } from "grommet/themes";
import { deepMerge } from "grommet/utils";
import { BoxProps } from "grommet";

export const boxAnimation: BoxProps["animation"] = { type: "fadeIn", duration: 400 };

export const theme: ThemeType = deepMerge(grommet, {
  global: {
    colors: {
      focus: "#0CABA065",
      "status-critical": "#AD2B2B",
    },
    font: {
      size: 16,
      family: "Raleway, Helvetica, sans-serif",
    },
    input: {
      weight: 300,
    },
  },
  formField: {
    round: "3px",
    border: {
      side: "all",
    },
    label: {
      size: "small",
      weight: "bold",
    },
    error: {
      size: "small",
    },
    info: {
      size: "small",
    },
  },
  button: {
    primary: {
      color: "#0A9187",
    },
    border: {
      radius: "5px",
      width: 0,
    },
    size: {
      small: {
        border: {
          radius: "5px",
        },
      },
      medium: {
        border: {
          radius: "5px",
        },
      },
    },
    padding: {
      vertical: "12px",
    },
    extend: {
      fontSize: "14px",
    },
  },
  anchor: {
    color: "#0A9187",
  },
  select: {
    container: {
      text: {
        size: "small",
      },
    },
    options: {
      container: {
        pad: { vertical: "xsmall", horizontal: "small" },
      },
      text: {
        size: "small",
      },
    },
  },
  checkBox: {
    size: "18px",
    border: {
      width: "1px",
    },
    check: {
      thickness: "2px",
    },
    hover: {
      border: {
        color: "dark-4",
      },
    },
    toggle: {
      background: "light-2",
      extend: {
        width: 57,
        height: 26,
        padding: "4px",
      },
    },
  },
  radioButton: {
    size: "18px",
    border: {
      width: "1px",
    },
    hover: {
      border: {
        color: "dark-4",
      },
    },
  },
});
