import { GraphQLError } from "graphql";
import { ApolloError } from "apollo-client";
import { reportToSentry, Severity } from "helpers";
import { Maybe } from "api";

type Error = GraphQLError & {
  code: string;
  errorType: string;
};

type ErrorHandler = () => any;

export const resolveError = (
  error: ApolloError,
  handlers?: { [code: string]: ErrorHandler },
  defaultErrorHandler?: ErrorHandler
): void => {
  if (!error.graphQLErrors || !error.graphQLErrors.length) return;

  const firstError = error.graphQLErrors[0] as Error;
  const handler = handlers && handlers[firstError.message];

  if (handler) {
    handler();
    return;
  }

  if (!defaultErrorHandler) {
    handleDefaultError(firstError);
    return;
  }

  defaultErrorHandler();
};

export const handleDefaultError = (e: Error) => {
  reportToSentry(`API error caught by handleDefaultError: ${e.message || "undefined"}`, Severity.Error);
};

export const mapConnection = <T>(connection?: { edges?: Maybe<{ node?: Maybe<T> }>[] }): T[] =>
  (connection?.edges?.filter((e) => !!e).map((e) => e?.node) as T[]) || [];

export const sortByName = <T>(collection: ({ name: string } & T)[]): T[] =>
  collection.sort(({ name: a }, { name: b }) => a.localeCompare(b));
