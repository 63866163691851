import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTitle, useUpdateEffect } from "react-use";
import { mapRowsToSimpleUsers } from "components/MembersAddTable/helpers";
import { useMe, useMembersAddStore, useNotification } from "hooks";
import { reportToSentry, Severity } from "helpers";
import { useSpaceInviteUsersMutation } from "api";
import { tk, useTranslation } from "translations";
import { routes } from "routes";

export const useMembersAddConfirm = () => {
  const { t } = useTranslation();

  useTitle(t(tk.common.addMembersToSpace) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const { show } = useNotification();

  const store = useMembersAddStore();
  const { activeOrganization } = useMe();

  const [invite, { loading }] = useSpaceInviteUsersMutation();

  const spaceName = useMemo(() => store.space?.name, [store]);
  const rows = useMemo(() => store.members, [store]);

  useEffect(() => {
    if (!store.space) history.replace(routes.membersAddSelectSpace);
    if (!store.members) history.replace(routes.membersAddSelectMembers);
  }, [history, store.members, store.space]);

  /** On activeOrganization change, redirect back to Members */
  useUpdateEffect(() => history.replace(routes.members), [activeOrganization, history]);

  const cancel = useCallback(() => history.replace(!store.origin ? routes.members : store.origin), [
    history,
    store.origin,
  ]);

  const back = useCallback(() => history.replace(routes.membersAddSelectMembers), [history]);

  const submit = useCallback(async () => {
    const space = store.space?.id;
    const data = store.members;

    if (!space || !data) return;

    const users = mapRowsToSimpleUsers(data);

    try {
      const { data } = await invite({ variables: { input: { space, users } } });

      const failedEmails = data?.spaceInviteUsers.failedEmails || [];

      if (failedEmails.length > 0) {
        reportToSentry(
          `Failed to invite some users to space (${space}). They were filtered by back-end.`,
          Severity.Warning
        );
      }

      await show(t(tk.membersAdd.confirm.notifications.success));
      history.replace(!store.origin ? routes.members : store.origin);
    } catch (e) {
      await show(t(tk.membersAdd.confirm.notifications.error), true);
    }
  }, [history, invite, show, store.members, store.origin, store.space, t]);

  return {
    data: { spaceName, rows },
    state: { loading },
    handlers: { cancel, back, submit },
  };
};
