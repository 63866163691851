import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Button, CheckBox, DataTable, DataTableProps, Image, Main, Select, Text, TextInput } from "grommet";
import { ContactInfo, Edit, FormSearch, Send, SubtractCircle, UserAdd } from "grommet-icons";
import { useSpace } from "controllers";
import {
  ActionsMenu,
  DataTableHeader,
  DeleteSpaceMember,
  EditMembership,
  Pagination,
  ResponsiveContainer,
  SpaceHeader,
  SpaceStateToggle,
  Spinner,
  Tooltip,
} from "components";
import { useTooltip } from "hooks";
import { tk, useTranslation } from "translations";
import { SpaceParams } from "routes";
import { SpaceState } from "api";

interface Props extends RouteComponentProps<SpaceParams> {}

export const Space = ({ match }: Props) => {
  const { t } = useTranslation();

  const tooltip = useTooltip();

  const { pagination, sorting, data, state, handlers, deleteSpaceMember } = useSpace(match.params.id);

  type Member = typeof data.members[0];

  const columns = useMemo<DataTableProps["columns"]>(() => {
    const checkedAll = state.checked.length === data.members.length;
    return [
      {
        property: "id",
        header: <CheckBox checked={checkedAll} disabled={!data.members.length} onChange={handlers.checkAll} />,
        sortable: false,
        render: ({ id }: Member) => (
          <CheckBox key={id} checked={state.checked.indexOf(id) !== -1} onChange={(e) => handlers.check(e, id)} />
        ),
      },
      {
        property: "member",
        header: (
          <DataTableHeader value={t(tk.common.member)} sortable={true} order={sorting.order} onClick={sorting.toggle} />
        ),
        render: ({ user }: Member) => (
          <Box direction={"row"} align={"center"} onClick={() => handlers.goToMemberDetail(user.id)}>
            <Image src={user.profileImage} height={40} />
            <Box margin={{ left: "small" }}>
              <Text size={"small"} color={textColor(user.isActive)}>
                {user.lastName} {user.firstName}
              </Text>
              <Text size={"xsmall"} color={textColor(user.isActive)}>
                {user.email}
              </Text>
            </Box>
          </Box>
        ),
      },
      {
        property: "role",
        header: <DataTableHeader value={t(tk.common.role)} />,
        align: "center",
        render: ({ role, user }: Member) => (
          <Text size={"small"} color={textColor(user.isActive)}>
            {t(tk.role[role])}
          </Text>
        ),
      },
      {
        property: "contactGroups",
        header: <DataTableHeader value={t(tk.common.group)} />,
        align: "center",
        render: ({ user, contactGroups }: Member) => (
          <Text size={"small"} color={textColor(user.isActive)}>
            {contactGroups || "-"}
          </Text>
        ),
      },
      {
        property: "actions",
        header: <DataTableHeader value={t(tk.common.actions)} />,
        align: "center",
        render: ({ id, user }: Member) => (
          <ActionsMenu
            id={id}
            items={[
              {
                label: t(tk.common.memberDetail),
                icon: <ContactInfo size={"small"} />,
                onClick: () => handlers.goToMemberDetail(user.id),
              },
              {
                label: t(tk.spaceMembers.actions.edit),
                icon: <Edit size={"small"} />,
                disabled: data.space?.state === SpaceState.Archived,
                onClick: handlers.editMembership,
              },
              ...(data.space?.state !== SpaceState.Draft && !user.isActive
                ? [
                    {
                      label: t(tk.common.sendInvitation),
                      icon: <Send size={"small"} />,
                      disabled: data.space?.state === SpaceState.Archived,
                      onClick: handlers.sendInvitation,
                    },
                  ]
                : []),
              {
                label: t(tk.spaceMembers.actions.remove),
                icon: <SubtractCircle size={"small"} />,
                disabled: data.space?.state === SpaceState.Archived,
                onClick: deleteSpaceMember.deleteMember,
              },
            ]}
            iconColor={textColor(user.isActive)}
          />
        ),
      },
    ];
  }, [
    data.members.length,
    data.space,
    deleteSpaceMember.deleteMember,
    handlers,
    sorting.order,
    sorting.toggle,
    state.checked,
    t,
  ]);

  if (!data.space) return null;

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>{t(tk.common.spaceDetail)}</Text>

      <ResponsiveContainer width={"large"} align={"center"} margin={{ top: "medium" }}>
        <Box direction={"row"} justify={"between"} align={"start"} width={"100%"} wrap={true}>
          <Box align={"start"} flex={true} width={{ min: "270px" }}>
            <Button label={t(tk.common.back)} size={"small"} onClick={handlers.goBack} />
          </Box>

          <Box align={"center"} flex={true} width={{ min: "350px" }}>
            <SpaceHeader
              name={data.space.name}
              officialName={data.space.officialName}
              color={data.space.color}
              background={data.space.header || undefined}
              logo={data.space.logo}
            />
            <Button
              label={t(tk.common.editSpace)}
              icon={<Edit size={"small"} />}
              primary={true}
              size={"medium"}
              margin={{ top: "small", bottom: "xsmall" }}
              disabled={data.space.state === SpaceState.Archived}
              onClick={handlers.edit}
            />
          </Box>

          <Box align={"end"} flex={true} width={{ min: "270px" }}>
            <SpaceStateToggle id={data.space.id} state={data.space.state} />
          </Box>
        </Box>

        {/** Members */}
        <Box align={"start"} margin={{ top: "medium" }} width={"100%"}>
          <Text size={"small"}>{t(tk.spaceMembers.heading)}</Text>
        </Box>

        <Box
          direction={"row"}
          align={"center"}
          justify={"between"}
          gap={"small"}
          wrap={true}
          width={"100%"}
          margin={{ top: "small" }}
        >
          <Box direction={"row"} align={"center"} justify={"start"} gap={"small"}>
            <Box width={"medium"}>
              <TextInput
                type={"search"}
                value={state.query}
                placeholder={t(tk.members.search.placeholder)}
                icon={state.loading ? <Spinner /> : <FormSearch color={"dark-3"} />}
                onChange={handlers.handleChangeSearch}
              />
            </Box>

            <Box width={"small"}>
              <Select
                value={state.activated}
                valueKey={"value"}
                labelKey={"label"}
                options={data.activatedOptions}
                onChange={({ value }) => handlers.handleChangeActivated(value)}
              />
            </Box>
          </Box>

          <Box direction={"row"} justify={"end"} gap={"small"}>
            <Tooltip
              value={t(tk.spaceMembers.removeTooltip)}
              visible={!state.checked.length && tooltip.visible}
              target={tooltip.target}
              align={{ bottom: "top" }}
            />
            <Button
              label={t(tk.spaceMembers.remove)}
              icon={<SubtractCircle size={"small"} />}
              size={"medium"}
              disabled={!state.checked.length || data.space.state === SpaceState.Archived}
              onClick={deleteSpaceMember.deleteMembers}
              {...tooltip.targetProps}
            />
            <Button
              label={t(tk.common.addMembers)}
              icon={<UserAdd size={"small"} />}
              size={"medium"}
              primary={true}
              disabled={data.space.state === SpaceState.Archived}
              onClick={handlers.addMembers}
            />
          </Box>
        </Box>

        <Box
          width={"100%"}
          elevation={"small"}
          round={"xsmall"}
          overflow={"auto"}
          margin={{ top: "small" }}
          // style={{ whiteSpace: "nowrap" }}
        >
          <DataTable
            columns={columns}
            data={data.members}
            pad={{ vertical: "small", horizontal: "small" }}
            border={{ body: { side: "bottom", color: "light-4" }, header: { side: "bottom", color: "light-4" } }}
          />

          {/** No results */}
          {!state.loading && !data.members.length && !state.debouncedQuery && state.activated.value === "all" && (
            <Box align={"center"} justify={"center"} height={"small"}>
              <Text size={"small"}>{t(tk.spaceMembers.empty)}</Text>
              <Button
                label={t(tk.common.addMembers)}
                icon={<UserAdd size={"small"} />}
                size={"medium"}
                primary={true}
                margin={{ top: "small" }}
                disabled={data.space.state === SpaceState.Archived}
                onClick={handlers.addMembers}
              />
            </Box>
          )}

          {!state.loading && !data.members.length && (!!state.debouncedQuery || state.activated.value !== "all") && (
            <Box align={"center"} justify={"center"} height={"small"}>
              <Text size={"small"}>{t(tk.members.table.empty.search)}</Text>
            </Box>
          )}

          {/** Pagination */}
          <Box align={"end"} margin={pagination.pageCount > 1 ? "small" : undefined}>
            <Pagination
              pageCount={pagination.pageCount}
              current={pagination.current}
              onPageClick={pagination.setPage}
            />
          </Box>
        </Box>
      </ResponsiveContainer>

      <DeleteSpaceMember
        memberships={deleteSpaceMember.membersToDelete}
        onCancel={deleteSpaceMember.handleDeleteSpaceMemberCancel}
        onSuccess={deleteSpaceMember.handleDeleteSpaceMemberSuccess}
      />

      <EditMembership
        space={data.space}
        membership={state.memberToEdit}
        onCancel={handlers.handleEditMembershipCancel}
        onSuccess={handlers.handleEditMembershipSuccess}
      />
    </Main>
  );
};

const textColor = (isActive: boolean) => (!isActive ? "status-disabled" : undefined);
