import React, { useMemo } from "react";
import { Box, BoxProps, Diagram, DiagramProps, Stack, Text } from "grommet";

interface Props {
  steps: string[];
  current: number;
  margin?: BoxProps["margin"];
}

export const ProgressBar = ({ steps, current, margin }: Props) => {
  const connections = useMemo<DiagramProps["connections"]>(() => {
    return steps.slice(0, -1).map((_step, i) => ({
      fromTarget: `progress-${i}`,
      toTarget: `progress-${i + 1}`,
      thickness: "xxsmall",
      color: i < current ? "dark-6" : "light-3",
      anchor: "horizontal",
      round: true,
    }));
  }, [current, steps]);

  return (
    <Box margin={margin} flex={{ shrink: 0 }}>
      <Stack>
        <Box direction={"row"} width={{ min: "medium" }} gap={"medium"}>
          {steps.map((step, i) => {
            const id = `progress-${i}`;
            return (
              <Box key={id} justify={"center"} align={"center"} flex={true}>
                <Box id={id} pad={"xsmall"}>
                  <Box width={"16px"} height={"16px"} round={"full"} background={i <= current ? "dark-6" : "light-3"} />
                </Box>

                <Text weight={i === current ? "bold" : "normal"} size={"small"}>
                  {step}
                </Text>
              </Box>
            );
          })}
        </Box>

        <Diagram connections={connections} />
      </Stack>
    </Box>
  );
};
