import { Row } from "components/MembersAddTable/useTable";
import { Roles } from "api";
import { isEmail, isPhoneNumber } from "helpers";

export const isOriginalDataEmpty = (data: Row[]): boolean => {
  for (const row of data) {
    if (row.firstName.length > 0) return false;
    if (row.lastName.length > 0) return false;
    if (row.email.length > 0) return false;
    if (row.phoneNumber.length > 0) return false;
    if (row.notificationNumber.length > 0) return false;
    if (row.position.length > 0) return false;
    if (row.contactGroups.length > 0) return false;
  }

  return true;
};

type SanitizedRow = [string, string, string, string, string, string, string, string];

export const parseRawData = (data: any[][]): Row[] => {
  if (!data.length) return [];

  const isAlternativeFormat = data[0][8] === "Sporty";

  const sanitizedData = data.filter((row) => row.length === (isAlternativeFormat ? 9 : 8)) as SanitizedRow[];

  // Remove header
  if (sanitizedData.length && isHeader(sanitizedData[0])) sanitizedData.splice(0, 1);

  return sanitizedData.map((row) => ({
    id: "",
    firstName: row[0].trim(),
    lastName: row[1].trim(),
    email: row[2].trim(),
    phoneNumber: parsePhoneNumber(row[3]),
    notificationNumber: parsePhoneNumber(row[4]),
    position: sanitizePosition(row[5]),
    role: parseRole(row[6]),
    contactGroups: row[7].trim(),
  }));
};

const isHeader = (row: SanitizedRow): boolean => {
  return row[2].includes("E-mail") || row[0].endsWith("*") || row[1].endsWith("*");
};

const parsePhoneNumber = (value: string): string => {
  value = value.replace(/\s/g, ""); // remove whitespaces
  if (value.startsWith("00")) value = "+" + value.substr(2); // replace leading 00 with +
  if (value.length && !value.startsWith("+")) value = "+" + value; // if leading + not present and not empty, add +

  return value;
};

const sanitizePosition = (position: string): string => {
  position = position.trim();

  if (position.length > 50) position = position.substring(0, 50);

  return position;
};

const parseRole = (role: string): string => {
  // noinspection SpellCheckingInspection
  switch (role.toLowerCase()) {
    case "regular":
    case "sportovec":
      return Roles.Regular;
    case "manager":
    case "manažer":
    default:
      return Roles.Manager;
  }
};

export const setIds = (data: Row[], startFrom: number = 1): Row[] => {
  return data.map((row, i) => ({ ...row, id: (startFrom + i).toString() }));
};

type ErrorType = "required" | "invalidEmail" | "invalidPhone" | "duplicate";
type MissingField = "firstName" | "lastName" | "email" | "contactGroups";
type Error = { row: string; type: ErrorType; missingFields?: MissingField[] };

export const validateData = (data: Row[]): { valid: boolean; error?: Error } => {
  const emails: string[] = [];

  for (const row of data) {
    const { id, firstName, lastName, email, phoneNumber, notificationNumber, contactGroups } = row;

    /** duplicate email validation */
    if (emails.includes(email)) {
      return { valid: false, error: { type: "duplicate", row: id } };
    }

    /** required fields validation */
    if (!firstName || !lastName || !email || !contactGroups) {
      const missingFields = [] as MissingField[];
      if (!firstName) missingFields.push("firstName");
      if (!lastName) missingFields.push("lastName");
      if (!email) missingFields.push("email");
      if (!contactGroups) missingFields.push("contactGroups");

      return { valid: false, error: { type: "required", row: id, missingFields } };
    }

    /** invalid email validation */
    if (!isEmail(email)) {
      return { valid: false, error: { type: "invalidEmail", row: id } };
    }

    /** invalid phone validation */
    if (!isPhoneNumber(phoneNumber) || !isPhoneNumber(notificationNumber)) {
      return { valid: false, error: { type: "invalidPhone", row: id } };
    }

    emails.push(email);
  }

  return { valid: true };
};
