import React, { useCallback, useMemo } from "react";
import { Box, Button, Layer, Text } from "grommet";
import { View, Clear, CircleQuestion } from "grommet-icons";
import { Tooltip } from "components/Tooltip";
import { useSpaceState, useTooltip, useModal } from "hooks";
import { tk, useTranslation } from "translations";
import { SpaceState } from "api";

interface Props {
  id: string;
  state: SpaceState;
}

export const SpaceStateToggle = ({ id, state }: Props) => {
  const { t } = useTranslation();
  const tooltip = useTooltip();
  const modal = useModal();

  const { setState, loading } = useSpaceState();

  const { label, tooltipValue, action, icon } = useMemo(() => {
    if (state === SpaceState.Draft) {
      return {
        label: t(tk.spaceState.concept),
        action: t(tk.common.activate),
        tooltipValue: t(tk.space.stateTooltip.concept),
        icon: <View size={"small"} />,
      };
    }

    if (state === SpaceState.Active) {
      return {
        label: `${t(tk.spaceState.active)} ${t(tk.common.space).toLowerCase()}`,
        action: t(tk.common.deactivate),
        tooltipValue: t(tk.space.stateTooltip.active),
        icon: <Clear size={"small"} />,
      };
    }

    return {
      label: `${t(tk.spaceState.inactive)} ${t(tk.common.space).toLowerCase()}`,
      action: t(tk.common.activate),
      tooltipValue: t(tk.space.stateTooltip.inactive),
      icon: <View size={"small"} />,
    };
  }, [state, t]);

  const handleClick = useCallback(async () => {
    if (state === SpaceState.Draft) return modal.show();
    if (state === SpaceState.Active) return await setState(id, SpaceState.Active, SpaceState.Archived);
    if (state === SpaceState.Archived) return await setState(id, SpaceState.Archived, SpaceState.Active);
  }, [id, modal, setState, state]);

  const publish = useCallback(async () => {
    modal.hide();
    await setState(id, SpaceState.Draft, SpaceState.Active);
  }, [id, modal, setState]);

  return (
    <Box direction={"row"} align={"center"} gap={"xsmall"}>
      {modal.visible && (
        <Layer onEsc={modal.hide} onClickOutside={modal.hide}>
          <Box width={"420px"} pad={"medium"} round={"xsmall"} elevation={"medium"}>
            <Box align={"start"}>
              <Text weight={"bold"}>{t(tk.space.publish.submit)}</Text>
            </Box>
            <Box margin={{ top: "small" }}>
              <Text size={"small"} margin={{ bottom: "xsmall" }}>
                {t(tk.space.publish.text)}
              </Text>
              <Text size={"small"}>{t(tk.space.publish.confirmation)}</Text>
            </Box>
            <Box direction={"row"} justify={"end"} margin={{ top: "medium" }} gap={"small"}>
              <Button label={t(tk.common.back)} size={"small"} onClick={modal.hide} />
              <Button label={t(tk.space.publish.submit)} size={"small"} primary={true} onClick={publish} />
            </Box>
          </Box>
        </Layer>
      )}

      <Box direction={"row"} align={"center"} gap={"xsmall"} {...tooltip.targetProps}>
        <Text size={"small"} weight={"bold"} style={{ cursor: "default" }}>
          {label}
        </Text>
        <CircleQuestion size={"small"} />

        <Tooltip
          value={tooltipValue}
          visible={tooltip.visible}
          target={tooltip.target}
          align={{ top: "bottom", right: "right" }}
          maxWidth={"300px"}
        />
      </Box>

      <Button
        label={action}
        icon={icon}
        gap={"xsmall"}
        size={"small"}
        primary={true}
        disabled={loading}
        onClick={handleClick}
      />
    </Box>
  );
};
