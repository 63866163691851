import React, { memo } from "react";
import { Box, Text } from "grommet";

interface Props {
  value: string;
  maxWidth?: number;
}

export const Value = memo(({ value, maxWidth = 200 }: Props) => (
  <Box width={{ max: `${maxWidth}px` }} height={{ min: "1px" }}>
    <Text size={"xsmall"} truncate={true}>
      {value}
    </Text>
  </Box>
));
