import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date with time (isoformat) */
  DateTime: any;
  /** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
  GlobalID: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  Upload: any;
  /** Represents NULL values */
  Void: any;
};


export type ActivateAccountInput = {
  activationCode: Scalars['String'];
  password: Scalars['String'];
};

export type ActivateAccountPayload = {
  __typename?: 'ActivateAccountPayload';
  user: InactiveUserNode;
};

export type AnnouncementCreateInput = {
  space: Scalars['GlobalID'];
  recipientSelection: Scalars['GlobalID'];
  title: Scalars['String'];
  text: Scalars['String'];
  urgency: Urgency;
  videoUrl?: Maybe<Scalars['String']>;
  reactions?: Maybe<Array<PositionedReaction>>;
  deadline?: Maybe<Scalars['DateTime']>;
  media?: Maybe<Scalars['GlobalID']>;
};

export type AnnouncementDeletePayload = {
  __typename?: 'AnnouncementDeletePayload';
  space: SpaceNode;
};

export type AnnouncementInput = {
  announcement: Scalars['GlobalID'];
};

export type AnnouncementInteractInput = {
  announcement: Scalars['GlobalID'];
  reaction?: Maybe<Scalars['GlobalID']>;
  seen?: Maybe<Scalars['Boolean']>;
  marked?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type AnnouncementInteractPayload = {
  __typename?: 'AnnouncementInteractPayload';
  interaction: AnnouncementUserInteractionNode;
};

export type AnnouncementLogNode = Node & {
  __typename?: 'AnnouncementLogNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  type: AnnouncementLogTypes;
  createdAt: Scalars['DateTime'];
  valueOld: Scalars['String'];
  valueNew: Scalars['String'];
};

/** A connection to a list of items. */
export type AnnouncementLogNodeConnection = {
  __typename?: 'AnnouncementLogNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementLogNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementLogNodeEdge = {
  __typename?: 'AnnouncementLogNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementLogNode;
};

export enum AnnouncementLogTypes {
  Seen = 'seen',
  Reaction = 'reaction',
  Urge = 'urge'
}

export type AnnouncementNode = Node & {
  __typename?: 'AnnouncementNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  space: SpaceNode;
  createdAt: Scalars['DateTime'];
  title: Scalars['String'];
  text: Scalars['String'];
  urgedAt?: Maybe<Scalars['DateTime']>;
  urgeCount: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  videoUrl?: Maybe<Scalars['String']>;
  reactionDeadlineAt?: Maybe<Scalars['DateTime']>;
  lastChangesSeenAt?: Maybe<Scalars['DateTime']>;
  interactions: AnnouncementUserInteractionNodeConnection;
  reactions: AnnouncementReactionNodeConnection;
  logs: AnnouncementLogNodeConnection;
  authorMembership: MembershipNode;
  myInteraction?: Maybe<AnnouncementUserInteractionNode>;
  recipientsTotalCount?: Maybe<Scalars['Int']>;
  recipientsSeenCount?: Maybe<Scalars['Int']>;
  recipientsReactedCount?: Maybe<Scalars['Int']>;
  imageThumbnail?: Maybe<Scalars['String']>;
  imageOriginal?: Maybe<Scalars['String']>;
};


export type AnnouncementNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AnnouncementNodeReactionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AnnouncementNodeLogsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type AnnouncementNodeConnection = {
  __typename?: 'AnnouncementNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementNodeEdge = {
  __typename?: 'AnnouncementNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementNode;
};

export type AnnouncementOrder = {
  createdAt?: Maybe<Ordering>;
  urgedAt?: Maybe<Ordering>;
};

export type AnnouncementPayload = {
  __typename?: 'AnnouncementPayload';
  announcement: AnnouncementNode;
};

export type AnnouncementReactionNode = Node & {
  __typename?: 'AnnouncementReactionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  position: Scalars['Int'];
  text: Scalars['String'];
  interactions: AnnouncementUserInteractionNodeConnection;
};


export type AnnouncementReactionNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type AnnouncementReactionNodeConnection = {
  __typename?: 'AnnouncementReactionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementReactionNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementReactionNodeEdge = {
  __typename?: 'AnnouncementReactionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementReactionNode;
};

export type AnnouncementSetReactionDeadlineInput = {
  announcement: Scalars['GlobalID'];
  deadline?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementUserInteractionNode = Node & {
  __typename?: 'AnnouncementUserInteractionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  announcement: AnnouncementNode;
  reaction?: Maybe<AnnouncementReactionNode>;
  reactedAt?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  marked: Scalars['Boolean'];
  sms?: Maybe<SmsNode>;
  lastReactionLog?: Maybe<AnnouncementLogNode>;
};

/** A connection to a list of items. */
export type AnnouncementUserInteractionNodeConnection = {
  __typename?: 'AnnouncementUserInteractionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<AnnouncementUserInteractionNodeEdge>;
};

/** An edge in a connection. */
export type AnnouncementUserInteractionNodeEdge = {
  __typename?: 'AnnouncementUserInteractionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AnnouncementUserInteractionNode;
};

export type BenefitNode = Node & {
  __typename?: 'BenefitNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  image?: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ChangeProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  language?: Maybe<Languages>;
  profileImage?: Maybe<Scalars['GlobalID']>;
  notificationAnnouncementNotUrgent?: Maybe<Scalars['Boolean']>;
  notificationChat?: Maybe<Scalars['Boolean']>;
  notificationStory?: Maybe<Scalars['Boolean']>;
  notificationMyStoryComment?: Maybe<Scalars['Boolean']>;
};

export type ChangeProfilePayload = {
  __typename?: 'ChangeProfilePayload';
  me: MeNode;
  user: UserNode;
};

export type ChatAddUsersInput = {
  chat: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
};

export type ChatCreateInput = {
  organization: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
  name?: Maybe<Scalars['String']>;
};

export type ChatInput = {
  chat: Scalars['GlobalID'];
};

export type ChatLeaveInput = {
  chat: Scalars['GlobalID'];
  newOwner?: Maybe<Scalars['GlobalID']>;
};

export type ChatLeavePayload = {
  __typename?: 'ChatLeavePayload';
  organization: OrganizationNode;
};

export type ChatMessageNode = Node & {
  __typename?: 'ChatMessageNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type ChatMessageNodeConnection = {
  __typename?: 'ChatMessageNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ChatMessageNodeEdge>;
};

/** An edge in a connection. */
export type ChatMessageNodeEdge = {
  __typename?: 'ChatMessageNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ChatMessageNode;
};

export type ChatMessagePayload = {
  __typename?: 'ChatMessagePayload';
  message: ChatMessageNode;
};

export type ChatNode = Node & {
  __typename?: 'ChatNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  createdAt: Scalars['DateTime'];
  lastMessageAt?: Maybe<Scalars['DateTime']>;
  createdBy: UserNode;
  organization: OrganizationNode;
  messages: ChatMessageNodeConnection;
  interactions: ChatUserInteractionNodeConnection;
  name?: Maybe<Scalars['String']>;
  myInteraction: ChatUserInteractionNode;
  metaMarkAsRead: Scalars['Boolean'];
};


export type ChatNodeMessagesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ChatNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type ChatNodeConnection = {
  __typename?: 'ChatNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ChatNodeEdge>;
};

/** An edge in a connection. */
export type ChatNodeEdge = {
  __typename?: 'ChatNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ChatNode;
};

export type ChatPayload = {
  __typename?: 'ChatPayload';
  chat: ChatNode;
};

export type ChatRemoveUsersInput = {
  chat: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
};

export type ChatSendMessageInput = {
  chat: Scalars['GlobalID'];
  message: Scalars['String'];
};

export type ChatSetMutedInput = {
  chat: Scalars['GlobalID'];
  muted: Scalars['Boolean'];
};

export type ChatSetNameInput = {
  chat: Scalars['GlobalID'];
  name: Scalars['String'];
};

export type ChatUserInteractionNode = Node & {
  __typename?: 'ChatUserInteractionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  createdBy: UserNode;
  lastOpenedAt?: Maybe<Scalars['DateTime']>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  muted: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type ChatUserInteractionNodeConnection = {
  __typename?: 'ChatUserInteractionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ChatUserInteractionNodeEdge>;
};

/** An edge in a connection. */
export type ChatUserInteractionNodeEdge = {
  __typename?: 'ChatUserInteractionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ChatUserInteractionNode;
};

export type ConfirmPasswordResetInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type ContactGroupCreateInput = {
  space: Scalars['GlobalID'];
  name: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
};

export type ContactGroupDeleteInput = {
  contactGroup: Scalars['GlobalID'];
};

export type ContactGroupDeletePayload = {
  __typename?: 'ContactGroupDeletePayload';
  contactGroup?: Maybe<ContactGroupNode>;
  success: Scalars['Boolean'];
  failedUsers: Array<UserNode>;
};

export type ContactGroupNode = Node & {
  __typename?: 'ContactGroupNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  private: Scalars['Boolean'];
  userCount: Scalars['Int'];
  users: ContactGroupUserConnection;
};


export type ContactGroupNodeUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type ContactGroupNodeConnection = {
  __typename?: 'ContactGroupNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ContactGroupNodeEdge>;
};

/** An edge in a connection. */
export type ContactGroupNodeEdge = {
  __typename?: 'ContactGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ContactGroupNode;
};

export type ContactGroupPayload = {
  __typename?: 'ContactGroupPayload';
  contactGroup: ContactGroupNode;
};

export type ContactGroupUpdateInput = {
  contactGroup: Scalars['GlobalID'];
  name?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<Scalars['GlobalID']>>;
};

export type ContactGroupUpdatePayload = {
  __typename?: 'ContactGroupUpdatePayload';
  contactGroup: ContactGroupNode;
  success: Scalars['Boolean'];
  failedUsers: Array<UserNode>;
};

export type ContactGroupUser = Node & {
  __typename?: 'ContactGroupUser';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  role: Roles;
  position?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ContactGroupUserConnection = {
  __typename?: 'ContactGroupUserConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<ContactGroupUserEdge>;
};

/** An edge in a connection. */
export type ContactGroupUserEdge = {
  __typename?: 'ContactGroupUserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ContactGroupUser;
};


export type DeactivatePushMessageTokenInput = {
  token: Scalars['String'];
};

export type DeviceSetInput = {
  os?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  appBuild?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  deviceInfo: Scalars['JSON'];
};

export type DocumentCreateInput = {
  space: Scalars['GlobalID'];
  media: Scalars['GlobalID'];
  recipientSelection: Scalars['GlobalID'];
  title: Scalars['String'];
};

export type DocumentDeletePayload = {
  __typename?: 'DocumentDeletePayload';
  space: SpaceNode;
};

export type DocumentInput = {
  document: Scalars['GlobalID'];
};

export type DocumentMarkAllAsReadInput = {
  space: Scalars['GlobalID'];
};

export type DocumentMarkAllAsReadPayload = {
  __typename?: 'DocumentMarkAllAsReadPayload';
  space: SpaceNode;
};

export type DocumentMarkAsReadPayload = {
  __typename?: 'DocumentMarkAsReadPayload';
  interaction: DocumentUserInteractionNode;
};

export type DocumentNode = Node & {
  __typename?: 'DocumentNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  space: SpaceNode;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  interactions: DocumentUserInteractionNodeConnection;
  authorMembership: MembershipNode;
  myInteraction: DocumentUserInteractionNode;
  file: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type DocumentNodeInteractionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type DocumentNodeConnection = {
  __typename?: 'DocumentNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<DocumentNodeEdge>;
};

/** An edge in a connection. */
export type DocumentNodeEdge = {
  __typename?: 'DocumentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DocumentNode;
};

export type DocumentPayload = {
  __typename?: 'DocumentPayload';
  document: DocumentNode;
};

export type DocumentUpdateInput = {
  document: Scalars['GlobalID'];
  media?: Maybe<Scalars['GlobalID']>;
  recipientSelection?: Maybe<Scalars['GlobalID']>;
  title?: Maybe<Scalars['String']>;
};

export type DocumentUserInteractionNode = Node & {
  __typename?: 'DocumentUserInteractionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  readAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type DocumentUserInteractionNodeConnection = {
  __typename?: 'DocumentUserInteractionNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<DocumentUserInteractionNodeEdge>;
};

/** An edge in a connection. */
export type DocumentUserInteractionNodeEdge = {
  __typename?: 'DocumentUserInteractionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DocumentUserInteractionNode;
};

export enum Errors {
  NotFound = 'not_found',
  PermissionDenied = 'permission_denied',
  ParameterRequired = 'parameter_required',
  SpaceMismatch = 'space_mismatch',
  SpaceNotDraft = 'space_not_draft',
  SpaceNotActive = 'space_not_active',
  SpaceNotArchived = 'space_not_archived',
  OperationNotAllowed = 'operation_not_allowed',
  ColorInvalidFormat = 'color_invalid_format',
  TokenInvalid = 'token_invalid',
  EmailInvalid = 'email_invalid',
  EmailAlreadyUsed = 'email_already_used',
  PhoneNumberInvalid = 'phone_number_invalid',
  PasswordTooShort = 'password_too_short',
  PasswordInvalid = 'password_invalid',
  MediaInvalidFormat = 'media_invalid_format',
  PreviewNotSupported = 'preview_not_supported',
  MediaAlreadyUsed = 'media_already_used',
  AnnouncementCannotUrgeYet = 'announcement_cannot_urge_yet',
  AnnouncementCannotUrgeAgain = 'announcement_cannot_urge_again',
  AnnouncementCannotUrgeAfterDeadline = 'announcement_cannot_urge_after_deadline',
  AnnouncementCannotUrgeDeleted = 'announcement_cannot_urge_deleted',
  ChatAddNoValidMembers = 'chat_add_no_valid_members',
  ChatMustSelectNewOwner = 'chat_must_select_new_owner'
}

export enum FcmType {
  Ios = 'ios',
  Android = 'android'
}

export type FeedbackInput = {
  text: Scalars['String'];
};


export type InactiveUserNode = Node & {
  __typename?: 'InactiveUserNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  activationCode: Scalars['String'];
  profileImage: Scalars['String'];
  profileImageFull: Scalars['String'];
};


export enum Languages {
  Cs = 'cs',
  En = 'en',
  De = 'de',
  Sk = 'sk'
}

export type LogBenefitClickInput = {
  benefit: Scalars['GlobalID'];
};

export type LogBenefitViewsInput = {
  benefits: Array<Scalars['GlobalID']>;
};

export type LogSponsorImpressionsInput = {
  sponsors: Array<Scalars['GlobalID']>;
};

export type MeNode = Node & {
  __typename?: 'MeNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  notificationAnnouncementNotUrgent: Scalars['Boolean'];
  notificationChat: Scalars['Boolean'];
  notificationStory: Scalars['Boolean'];
  notificationMyStoryComment: Scalars['Boolean'];
  profileImageFull: Scalars['String'];
  profileImage: Scalars['String'];
  userId: Scalars['GlobalID'];
  vocative: Scalars['String'];
  language?: Maybe<Languages>;
  organizations: OrganizationNodeConnection;
  ownedOrganizations: OrganizationNodeConnection;
};


export type MeNodeOrganizationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MeNodeOwnedOrganizationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MediaInput = {
  file: Scalars['Upload'];
  type: MediaTypes;
};

export type MediaNode = Node & {
  __typename?: 'MediaNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  size?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  file?: Maybe<Scalars['String']>;
  type: MediaTypes;
};

export type MediaPayload = {
  __typename?: 'MediaPayload';
  media: MediaNode;
};

export type MediaPreviewPayload = {
  __typename?: 'MediaPreviewPayload';
  preview: Scalars['String'];
};

export enum MediaTypes {
  Document = 'document',
  StoryHeader = 'story_header',
  ProfileImage = 'profile_image',
  OrganizationLogo = 'organization_logo',
  SpaceLogo = 'space_logo',
  SpaceHeader = 'space_header',
  SponsorLogo = 'sponsor_logo',
  SponsorImage = 'sponsor_image',
  BenefitImage = 'benefit_image',
  AnnouncementImage = 'announcement_image'
}

export type MembershipNode = Node & {
  __typename?: 'MembershipNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  role: Roles;
  position?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  inviteSentAt?: Maybe<Scalars['DateTime']>;
  space: SpaceNode;
  contactGroups: ContactGroupNodeConnection;
};


export type MembershipNodeContactGroupsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type MembershipNodeConnection = {
  __typename?: 'MembershipNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<MembershipNodeEdge>;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type MembershipNodeEdge = {
  __typename?: 'MembershipNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: MembershipNode;
};

export type Mutations = {
  __typename?: 'Mutations';
  storyCreate: StoryPayload;
  storyUpdate: StoryPayload;
  storyDelete: StoryDeletePayload;
  storyAddComment: StoryAddCommentPayload;
  storyClap: StoryPayload;
  chatMarkAsRead: ChatPayload;
  chatCreate: ChatPayload;
  chatSendMessage: ChatMessagePayload;
  chatAddUsers: ChatPayload;
  chatRemoveUsers: ChatPayload;
  chatSetName: ChatPayload;
  chatLeave: ChatLeavePayload;
  chatAcceptInvitation: ChatPayload;
  chatSetMuted: ChatPayload;
  documentMarkAsRead: DocumentMarkAsReadPayload;
  documentsMarkAllAsRead: DocumentMarkAllAsReadPayload;
  documentCreate: DocumentPayload;
  documentUpdate: DocumentPayload;
  documentDelete: DocumentDeletePayload;
  changeProfile: ChangeProfilePayload;
  changePassword?: Maybe<Scalars['Void']>;
  activateAccount: ActivateAccountPayload;
  requestPasswordReset?: Maybe<Scalars['Void']>;
  confirmPasswordReset?: Maybe<Scalars['Void']>;
  registerPushMessageToken?: Maybe<Scalars['Void']>;
  deactivatePushMessageToken?: Maybe<Scalars['Void']>;
  announcementInteract: AnnouncementInteractPayload;
  announcementCreate: AnnouncementPayload;
  announcementUrge: AnnouncementPayload;
  announcementSetReactionDeadline: AnnouncementPayload;
  announcementDelete: AnnouncementDeletePayload;
  announcementReactionsChangesSeen: AnnouncementPayload;
  feedbackCreate?: Maybe<Scalars['Void']>;
  deviceSet?: Maybe<Scalars['Void']>;
  recipientSelectionCreate: RecipientSelectionPayload;
  recipientSelectionCreateFromDocument: RecipientSelectionPayload;
  recipientSelectionToggle: RecipientSelectionPayload;
  mediaCreate: MediaPayload;
  mediaPreview: MediaPreviewPayload;
  organizationAddOwner: OrganizationPayload;
  organizationRemoveOwner: OrganizationPayload;
  organizationUpdate: OrganizationPayload;
  organizationRemoveUsers: OrganizationPayload;
  organizationChangeUser: OrganizationChangeUserPayload;
  organizationChangeMembership: OrganizationChangeMembershipPayload;
  spaceCreate: SpacePayload;
  spaceUpdate: SpacePayload;
  spacePublish: SpacePayload;
  spaceArchive: SpacePayload;
  spaceUnarchive: SpacePayload;
  spaceSendInvite?: Maybe<Scalars['Void']>;
  spaceDeleteMemberships?: Maybe<Scalars['Void']>;
  spaceInviteUsers: SpaceInviteUsersPayload;
  spaceCheckUsers: SpaceCheckUsersPayload;
  contactGroupCreate: ContactGroupPayload;
  contactGroupUpdate: ContactGroupUpdatePayload;
  contactGroupDelete: ContactGroupDeletePayload;
  logSponsorImpressions?: Maybe<Scalars['Void']>;
  logBenefitViews?: Maybe<Scalars['Void']>;
  logBenefitClick?: Maybe<Scalars['Void']>;
  tokenAuth: Token;
  tokenRefresh: Token;
  tokenVerify: TokenPayloadType;
};


export type MutationsStoryCreateArgs = {
  input: StoryCreateInput;
};


export type MutationsStoryUpdateArgs = {
  input: StoryUpdateInput;
};


export type MutationsStoryDeleteArgs = {
  input: StoryInput;
};


export type MutationsStoryAddCommentArgs = {
  input: StoryAddCommentInput;
};


export type MutationsStoryClapArgs = {
  input: StoryClapInput;
};


export type MutationsChatMarkAsReadArgs = {
  input: ChatInput;
};


export type MutationsChatCreateArgs = {
  input: ChatCreateInput;
};


export type MutationsChatSendMessageArgs = {
  input: ChatSendMessageInput;
};


export type MutationsChatAddUsersArgs = {
  input: ChatAddUsersInput;
};


export type MutationsChatRemoveUsersArgs = {
  input: ChatRemoveUsersInput;
};


export type MutationsChatSetNameArgs = {
  input: ChatSetNameInput;
};


export type MutationsChatLeaveArgs = {
  input: ChatLeaveInput;
};


export type MutationsChatAcceptInvitationArgs = {
  input: ChatInput;
};


export type MutationsChatSetMutedArgs = {
  input: ChatSetMutedInput;
};


export type MutationsDocumentMarkAsReadArgs = {
  input: DocumentInput;
};


export type MutationsDocumentsMarkAllAsReadArgs = {
  input: DocumentMarkAllAsReadInput;
};


export type MutationsDocumentCreateArgs = {
  input: DocumentCreateInput;
};


export type MutationsDocumentUpdateArgs = {
  input: DocumentUpdateInput;
};


export type MutationsDocumentDeleteArgs = {
  input: DocumentInput;
};


export type MutationsChangeProfileArgs = {
  input: ChangeProfileInput;
};


export type MutationsChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationsActivateAccountArgs = {
  input: ActivateAccountInput;
};


export type MutationsRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationsConfirmPasswordResetArgs = {
  input: ConfirmPasswordResetInput;
};


export type MutationsRegisterPushMessageTokenArgs = {
  input: RegisterPushMessageTokenInput;
};


export type MutationsDeactivatePushMessageTokenArgs = {
  input: DeactivatePushMessageTokenInput;
};


export type MutationsAnnouncementInteractArgs = {
  input: AnnouncementInteractInput;
};


export type MutationsAnnouncementCreateArgs = {
  input: AnnouncementCreateInput;
};


export type MutationsAnnouncementUrgeArgs = {
  input: AnnouncementInput;
};


export type MutationsAnnouncementSetReactionDeadlineArgs = {
  input: AnnouncementSetReactionDeadlineInput;
};


export type MutationsAnnouncementDeleteArgs = {
  input: AnnouncementInput;
};


export type MutationsAnnouncementReactionsChangesSeenArgs = {
  input: AnnouncementInput;
};


export type MutationsFeedbackCreateArgs = {
  input: FeedbackInput;
};


export type MutationsDeviceSetArgs = {
  input: DeviceSetInput;
};


export type MutationsRecipientSelectionCreateArgs = {
  input: RecipientSelectionCreateInput;
};


export type MutationsRecipientSelectionCreateFromDocumentArgs = {
  input: RecipientSelectionCreateFromDocumentInput;
};


export type MutationsRecipientSelectionToggleArgs = {
  input: RecipientSelectionToggleInput;
};


export type MutationsMediaCreateArgs = {
  input: MediaInput;
};


export type MutationsMediaPreviewArgs = {
  input: MediaInput;
};


export type MutationsOrganizationAddOwnerArgs = {
  input: OrganizationAddOwnerInput;
};


export type MutationsOrganizationRemoveOwnerArgs = {
  input: OrganizationRemoveOwnerInput;
};


export type MutationsOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};


export type MutationsOrganizationRemoveUsersArgs = {
  input: OrganizationRemoveUsersInput;
};


export type MutationsOrganizationChangeUserArgs = {
  input: OrganizationChangeUserInput;
};


export type MutationsOrganizationChangeMembershipArgs = {
  input: OrganizationChangeMembershipInput;
};


export type MutationsSpaceCreateArgs = {
  input: SpaceCreateInput;
};


export type MutationsSpaceUpdateArgs = {
  input: SpaceUpdateInput;
};


export type MutationsSpacePublishArgs = {
  input: SpaceInput;
};


export type MutationsSpaceArchiveArgs = {
  input: SpaceInput;
};


export type MutationsSpaceUnarchiveArgs = {
  input: SpaceInput;
};


export type MutationsSpaceSendInviteArgs = {
  input: SpaceSendInviteInput;
};


export type MutationsSpaceDeleteMembershipsArgs = {
  input: SpaceDeleteMembershipsInput;
};


export type MutationsSpaceInviteUsersArgs = {
  input: SpaceUsersToAddInput;
};


export type MutationsSpaceCheckUsersArgs = {
  input: SpaceUsersToAddInput;
};


export type MutationsContactGroupCreateArgs = {
  input: ContactGroupCreateInput;
};


export type MutationsContactGroupUpdateArgs = {
  input: ContactGroupUpdateInput;
};


export type MutationsContactGroupDeleteArgs = {
  input: ContactGroupDeleteInput;
};


export type MutationsLogSponsorImpressionsArgs = {
  input: LogSponsorImpressionsInput;
};


export type MutationsLogBenefitViewsArgs = {
  input: LogBenefitViewsInput;
};


export type MutationsLogBenefitClickArgs = {
  input: LogBenefitClickInput;
};


export type MutationsTokenAuthArgs = {
  username: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
};


export type MutationsTokenRefreshArgs = {
  refreshToken: Maybe<Scalars['String']>;
};


export type MutationsTokenVerifyArgs = {
  token: Scalars['String'];
};

/** An object with a Globally Unique ID */
export type Node = {
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
};

export type OnAnnouncementChangeMessage = {
  __typename?: 'OnAnnouncementChangeMessage';
  event: Scalars['String'];
  announcement: AnnouncementNode;
};

export type OnChatChangeMessage = {
  __typename?: 'OnChatChangeMessage';
  event: Scalars['String'];
  chat: ChatNode;
};

export type OnSpaceAnnouncementChangeMessage = {
  __typename?: 'OnSpaceAnnouncementChangeMessage';
  event: Scalars['String'];
  space: SpaceNode;
};

export type OnSpaceDocumentChangeMessage = {
  __typename?: 'OnSpaceDocumentChangeMessage';
  event: Scalars['String'];
  space: SpaceNode;
};

export enum Ordering {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST'
}

export type OrganizationAddOwnerInput = {
  organization: Scalars['GlobalID'];
  user: Scalars['GlobalID'];
};

export type OrganizationChangeMembershipInput = {
  membership: Scalars['GlobalID'];
  role?: Maybe<Roles>;
  position?: Maybe<Scalars['String']>;
};

export type OrganizationChangeMembershipPayload = {
  __typename?: 'OrganizationChangeMembershipPayload';
  membership: MembershipNode;
};

export type OrganizationChangeUserInput = {
  user: Scalars['GlobalID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['GlobalID']>;
  about?: Maybe<Scalars['String']>;
};

export type OrganizationChangeUserPayload = {
  __typename?: 'OrganizationChangeUserPayload';
  user: UserNode;
};

export type OrganizationNode = Node & {
  __typename?: 'OrganizationNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  owners: UserNodeConnection;
  name: Scalars['String'];
  officialName: Scalars['String'];
  crn: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  sponsorType: SponsorTypes;
  logo: Scalars['String'];
  users: UserNodeConnection;
  spaces: SpaceNodeConnection;
  chats: ChatNodeConnection;
  unresolvedChatCount: Scalars['Int'];
  sponsors: Array<SponsorNode>;
  benefits: Array<BenefitNode>;
  hasBenefits: Scalars['Boolean'];
};


export type OrganizationNodeOwnersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationNodeUsersArgs = {
  query?: Maybe<Scalars['String']>;
  order: Maybe<OrganizationUserOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationNodeSpacesArgs = {
  all?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrganizationNodeChatsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type OrganizationNodeConnection = {
  __typename?: 'OrganizationNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<OrganizationNodeEdge>;
};

/** An edge in a connection. */
export type OrganizationNodeEdge = {
  __typename?: 'OrganizationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: OrganizationNode;
};

export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  organization: OrganizationNode;
};

export type OrganizationRemoveOwnerInput = {
  organization: Scalars['GlobalID'];
  user: Scalars['GlobalID'];
};

export type OrganizationRemoveUsersInput = {
  organization: Scalars['GlobalID'];
  users: Array<Scalars['GlobalID']>;
};

export type OrganizationUpdateInput = {
  organization: Scalars['GlobalID'];
  name?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  crn?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['GlobalID']>;
};

export type OrganizationUserOrder = {
  firstName?: Maybe<Ordering>;
  lastName?: Maybe<Ordering>;
};

/** Information to aid in pagination. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PositionedReaction = {
  position: Scalars['Int'];
  reaction: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  user: UserNode;
  userForActivation?: Maybe<InactiveUserNode>;
  organization: OrganizationNode;
  me: MeNode;
  contactGroup: ContactGroupNode;
  recipientSelection: RecipientSelectionNode;
  space: SpaceNode;
  announcement: AnnouncementNode;
  document: DocumentNode;
  chat: ChatNode;
  story: StoryNode;
  exposeErrors: Errors;
};


export type QueryUserArgs = {
  id: Scalars['GlobalID'];
};


export type QueryUserForActivationArgs = {
  activationCode: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['GlobalID'];
};


export type QueryContactGroupArgs = {
  id: Scalars['GlobalID'];
};


export type QueryRecipientSelectionArgs = {
  id: Scalars['GlobalID'];
};


export type QuerySpaceArgs = {
  id: Scalars['GlobalID'];
};


export type QueryAnnouncementArgs = {
  id: Scalars['GlobalID'];
};


export type QueryDocumentArgs = {
  id: Scalars['GlobalID'];
};


export type QueryChatArgs = {
  id: Scalars['GlobalID'];
};


export type QueryStoryArgs = {
  id: Scalars['GlobalID'];
};

export type RecipientSelectionContactGroupNode = Node & {
  __typename?: 'RecipientSelectionContactGroupNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  contactGroup: ContactGroupNode;
  selectedCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

/** A connection to a list of items. */
export type RecipientSelectionContactGroupNodeConnection = {
  __typename?: 'RecipientSelectionContactGroupNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<RecipientSelectionContactGroupNodeEdge>;
};

/** An edge in a connection. */
export type RecipientSelectionContactGroupNodeEdge = {
  __typename?: 'RecipientSelectionContactGroupNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: RecipientSelectionContactGroupNode;
};

export type RecipientSelectionCreateFromDocumentInput = {
  document: Scalars['GlobalID'];
};

export type RecipientSelectionCreateInput = {
  space: Scalars['GlobalID'];
};

export type RecipientSelectionNode = Node & {
  __typename?: 'RecipientSelectionNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  createdBy: UserNode;
  space: SpaceNode;
  selectedCount: Scalars['Int'];
  contactGroups: RecipientSelectionContactGroupNodeConnection;
  users: RecipientSelectionUserNodeConnection;
};


export type RecipientSelectionNodeContactGroupsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RecipientSelectionNodeUsersArgs = {
  contactGroupId?: Maybe<Scalars['GlobalID']>;
  query?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RecipientSelectionPayload = {
  __typename?: 'RecipientSelectionPayload';
  recipientSelection: RecipientSelectionNode;
};

export type RecipientSelectionToggleInput = {
  recipientSelection: Scalars['GlobalID'];
  add: Scalars['Boolean'];
  contactGroup?: Maybe<Scalars['GlobalID']>;
  user?: Maybe<Scalars['GlobalID']>;
  all?: Maybe<Scalars['Boolean']>;
};

export type RecipientSelectionUserNode = Node & {
  __typename?: 'RecipientSelectionUserNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  user: UserNode;
  membership: MembershipNode;
  selected: Scalars['Boolean'];
  space?: Maybe<Scalars['Void']>;
};

/** A connection to a list of items. */
export type RecipientSelectionUserNodeConnection = {
  __typename?: 'RecipientSelectionUserNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<RecipientSelectionUserNodeEdge>;
};

/** An edge in a connection. */
export type RecipientSelectionUserNodeEdge = {
  __typename?: 'RecipientSelectionUserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: RecipientSelectionUserNode;
};

export type RegisterPushMessageTokenInput = {
  token: Scalars['String'];
  type: FcmType;
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export enum Roles {
  Manager = 'manager',
  Regular = 'regular'
}

export type SimpleUser = {
  email: Scalars['String'];
  role: Roles;
  contactGroups: Array<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type SmsNode = Node & {
  __typename?: 'SmsNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  status: SmsStatus;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  message: Scalars['String'];
};

export enum SmsStatus {
  New = 'new',
  Error = 'error',
  Accepted = 'accepted',
  Queued = 'queued',
  Sending = 'sending',
  Sent = 'sent',
  Receiving = 'receiving',
  Received = 'received',
  Delivered = 'delivered',
  Undelivered = 'undelivered',
  Failed = 'failed'
}

export type SpaceCheckUsersPayload = {
  __typename?: 'SpaceCheckUsersPayload';
  suggestedChanges: Array<SuggestedUser>;
};

export type SpaceCreateInput = {
  organization: Scalars['GlobalID'];
  name: Scalars['String'];
  officialName: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['GlobalID']>;
  header?: Maybe<Scalars['GlobalID']>;
};

export type SpaceDeleteMembershipsInput = {
  memberships: Array<Scalars['GlobalID']>;
};

export type SpaceInput = {
  space: Scalars['GlobalID'];
};

export type SpaceInviteUsersPayload = {
  __typename?: 'SpaceInviteUsersPayload';
  space: SpaceNode;
  failedEmails: Array<Scalars['String']>;
};

export type SpaceMembershipOrder = {
  createdAt?: Maybe<Ordering>;
  role?: Maybe<Ordering>;
  user_FirstName?: Maybe<Ordering>;
  user_LastName?: Maybe<Ordering>;
};

export type SpaceNode = Node & {
  __typename?: 'SpaceNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  officialName: Scalars['String'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  organization: OrganizationNode;
  state: SpaceState;
  logo: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  members: MembershipNodeConnection;
  managerCount: Scalars['Int'];
  myMembership: MembershipNode;
  active: Scalars['Boolean'];
  stories: StoryNodeConnection;
  receivedAnnouncements: AnnouncementNodeConnection;
  sentAnnouncements: AnnouncementNodeConnection;
  unresolvedAnnouncementCount: Scalars['Int'];
  receivedDocuments: DocumentNodeConnection;
  sentDocuments: DocumentNodeConnection;
  unresolvedDocumentCount: Scalars['Int'];
  contactGroups: ContactGroupNodeConnection;
};


export type SpaceNodeMembersArgs = {
  query?: Maybe<Scalars['String']>;
  activated?: Maybe<Scalars['Boolean']>;
  order: Maybe<SpaceMembershipOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeStoriesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeReceivedAnnouncementsArgs = {
  marked?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  unresolved?: Maybe<Scalars['Boolean']>;
  order: Maybe<AnnouncementOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeSentAnnouncementsArgs = {
  order: Maybe<AnnouncementOrder>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeReceivedDocumentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeSentDocumentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SpaceNodeContactGroupsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type SpaceNodeConnection = {
  __typename?: 'SpaceNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<SpaceNodeEdge>;
};

/** An edge in a connection. */
export type SpaceNodeEdge = {
  __typename?: 'SpaceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: SpaceNode;
};

export type SpacePayload = {
  __typename?: 'SpacePayload';
  space: SpaceNode;
};

export type SpaceSendInviteInput = {
  membership: Scalars['GlobalID'];
};

export enum SpaceState {
  Draft = 'draft',
  Active = 'active',
  Archived = 'archived'
}

export type SpaceUpdateInput = {
  space: Scalars['GlobalID'];
  name?: Maybe<Scalars['String']>;
  officialName?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['GlobalID']>;
  header?: Maybe<Scalars['GlobalID']>;
};

export type SpaceUsersToAddInput = {
  space: Scalars['GlobalID'];
  users: Array<SimpleUser>;
};

export type SponsorNode = Node & {
  __typename?: 'SponsorNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  type: SponsorTypes;
  logo?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export enum SponsorTypes {
  Simple = 'simple',
  Graphical = 'graphical'
}

export type StoryAddCommentInput = {
  story: Scalars['GlobalID'];
  text: Scalars['String'];
};

export type StoryAddCommentPayload = {
  __typename?: 'StoryAddCommentPayload';
  comment: StoryCommentNode;
};

export type StoryClapInput = {
  story: Scalars['GlobalID'];
  count: Scalars['Int'];
};

export type StoryCommentNode = Node & {
  __typename?: 'StoryCommentNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  createdAt: Scalars['DateTime'];
  text: Scalars['String'];
};

/** A connection to a list of items. */
export type StoryCommentNodeConnection = {
  __typename?: 'StoryCommentNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<StoryCommentNodeEdge>;
};

/** An edge in a connection. */
export type StoryCommentNodeEdge = {
  __typename?: 'StoryCommentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: StoryCommentNode;
};

export type StoryCreateInput = {
  space: Scalars['GlobalID'];
  text: Scalars['String'];
  media?: Maybe<Scalars['GlobalID']>;
};

export type StoryDeletePayload = {
  __typename?: 'StoryDeletePayload';
  space: SpaceNode;
};

export type StoryInput = {
  story: Scalars['GlobalID'];
};

export type StoryNode = Node & {
  __typename?: 'StoryNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  author: UserNode;
  text: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  space: SpaceNode;
  claps: Scalars['Int'];
  comments: StoryCommentNodeConnection;
  myClapCount: Scalars['Int'];
  commentCount: Scalars['Int'];
  headerThumbnail?: Maybe<Scalars['String']>;
  headerOriginal?: Maybe<Scalars['String']>;
  commentedOnByUser: Scalars['Boolean'];
  authorMembership: MembershipNode;
};


export type StoryNodeCommentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type StoryNodeConnection = {
  __typename?: 'StoryNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<StoryNodeEdge>;
};

/** An edge in a connection. */
export type StoryNodeEdge = {
  __typename?: 'StoryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: StoryNode;
};

export type StoryPayload = {
  __typename?: 'StoryPayload';
  story: StoryNode;
};

export type StoryUpdateInput = {
  story: Scalars['GlobalID'];
  media?: Maybe<Scalars['GlobalID']>;
  text: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onChatChange: OnChatChangeMessage;
  /** Broadcasts when sent/received announcement changes (someone sends reaction, reads the announcement, ...). */
  onAnnouncementChange: OnAnnouncementChangeMessage;
  /** Broadcasts when announcement is created or deleted in the space. Warning: doesn't check whether the subscribed user is affected by the event. */
  onSpaceAnnouncementsEvent: OnSpaceAnnouncementChangeMessage;
  /** Broadcasts when document is created or deleted in the space. Warning: doesn't check whether the subscribed user is affected by the event. */
  onSpaceDocumentsEvent: OnSpaceDocumentChangeMessage;
};

export type SuggestedUser = {
  __typename?: 'SuggestedUser';
  originalEmail: Scalars['String'];
  suggestedEmail: Scalars['String'];
  suggestedFirstName?: Maybe<Scalars['String']>;
  suggestedLastName?: Maybe<Scalars['String']>;
  suggestedPhoneNumber?: Maybe<Scalars['String']>;
  suggestedNotificationNumber?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
  refreshToken: Scalars['String'];
  refreshExpiresIn: Scalars['Int'];
};

export type TokenPayloadType = {
  __typename?: 'TokenPayloadType';
  exp: Scalars['Int'];
  iat: Scalars['Int'];
};


export enum Urgency {
  NotUrgent = 'not_urgent',
  Urgent = 'urgent',
  VeryUrgent = 'very_urgent'
}

export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isActive: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  notificationNumber?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  activationCode: Scalars['String'];
  profileImage: Scalars['String'];
  profileImageFull: Scalars['String'];
  memberships: MembershipNodeConnection;
  stories: StoryNodeConnection;
};


export type UserNodeMembershipsArgs = {
  organization: Scalars['GlobalID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserNodeStoriesArgs = {
  organization: Scalars['GlobalID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection */
  edges: Array<UserNodeEdge>;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: UserNode;
};


export type ListedSpaceFragment = { __typename?: 'SpaceNode', id: any, name: string, logo: string, state: SpaceState };

export type ListedUserFragment = { __typename?: 'UserNode', id: any, email: string, firstName: string, lastName: string, isActive: boolean, profileImage: string, memberships: { __typename?: 'MembershipNodeConnection', edges: Array<{ __typename?: 'MembershipNodeEdge', node: (
        { __typename?: 'MembershipNode' }
        & SpaceMembershipFragment
      ) }> } };

export type MeFragment = { __typename?: 'MeNode', id: any, email: string, userId: any, isActive: boolean, firstName: string, lastName: string, phoneNumber?: Maybe<string>, notificationNumber?: Maybe<string>, profileImage: string, language?: Maybe<Languages>, ownedOrganizations: { __typename?: 'OrganizationNodeConnection', edges: Array<{ __typename?: 'OrganizationNodeEdge', node: (
        { __typename?: 'OrganizationNode' }
        & OwnedOrganizationFragment
      ) }> } };

export type MeValidationFragment = { __typename?: 'MeNode', id: any, ownedOrganizations: { __typename?: 'OrganizationNodeConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: Maybe<string> } } };

export type OrganizationSpaceSelectFragment = { __typename?: 'SpaceNode', id: any, name: string, state: SpaceState };

export type OwnedOrganizationFragment = { __typename?: 'OrganizationNode', id: any, crn: string, tin?: Maybe<string>, name: string, logo: string, officialName: string, owners: { __typename?: 'UserNodeConnection', edges: Array<{ __typename?: 'UserNodeEdge', node: (
        { __typename?: 'UserNode' }
        & OwnerFragment
      ) }> }, spaces: { __typename?: 'SpaceNodeConnection', edges: Array<{ __typename?: 'SpaceNodeEdge', node: { __typename?: 'SpaceNode', id: any } }> } };

export type OwnerFragment = { __typename?: 'UserNode', id: any, email: string, firstName: string, lastName: string, profileImage: string };

export type SearchedContactGroupFragment = { __typename?: 'ContactGroupNode', id: any, name: string, private: boolean };

export type SearchedUserFragment = { __typename?: 'UserNode', id: any, email: string, firstName: string, lastName: string, isActive: boolean, profileImage: string };

export type SpaceFragment = { __typename?: 'SpaceNode', id: any, name: string, officialName: string, state: SpaceState, color: string, logo: string, header?: Maybe<string> };

export type SpaceMemberFragment = { __typename?: 'MembershipNode', id: any, role: Roles, position?: Maybe<string>, user: { __typename?: 'UserNode', id: any, email: string, firstName: string, lastName: string, profileImage: string, isActive: boolean }, contactGroups: { __typename?: 'ContactGroupNodeConnection', edges: Array<{ __typename?: 'ContactGroupNodeEdge', node: (
        { __typename?: 'ContactGroupNode' }
        & UserContactGroupFragment
      ) }> } };

export type SpaceMembershipFragment = { __typename?: 'MembershipNode', id: any, space: { __typename?: 'SpaceNode', id: any, name: string } };

export type UserFragment = { __typename?: 'UserNode', id: any, firstName: string, lastName: string, email: string, phoneNumber?: Maybe<string>, notificationNumber?: Maybe<string>, isActive: boolean, activationCode: string, profileImage: string, about?: Maybe<string> };

export type UserContactGroupFragment = { __typename?: 'ContactGroupNode', id: any, name: string };

export type UserMembershipFragment = { __typename?: 'MembershipNode', id: any, role: Roles, position?: Maybe<string>, contactGroups: { __typename?: 'ContactGroupNodeConnection', edges: Array<{ __typename?: 'ContactGroupNodeEdge', node: (
        { __typename?: 'ContactGroupNode' }
        & UserContactGroupFragment
      ) }> }, space: (
    { __typename?: 'SpaceNode' }
    & UserSpaceFragment
  ) };

export type UserSpaceFragment = { __typename?: 'SpaceNode', id: any, name: string, logo: string, state: SpaceState };

export type ContactGroupCreateMutationVariables = Exact<{
  input: ContactGroupCreateInput;
}>;


export type ContactGroupCreateMutation = { __typename?: 'Mutations', contactGroupCreate: { __typename?: 'ContactGroupPayload', contactGroup: (
      { __typename?: 'ContactGroupNode' }
      & SearchedContactGroupFragment
    ) } };

export type ContactGroupDeleteMutationVariables = Exact<{
  input: ContactGroupDeleteInput;
}>;


export type ContactGroupDeleteMutation = { __typename?: 'Mutations', contactGroupDelete: { __typename?: 'ContactGroupDeletePayload', success: boolean, failedUsers: Array<{ __typename?: 'UserNode', id: any, firstName: string, lastName: string }> } };

export type ContactGroupUpdateMutationVariables = Exact<{
  input: ContactGroupUpdateInput;
}>;


export type ContactGroupUpdateMutation = { __typename?: 'Mutations', contactGroupUpdate: { __typename?: 'ContactGroupUpdatePayload', success: boolean, contactGroup: (
      { __typename?: 'ContactGroupNode' }
      & SearchedContactGroupFragment
    ), failedUsers: Array<{ __typename?: 'UserNode', id: any, firstName: string, lastName: string }> } };

export type MediaCreateMutationVariables = Exact<{
  input: MediaInput;
}>;


export type MediaCreateMutation = { __typename?: 'Mutations', mediaCreate: { __typename?: 'MediaPayload', media: { __typename?: 'MediaNode', id: any } } };

export type MediaPreviewMutationVariables = Exact<{
  input: MediaInput;
}>;


export type MediaPreviewMutation = { __typename?: 'Mutations', mediaPreview: { __typename?: 'MediaPreviewPayload', preview: string } };

export type OrganizationAddOwnerMutationVariables = Exact<{
  input: OrganizationAddOwnerInput;
}>;


export type OrganizationAddOwnerMutation = { __typename?: 'Mutations', organizationAddOwner: { __typename?: 'OrganizationPayload', organization: (
      { __typename?: 'OrganizationNode' }
      & OwnedOrganizationFragment
    ) } };

export type OrganizationChangeMembershipMutationVariables = Exact<{
  input: OrganizationChangeMembershipInput;
}>;


export type OrganizationChangeMembershipMutation = { __typename?: 'Mutations', organizationChangeMembership: { __typename?: 'OrganizationChangeMembershipPayload', membership: (
      { __typename?: 'MembershipNode' }
      & SpaceMemberFragment
    ) } };

export type OrganizationChangeUserMutationVariables = Exact<{
  input: OrganizationChangeUserInput;
}>;


export type OrganizationChangeUserMutation = { __typename?: 'Mutations', organizationChangeUser: { __typename?: 'OrganizationChangeUserPayload', user: (
      { __typename?: 'UserNode' }
      & UserFragment
    ) } };

export type OrganizationRemoveOwnerMutationVariables = Exact<{
  input: OrganizationRemoveOwnerInput;
}>;


export type OrganizationRemoveOwnerMutation = { __typename?: 'Mutations', organizationRemoveOwner: { __typename?: 'OrganizationPayload', organization: (
      { __typename?: 'OrganizationNode' }
      & OwnedOrganizationFragment
    ) } };

export type OrganizationRemoveUsersMutationVariables = Exact<{
  input: OrganizationRemoveUsersInput;
}>;


export type OrganizationRemoveUsersMutation = { __typename?: 'Mutations', organizationRemoveUsers: { __typename?: 'OrganizationPayload', organization: { __typename?: 'OrganizationNode', id: any } } };

export type OrganizationUpdateMutationVariables = Exact<{
  input: OrganizationUpdateInput;
}>;


export type OrganizationUpdateMutation = { __typename?: 'Mutations', organizationUpdate: { __typename?: 'OrganizationPayload', organization: (
      { __typename?: 'OrganizationNode' }
      & OwnedOrganizationFragment
    ) } };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutations', tokenRefresh: { __typename?: 'Token', token: string, refreshToken: string, refreshExpiresIn: number } };

export type SpaceArchiveMutationVariables = Exact<{
  input: SpaceInput;
}>;


export type SpaceArchiveMutation = { __typename?: 'Mutations', spaceArchive: { __typename?: 'SpacePayload', space: (
      { __typename?: 'SpaceNode' }
      & SpaceFragment
    ) } };

export type SpaceCheckUsersMutationVariables = Exact<{
  input: SpaceUsersToAddInput;
}>;


export type SpaceCheckUsersMutation = { __typename?: 'Mutations', spaceCheckUsers: { __typename?: 'SpaceCheckUsersPayload', suggestedChanges: Array<{ __typename?: 'SuggestedUser', originalEmail: string, suggestedEmail: string, suggestedFirstName?: Maybe<string>, suggestedLastName?: Maybe<string>, suggestedPhoneNumber?: Maybe<string>, suggestedNotificationNumber?: Maybe<string> }> } };

export type SpaceCreateMutationVariables = Exact<{
  input: SpaceCreateInput;
}>;


export type SpaceCreateMutation = { __typename?: 'Mutations', spaceCreate: { __typename?: 'SpacePayload', space: { __typename?: 'SpaceNode', id: any } } };

export type SpaceDeleteMembershipsMutationVariables = Exact<{
  input: SpaceDeleteMembershipsInput;
}>;


export type SpaceDeleteMembershipsMutation = { __typename?: 'Mutations', spaceDeleteMemberships?: Maybe<any> };

export type SpaceInviteUsersMutationVariables = Exact<{
  input: SpaceUsersToAddInput;
}>;


export type SpaceInviteUsersMutation = { __typename?: 'Mutations', spaceInviteUsers: { __typename?: 'SpaceInviteUsersPayload', failedEmails: Array<string>, space: { __typename?: 'SpaceNode', id: any } } };

export type SpacePublishMutationVariables = Exact<{
  input: SpaceInput;
}>;


export type SpacePublishMutation = { __typename?: 'Mutations', spacePublish: { __typename?: 'SpacePayload', space: (
      { __typename?: 'SpaceNode' }
      & SpaceFragment
    ) } };

export type SpaceSendInviteMutationVariables = Exact<{
  input: SpaceSendInviteInput;
}>;


export type SpaceSendInviteMutation = { __typename?: 'Mutations', spaceSendInvite?: Maybe<any> };

export type SpaceUnarchiveMutationVariables = Exact<{
  input: SpaceInput;
}>;


export type SpaceUnarchiveMutation = { __typename?: 'Mutations', spaceUnarchive: { __typename?: 'SpacePayload', space: (
      { __typename?: 'SpaceNode' }
      & SpaceFragment
    ) } };

export type SpaceUpdateMutationVariables = Exact<{
  input: SpaceUpdateInput;
}>;


export type SpaceUpdateMutation = { __typename?: 'Mutations', spaceUpdate: { __typename?: 'SpacePayload', space: (
      { __typename?: 'SpaceNode' }
      & SpaceFragment
    ) } };

export type TokenAuthMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type TokenAuthMutation = { __typename?: 'Mutations', tokenAuth: { __typename?: 'Token', token: string, refreshToken: string, refreshExpiresIn: number } };

export type ContactGroupQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type ContactGroupQuery = { __typename?: 'Query', contactGroup: { __typename?: 'ContactGroupNode', id: any, name: string, private: boolean, userCount: number, users: { __typename?: 'ContactGroupUserConnection', edges: Array<{ __typename?: 'ContactGroupUserEdge', node: { __typename?: 'ContactGroupUser', role: Roles, user: { __typename?: 'UserNode', id: any, firstName: string, lastName: string, profileImage: string, isActive: boolean } } }> } } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: (
    { __typename?: 'MeNode' }
    & MeFragment
  ) };

export type MeValidationQueryVariables = Exact<{ [key: string]: never; }>;


export type MeValidationQuery = { __typename?: 'Query', me: (
    { __typename?: 'MeNode' }
    & MeValidationFragment
  ) };

export type OrganizationSpacesQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type OrganizationSpacesQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationNode', id: any, spaces: { __typename?: 'SpaceNodeConnection', edges: Array<{ __typename?: 'SpaceNodeEdge', node: (
          { __typename?: 'SpaceNode', managerCount: number, members: { __typename?: 'MembershipNodeConnection', totalCount?: Maybe<number> } }
          & ListedSpaceFragment
        ) }> } } };

export type OrganizationSpacesSelectQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type OrganizationSpacesSelectQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationNode', id: any, spaces: { __typename?: 'SpaceNodeConnection', edges: Array<{ __typename?: 'SpaceNodeEdge', node: (
          { __typename?: 'SpaceNode' }
          & OrganizationSpaceSelectFragment
        ) }> } } };

export type OrganizationSpacesWithContactGroupsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type OrganizationSpacesWithContactGroupsQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationNode', id: any, spaces: { __typename?: 'SpaceNodeConnection', edges: Array<{ __typename?: 'SpaceNodeEdge', node: (
          { __typename?: 'SpaceNode', contactGroups: { __typename?: 'ContactGroupNodeConnection', edges: Array<{ __typename?: 'ContactGroupNodeEdge', node: (
                { __typename?: 'ContactGroupNode' }
                & SearchedContactGroupFragment
              ) }> } }
          & ListedSpaceFragment
        ) }> } } };

export type OrganizationUsersQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  query: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  order: Maybe<OrganizationUserOrder>;
}>;


export type OrganizationUsersQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationNode', id: any, users: { __typename?: 'UserNodeConnection', totalCount?: Maybe<number>, edges: Array<{ __typename?: 'UserNodeEdge', cursor: string, node: (
          { __typename?: 'UserNode' }
          & ListedUserFragment
        ) }> }, spaces: { __typename?: 'SpaceNodeConnection', pageInfo: { __typename?: 'PageInfo', startCursor?: Maybe<string> } } } };

export type SearchOrganizationUsersQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  query: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
}>;


export type SearchOrganizationUsersQuery = { __typename?: 'Query', organization: { __typename?: 'OrganizationNode', id: any, users: { __typename?: 'UserNodeConnection', edges: Array<{ __typename?: 'UserNodeEdge', node: (
          { __typename?: 'UserNode' }
          & SearchedUserFragment
        ) }> } } };

export type SearchSpaceMembersQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  query: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
}>;


export type SearchSpaceMembersQuery = { __typename?: 'Query', space: { __typename?: 'SpaceNode', id: any, members: { __typename?: 'MembershipNodeConnection', edges: Array<{ __typename?: 'MembershipNodeEdge', node: { __typename?: 'MembershipNode', id: any, role: Roles, user: (
            { __typename?: 'UserNode' }
            & SearchedUserFragment
          ) } }> } } };

export type SpaceQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  order: Maybe<SpaceMembershipOrder>;
  query: Maybe<Scalars['String']>;
  activated: Maybe<Scalars['Boolean']>;
}>;


export type SpaceQuery = { __typename?: 'Query', space: (
    { __typename?: 'SpaceNode', members: { __typename?: 'MembershipNodeConnection', totalCount?: Maybe<number>, edges: Array<{ __typename?: 'MembershipNodeEdge', node: (
          { __typename?: 'MembershipNode' }
          & SpaceMemberFragment
        ) }> } }
    & SpaceFragment
  ) };

export type SpaceGroupsQueryVariables = Exact<{
  id: Scalars['GlobalID'];
}>;


export type SpaceGroupsQuery = { __typename?: 'Query', space: { __typename?: 'SpaceNode', id: any, contactGroups: { __typename?: 'ContactGroupNodeConnection', edges: Array<{ __typename?: 'ContactGroupNodeEdge', node: (
          { __typename?: 'ContactGroupNode' }
          & SearchedContactGroupFragment
        ) }> } } };

export type UserQueryVariables = Exact<{
  id: Scalars['GlobalID'];
  organization: Scalars['GlobalID'];
}>;


export type UserQuery = { __typename?: 'Query', user: (
    { __typename?: 'UserNode', memberships: { __typename?: 'MembershipNodeConnection', edges: Array<{ __typename?: 'MembershipNodeEdge', node: (
          { __typename?: 'MembershipNode' }
          & UserMembershipFragment
        ) }> } }
    & UserFragment
  ) };

export const ListedSpaceFragmentDoc = gql`
    fragment ListedSpace on SpaceNode {
  id
  name
  logo
  state
}
    `;
export const SpaceMembershipFragmentDoc = gql`
    fragment SpaceMembership on MembershipNode {
  id
  space {
    id
    name
  }
}
    `;
export const ListedUserFragmentDoc = gql`
    fragment ListedUser on UserNode {
  id
  email
  firstName
  lastName
  isActive
  profileImage
  memberships(organization: $id) {
    edges {
      node {
        ...SpaceMembership
      }
    }
  }
}
    ${SpaceMembershipFragmentDoc}`;
export const OwnerFragmentDoc = gql`
    fragment Owner on UserNode {
  id
  email
  firstName
  lastName
  profileImage
}
    `;
export const OwnedOrganizationFragmentDoc = gql`
    fragment OwnedOrganization on OrganizationNode {
  id
  crn
  tin
  name
  logo
  officialName
  owners {
    edges {
      node {
        ...Owner
      }
    }
  }
  spaces(all: true) {
    edges {
      node {
        id
      }
    }
  }
}
    ${OwnerFragmentDoc}`;
export const MeFragmentDoc = gql`
    fragment Me on MeNode {
  id
  email
  userId
  isActive
  firstName
  lastName
  phoneNumber
  notificationNumber
  profileImage
  language
  ownedOrganizations {
    edges {
      node {
        ...OwnedOrganization
      }
    }
  }
}
    ${OwnedOrganizationFragmentDoc}`;
export const MeValidationFragmentDoc = gql`
    fragment MeValidation on MeNode {
  id
  ownedOrganizations {
    pageInfo {
      startCursor
    }
  }
}
    `;
export const OrganizationSpaceSelectFragmentDoc = gql`
    fragment OrganizationSpaceSelect on SpaceNode {
  id
  name
  state
}
    `;
export const SearchedContactGroupFragmentDoc = gql`
    fragment SearchedContactGroup on ContactGroupNode {
  id
  name
  private
}
    `;
export const SearchedUserFragmentDoc = gql`
    fragment SearchedUser on UserNode {
  id
  email
  firstName
  lastName
  isActive
  profileImage
}
    `;
export const SpaceFragmentDoc = gql`
    fragment Space on SpaceNode {
  id
  name
  officialName
  state
  color
  logo
  header
}
    `;
export const UserContactGroupFragmentDoc = gql`
    fragment UserContactGroup on ContactGroupNode {
  id
  name
}
    `;
export const SpaceMemberFragmentDoc = gql`
    fragment SpaceMember on MembershipNode {
  id
  role
  position
  user {
    id
    email
    firstName
    lastName
    profileImage
    isActive
  }
  contactGroups {
    edges {
      node {
        ...UserContactGroup
      }
    }
  }
}
    ${UserContactGroupFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on UserNode {
  id
  firstName
  lastName
  email
  phoneNumber
  notificationNumber
  isActive
  activationCode
  profileImage
  about
}
    `;
export const UserSpaceFragmentDoc = gql`
    fragment UserSpace on SpaceNode {
  id
  name
  logo
  state
}
    `;
export const UserMembershipFragmentDoc = gql`
    fragment UserMembership on MembershipNode {
  id
  role
  position
  contactGroups {
    edges {
      node {
        ...UserContactGroup
      }
    }
  }
  space {
    ...UserSpace
  }
}
    ${UserContactGroupFragmentDoc}
${UserSpaceFragmentDoc}`;
export const ContactGroupCreateDocument = gql`
    mutation contactGroupCreate($input: ContactGroupCreateInput!) {
  contactGroupCreate(input: $input) {
    contactGroup {
      ...SearchedContactGroup
    }
  }
}
    ${SearchedContactGroupFragmentDoc}`;

/**
 * __useContactGroupCreateMutation__
 *
 * To run a mutation, you first call `useContactGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactGroupCreateMutation, { data, loading, error }] = useContactGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactGroupCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactGroupCreateMutation, ContactGroupCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactGroupCreateMutation, ContactGroupCreateMutationVariables>(ContactGroupCreateDocument, baseOptions);
      }
export type ContactGroupCreateMutationHookResult = ReturnType<typeof useContactGroupCreateMutation>;
export type ContactGroupCreateMutationResult = ApolloReactCommon.MutationResult<ContactGroupCreateMutation>;
export type ContactGroupCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactGroupCreateMutation, ContactGroupCreateMutationVariables>;
export const ContactGroupDeleteDocument = gql`
    mutation contactGroupDelete($input: ContactGroupDeleteInput!) {
  contactGroupDelete(input: $input) {
    success
    failedUsers {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useContactGroupDeleteMutation__
 *
 * To run a mutation, you first call `useContactGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactGroupDeleteMutation, { data, loading, error }] = useContactGroupDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactGroupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactGroupDeleteMutation, ContactGroupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactGroupDeleteMutation, ContactGroupDeleteMutationVariables>(ContactGroupDeleteDocument, baseOptions);
      }
export type ContactGroupDeleteMutationHookResult = ReturnType<typeof useContactGroupDeleteMutation>;
export type ContactGroupDeleteMutationResult = ApolloReactCommon.MutationResult<ContactGroupDeleteMutation>;
export type ContactGroupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactGroupDeleteMutation, ContactGroupDeleteMutationVariables>;
export const ContactGroupUpdateDocument = gql`
    mutation contactGroupUpdate($input: ContactGroupUpdateInput!) {
  contactGroupUpdate(input: $input) {
    success
    contactGroup {
      ...SearchedContactGroup
    }
    failedUsers {
      id
      firstName
      lastName
    }
  }
}
    ${SearchedContactGroupFragmentDoc}`;

/**
 * __useContactGroupUpdateMutation__
 *
 * To run a mutation, you first call `useContactGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactGroupUpdateMutation, { data, loading, error }] = useContactGroupUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactGroupUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactGroupUpdateMutation, ContactGroupUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactGroupUpdateMutation, ContactGroupUpdateMutationVariables>(ContactGroupUpdateDocument, baseOptions);
      }
export type ContactGroupUpdateMutationHookResult = ReturnType<typeof useContactGroupUpdateMutation>;
export type ContactGroupUpdateMutationResult = ApolloReactCommon.MutationResult<ContactGroupUpdateMutation>;
export type ContactGroupUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactGroupUpdateMutation, ContactGroupUpdateMutationVariables>;
export const MediaCreateDocument = gql`
    mutation mediaCreate($input: MediaInput!) {
  mediaCreate(input: $input) {
    media {
      id
    }
  }
}
    `;

/**
 * __useMediaCreateMutation__
 *
 * To run a mutation, you first call `useMediaCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaCreateMutation, { data, loading, error }] = useMediaCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaCreateMutation, MediaCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<MediaCreateMutation, MediaCreateMutationVariables>(MediaCreateDocument, baseOptions);
      }
export type MediaCreateMutationHookResult = ReturnType<typeof useMediaCreateMutation>;
export type MediaCreateMutationResult = ApolloReactCommon.MutationResult<MediaCreateMutation>;
export type MediaCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<MediaCreateMutation, MediaCreateMutationVariables>;
export const MediaPreviewDocument = gql`
    mutation mediaPreview($input: MediaInput!) {
  mediaPreview(input: $input) {
    preview
  }
}
    `;

/**
 * __useMediaPreviewMutation__
 *
 * To run a mutation, you first call `useMediaPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMediaPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mediaPreviewMutation, { data, loading, error }] = useMediaPreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMediaPreviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MediaPreviewMutation, MediaPreviewMutationVariables>) {
        return ApolloReactHooks.useMutation<MediaPreviewMutation, MediaPreviewMutationVariables>(MediaPreviewDocument, baseOptions);
      }
export type MediaPreviewMutationHookResult = ReturnType<typeof useMediaPreviewMutation>;
export type MediaPreviewMutationResult = ApolloReactCommon.MutationResult<MediaPreviewMutation>;
export type MediaPreviewMutationOptions = ApolloReactCommon.BaseMutationOptions<MediaPreviewMutation, MediaPreviewMutationVariables>;
export const OrganizationAddOwnerDocument = gql`
    mutation organizationAddOwner($input: OrganizationAddOwnerInput!) {
  organizationAddOwner(input: $input) {
    organization {
      ...OwnedOrganization
    }
  }
}
    ${OwnedOrganizationFragmentDoc}`;

/**
 * __useOrganizationAddOwnerMutation__
 *
 * To run a mutation, you first call `useOrganizationAddOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAddOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationAddOwnerMutation, { data, loading, error }] = useOrganizationAddOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationAddOwnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationAddOwnerMutation, OrganizationAddOwnerMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationAddOwnerMutation, OrganizationAddOwnerMutationVariables>(OrganizationAddOwnerDocument, baseOptions);
      }
export type OrganizationAddOwnerMutationHookResult = ReturnType<typeof useOrganizationAddOwnerMutation>;
export type OrganizationAddOwnerMutationResult = ApolloReactCommon.MutationResult<OrganizationAddOwnerMutation>;
export type OrganizationAddOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationAddOwnerMutation, OrganizationAddOwnerMutationVariables>;
export const OrganizationChangeMembershipDocument = gql`
    mutation organizationChangeMembership($input: OrganizationChangeMembershipInput!) {
  organizationChangeMembership(input: $input) {
    membership {
      ...SpaceMember
    }
  }
}
    ${SpaceMemberFragmentDoc}`;

/**
 * __useOrganizationChangeMembershipMutation__
 *
 * To run a mutation, you first call `useOrganizationChangeMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationChangeMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationChangeMembershipMutation, { data, loading, error }] = useOrganizationChangeMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationChangeMembershipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationChangeMembershipMutation, OrganizationChangeMembershipMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationChangeMembershipMutation, OrganizationChangeMembershipMutationVariables>(OrganizationChangeMembershipDocument, baseOptions);
      }
export type OrganizationChangeMembershipMutationHookResult = ReturnType<typeof useOrganizationChangeMembershipMutation>;
export type OrganizationChangeMembershipMutationResult = ApolloReactCommon.MutationResult<OrganizationChangeMembershipMutation>;
export type OrganizationChangeMembershipMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationChangeMembershipMutation, OrganizationChangeMembershipMutationVariables>;
export const OrganizationChangeUserDocument = gql`
    mutation organizationChangeUser($input: OrganizationChangeUserInput!) {
  organizationChangeUser(input: $input) {
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useOrganizationChangeUserMutation__
 *
 * To run a mutation, you first call `useOrganizationChangeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationChangeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationChangeUserMutation, { data, loading, error }] = useOrganizationChangeUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationChangeUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationChangeUserMutation, OrganizationChangeUserMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationChangeUserMutation, OrganizationChangeUserMutationVariables>(OrganizationChangeUserDocument, baseOptions);
      }
export type OrganizationChangeUserMutationHookResult = ReturnType<typeof useOrganizationChangeUserMutation>;
export type OrganizationChangeUserMutationResult = ApolloReactCommon.MutationResult<OrganizationChangeUserMutation>;
export type OrganizationChangeUserMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationChangeUserMutation, OrganizationChangeUserMutationVariables>;
export const OrganizationRemoveOwnerDocument = gql`
    mutation organizationRemoveOwner($input: OrganizationRemoveOwnerInput!) {
  organizationRemoveOwner(input: $input) {
    organization {
      ...OwnedOrganization
    }
  }
}
    ${OwnedOrganizationFragmentDoc}`;

/**
 * __useOrganizationRemoveOwnerMutation__
 *
 * To run a mutation, you first call `useOrganizationRemoveOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRemoveOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveOwnerMutation, { data, loading, error }] = useOrganizationRemoveOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationRemoveOwnerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationRemoveOwnerMutation, OrganizationRemoveOwnerMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationRemoveOwnerMutation, OrganizationRemoveOwnerMutationVariables>(OrganizationRemoveOwnerDocument, baseOptions);
      }
export type OrganizationRemoveOwnerMutationHookResult = ReturnType<typeof useOrganizationRemoveOwnerMutation>;
export type OrganizationRemoveOwnerMutationResult = ApolloReactCommon.MutationResult<OrganizationRemoveOwnerMutation>;
export type OrganizationRemoveOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationRemoveOwnerMutation, OrganizationRemoveOwnerMutationVariables>;
export const OrganizationRemoveUsersDocument = gql`
    mutation organizationRemoveUsers($input: OrganizationRemoveUsersInput!) {
  organizationRemoveUsers(input: $input) {
    organization {
      id
    }
  }
}
    `;

/**
 * __useOrganizationRemoveUsersMutation__
 *
 * To run a mutation, you first call `useOrganizationRemoveUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRemoveUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationRemoveUsersMutation, { data, loading, error }] = useOrganizationRemoveUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationRemoveUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationRemoveUsersMutation, OrganizationRemoveUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationRemoveUsersMutation, OrganizationRemoveUsersMutationVariables>(OrganizationRemoveUsersDocument, baseOptions);
      }
export type OrganizationRemoveUsersMutationHookResult = ReturnType<typeof useOrganizationRemoveUsersMutation>;
export type OrganizationRemoveUsersMutationResult = ApolloReactCommon.MutationResult<OrganizationRemoveUsersMutation>;
export type OrganizationRemoveUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationRemoveUsersMutation, OrganizationRemoveUsersMutationVariables>;
export const OrganizationUpdateDocument = gql`
    mutation organizationUpdate($input: OrganizationUpdateInput!) {
  organizationUpdate(input: $input) {
    organization {
      ...OwnedOrganization
    }
  }
}
    ${OwnedOrganizationFragmentDoc}`;

/**
 * __useOrganizationUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdateMutation, { data, loading, error }] = useOrganizationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>(OrganizationUpdateDocument, baseOptions);
      }
export type OrganizationUpdateMutationHookResult = ReturnType<typeof useOrganizationUpdateMutation>;
export type OrganizationUpdateMutationResult = ApolloReactCommon.MutationResult<OrganizationUpdateMutation>;
export type OrganizationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OrganizationUpdateMutation, OrganizationUpdateMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String!) {
  tokenRefresh(refreshToken: $refreshToken) {
    token
    refreshToken
    refreshExpiresIn
  }
}
    `;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, baseOptions);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = ApolloReactCommon.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const SpaceArchiveDocument = gql`
    mutation spaceArchive($input: SpaceInput!) {
  spaceArchive(input: $input) {
    space {
      ...Space
    }
  }
}
    ${SpaceFragmentDoc}`;

/**
 * __useSpaceArchiveMutation__
 *
 * To run a mutation, you first call `useSpaceArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceArchiveMutation, { data, loading, error }] = useSpaceArchiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceArchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceArchiveMutation, SpaceArchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceArchiveMutation, SpaceArchiveMutationVariables>(SpaceArchiveDocument, baseOptions);
      }
export type SpaceArchiveMutationHookResult = ReturnType<typeof useSpaceArchiveMutation>;
export type SpaceArchiveMutationResult = ApolloReactCommon.MutationResult<SpaceArchiveMutation>;
export type SpaceArchiveMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceArchiveMutation, SpaceArchiveMutationVariables>;
export const SpaceCheckUsersDocument = gql`
    mutation spaceCheckUsers($input: SpaceUsersToAddInput!) {
  spaceCheckUsers(input: $input) {
    suggestedChanges {
      originalEmail
      suggestedEmail
      suggestedFirstName
      suggestedLastName
      suggestedPhoneNumber
      suggestedNotificationNumber
    }
  }
}
    `;

/**
 * __useSpaceCheckUsersMutation__
 *
 * To run a mutation, you first call `useSpaceCheckUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceCheckUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceCheckUsersMutation, { data, loading, error }] = useSpaceCheckUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceCheckUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceCheckUsersMutation, SpaceCheckUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceCheckUsersMutation, SpaceCheckUsersMutationVariables>(SpaceCheckUsersDocument, baseOptions);
      }
export type SpaceCheckUsersMutationHookResult = ReturnType<typeof useSpaceCheckUsersMutation>;
export type SpaceCheckUsersMutationResult = ApolloReactCommon.MutationResult<SpaceCheckUsersMutation>;
export type SpaceCheckUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceCheckUsersMutation, SpaceCheckUsersMutationVariables>;
export const SpaceCreateDocument = gql`
    mutation spaceCreate($input: SpaceCreateInput!) {
  spaceCreate(input: $input) {
    space {
      id
    }
  }
}
    `;

/**
 * __useSpaceCreateMutation__
 *
 * To run a mutation, you first call `useSpaceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceCreateMutation, { data, loading, error }] = useSpaceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceCreateMutation, SpaceCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceCreateMutation, SpaceCreateMutationVariables>(SpaceCreateDocument, baseOptions);
      }
export type SpaceCreateMutationHookResult = ReturnType<typeof useSpaceCreateMutation>;
export type SpaceCreateMutationResult = ApolloReactCommon.MutationResult<SpaceCreateMutation>;
export type SpaceCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceCreateMutation, SpaceCreateMutationVariables>;
export const SpaceDeleteMembershipsDocument = gql`
    mutation spaceDeleteMemberships($input: SpaceDeleteMembershipsInput!) {
  spaceDeleteMemberships(input: $input)
}
    `;

/**
 * __useSpaceDeleteMembershipsMutation__
 *
 * To run a mutation, you first call `useSpaceDeleteMembershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceDeleteMembershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceDeleteMembershipsMutation, { data, loading, error }] = useSpaceDeleteMembershipsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceDeleteMembershipsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceDeleteMembershipsMutation, SpaceDeleteMembershipsMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceDeleteMembershipsMutation, SpaceDeleteMembershipsMutationVariables>(SpaceDeleteMembershipsDocument, baseOptions);
      }
export type SpaceDeleteMembershipsMutationHookResult = ReturnType<typeof useSpaceDeleteMembershipsMutation>;
export type SpaceDeleteMembershipsMutationResult = ApolloReactCommon.MutationResult<SpaceDeleteMembershipsMutation>;
export type SpaceDeleteMembershipsMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceDeleteMembershipsMutation, SpaceDeleteMembershipsMutationVariables>;
export const SpaceInviteUsersDocument = gql`
    mutation spaceInviteUsers($input: SpaceUsersToAddInput!) {
  spaceInviteUsers(input: $input) {
    failedEmails
    space {
      id
    }
  }
}
    `;

/**
 * __useSpaceInviteUsersMutation__
 *
 * To run a mutation, you first call `useSpaceInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceInviteUsersMutation, { data, loading, error }] = useSpaceInviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceInviteUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceInviteUsersMutation, SpaceInviteUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceInviteUsersMutation, SpaceInviteUsersMutationVariables>(SpaceInviteUsersDocument, baseOptions);
      }
export type SpaceInviteUsersMutationHookResult = ReturnType<typeof useSpaceInviteUsersMutation>;
export type SpaceInviteUsersMutationResult = ApolloReactCommon.MutationResult<SpaceInviteUsersMutation>;
export type SpaceInviteUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceInviteUsersMutation, SpaceInviteUsersMutationVariables>;
export const SpacePublishDocument = gql`
    mutation spacePublish($input: SpaceInput!) {
  spacePublish(input: $input) {
    space {
      ...Space
    }
  }
}
    ${SpaceFragmentDoc}`;

/**
 * __useSpacePublishMutation__
 *
 * To run a mutation, you first call `useSpacePublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpacePublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spacePublishMutation, { data, loading, error }] = useSpacePublishMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpacePublishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpacePublishMutation, SpacePublishMutationVariables>) {
        return ApolloReactHooks.useMutation<SpacePublishMutation, SpacePublishMutationVariables>(SpacePublishDocument, baseOptions);
      }
export type SpacePublishMutationHookResult = ReturnType<typeof useSpacePublishMutation>;
export type SpacePublishMutationResult = ApolloReactCommon.MutationResult<SpacePublishMutation>;
export type SpacePublishMutationOptions = ApolloReactCommon.BaseMutationOptions<SpacePublishMutation, SpacePublishMutationVariables>;
export const SpaceSendInviteDocument = gql`
    mutation spaceSendInvite($input: SpaceSendInviteInput!) {
  spaceSendInvite(input: $input)
}
    `;

/**
 * __useSpaceSendInviteMutation__
 *
 * To run a mutation, you first call `useSpaceSendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceSendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceSendInviteMutation, { data, loading, error }] = useSpaceSendInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceSendInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceSendInviteMutation, SpaceSendInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceSendInviteMutation, SpaceSendInviteMutationVariables>(SpaceSendInviteDocument, baseOptions);
      }
export type SpaceSendInviteMutationHookResult = ReturnType<typeof useSpaceSendInviteMutation>;
export type SpaceSendInviteMutationResult = ApolloReactCommon.MutationResult<SpaceSendInviteMutation>;
export type SpaceSendInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceSendInviteMutation, SpaceSendInviteMutationVariables>;
export const SpaceUnarchiveDocument = gql`
    mutation spaceUnarchive($input: SpaceInput!) {
  spaceUnarchive(input: $input) {
    space {
      ...Space
    }
  }
}
    ${SpaceFragmentDoc}`;

/**
 * __useSpaceUnarchiveMutation__
 *
 * To run a mutation, you first call `useSpaceUnarchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceUnarchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceUnarchiveMutation, { data, loading, error }] = useSpaceUnarchiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceUnarchiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceUnarchiveMutation, SpaceUnarchiveMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceUnarchiveMutation, SpaceUnarchiveMutationVariables>(SpaceUnarchiveDocument, baseOptions);
      }
export type SpaceUnarchiveMutationHookResult = ReturnType<typeof useSpaceUnarchiveMutation>;
export type SpaceUnarchiveMutationResult = ApolloReactCommon.MutationResult<SpaceUnarchiveMutation>;
export type SpaceUnarchiveMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceUnarchiveMutation, SpaceUnarchiveMutationVariables>;
export const SpaceUpdateDocument = gql`
    mutation spaceUpdate($input: SpaceUpdateInput!) {
  spaceUpdate(input: $input) {
    space {
      ...Space
    }
  }
}
    ${SpaceFragmentDoc}`;

/**
 * __useSpaceUpdateMutation__
 *
 * To run a mutation, you first call `useSpaceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpaceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spaceUpdateMutation, { data, loading, error }] = useSpaceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpaceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpaceUpdateMutation, SpaceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SpaceUpdateMutation, SpaceUpdateMutationVariables>(SpaceUpdateDocument, baseOptions);
      }
export type SpaceUpdateMutationHookResult = ReturnType<typeof useSpaceUpdateMutation>;
export type SpaceUpdateMutationResult = ApolloReactCommon.MutationResult<SpaceUpdateMutation>;
export type SpaceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SpaceUpdateMutation, SpaceUpdateMutationVariables>;
export const TokenAuthDocument = gql`
    mutation tokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
    token
    refreshToken
    refreshExpiresIn
  }
}
    `;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>) {
        return ApolloReactHooks.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(TokenAuthDocument, baseOptions);
      }
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = ApolloReactCommon.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<TokenAuthMutation, TokenAuthMutationVariables>;
export const ContactGroupDocument = gql`
    query contactGroup($id: GlobalID!) {
  contactGroup(id: $id) {
    id
    name
    private
    userCount
    users {
      edges {
        node {
          role
          user {
            id
            firstName
            lastName
            profileImage
            isActive
          }
        }
      }
    }
  }
}
    `;

/**
 * __useContactGroupQuery__
 *
 * To run a query within a React component, call `useContactGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactGroupQuery, ContactGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactGroupQuery, ContactGroupQueryVariables>(ContactGroupDocument, baseOptions);
      }
export function useContactGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactGroupQuery, ContactGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactGroupQuery, ContactGroupQueryVariables>(ContactGroupDocument, baseOptions);
        }
export type ContactGroupQueryHookResult = ReturnType<typeof useContactGroupQuery>;
export type ContactGroupLazyQueryHookResult = ReturnType<typeof useContactGroupLazyQuery>;
export type ContactGroupQueryResult = ApolloReactCommon.QueryResult<ContactGroupQuery, ContactGroupQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const MeValidationDocument = gql`
    query meValidation {
  me {
    ...MeValidation
  }
}
    ${MeValidationFragmentDoc}`;

/**
 * __useMeValidationQuery__
 *
 * To run a query within a React component, call `useMeValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeValidationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeValidationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeValidationQuery, MeValidationQueryVariables>) {
        return ApolloReactHooks.useQuery<MeValidationQuery, MeValidationQueryVariables>(MeValidationDocument, baseOptions);
      }
export function useMeValidationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeValidationQuery, MeValidationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeValidationQuery, MeValidationQueryVariables>(MeValidationDocument, baseOptions);
        }
export type MeValidationQueryHookResult = ReturnType<typeof useMeValidationQuery>;
export type MeValidationLazyQueryHookResult = ReturnType<typeof useMeValidationLazyQuery>;
export type MeValidationQueryResult = ApolloReactCommon.QueryResult<MeValidationQuery, MeValidationQueryVariables>;
export const OrganizationSpacesDocument = gql`
    query organizationSpaces($id: GlobalID!) {
  organization(id: $id) {
    id
    spaces(all: true) {
      edges {
        node {
          ...ListedSpace
          managerCount
          members {
            totalCount
          }
        }
      }
    }
  }
}
    ${ListedSpaceFragmentDoc}`;

/**
 * __useOrganizationSpacesQuery__
 *
 * To run a query within a React component, call `useOrganizationSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSpacesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationSpacesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSpacesQuery, OrganizationSpacesQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSpacesQuery, OrganizationSpacesQueryVariables>(OrganizationSpacesDocument, baseOptions);
      }
export function useOrganizationSpacesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSpacesQuery, OrganizationSpacesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSpacesQuery, OrganizationSpacesQueryVariables>(OrganizationSpacesDocument, baseOptions);
        }
export type OrganizationSpacesQueryHookResult = ReturnType<typeof useOrganizationSpacesQuery>;
export type OrganizationSpacesLazyQueryHookResult = ReturnType<typeof useOrganizationSpacesLazyQuery>;
export type OrganizationSpacesQueryResult = ApolloReactCommon.QueryResult<OrganizationSpacesQuery, OrganizationSpacesQueryVariables>;
export const OrganizationSpacesSelectDocument = gql`
    query organizationSpacesSelect($id: GlobalID!) {
  organization(id: $id) {
    id
    spaces(all: true) {
      edges {
        node {
          ...OrganizationSpaceSelect
        }
      }
    }
  }
}
    ${OrganizationSpaceSelectFragmentDoc}`;

/**
 * __useOrganizationSpacesSelectQuery__
 *
 * To run a query within a React component, call `useOrganizationSpacesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSpacesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSpacesSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationSpacesSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSpacesSelectQuery, OrganizationSpacesSelectQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSpacesSelectQuery, OrganizationSpacesSelectQueryVariables>(OrganizationSpacesSelectDocument, baseOptions);
      }
export function useOrganizationSpacesSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSpacesSelectQuery, OrganizationSpacesSelectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSpacesSelectQuery, OrganizationSpacesSelectQueryVariables>(OrganizationSpacesSelectDocument, baseOptions);
        }
export type OrganizationSpacesSelectQueryHookResult = ReturnType<typeof useOrganizationSpacesSelectQuery>;
export type OrganizationSpacesSelectLazyQueryHookResult = ReturnType<typeof useOrganizationSpacesSelectLazyQuery>;
export type OrganizationSpacesSelectQueryResult = ApolloReactCommon.QueryResult<OrganizationSpacesSelectQuery, OrganizationSpacesSelectQueryVariables>;
export const OrganizationSpacesWithContactGroupsDocument = gql`
    query organizationSpacesWithContactGroups($id: GlobalID!) {
  organization(id: $id) {
    id
    spaces(all: true) {
      edges {
        node {
          ...ListedSpace
          contactGroups {
            edges {
              node {
                ...SearchedContactGroup
              }
            }
          }
        }
      }
    }
  }
}
    ${ListedSpaceFragmentDoc}
${SearchedContactGroupFragmentDoc}`;

/**
 * __useOrganizationSpacesWithContactGroupsQuery__
 *
 * To run a query within a React component, call `useOrganizationSpacesWithContactGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSpacesWithContactGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSpacesWithContactGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationSpacesWithContactGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSpacesWithContactGroupsQuery, OrganizationSpacesWithContactGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSpacesWithContactGroupsQuery, OrganizationSpacesWithContactGroupsQueryVariables>(OrganizationSpacesWithContactGroupsDocument, baseOptions);
      }
export function useOrganizationSpacesWithContactGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSpacesWithContactGroupsQuery, OrganizationSpacesWithContactGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSpacesWithContactGroupsQuery, OrganizationSpacesWithContactGroupsQueryVariables>(OrganizationSpacesWithContactGroupsDocument, baseOptions);
        }
export type OrganizationSpacesWithContactGroupsQueryHookResult = ReturnType<typeof useOrganizationSpacesWithContactGroupsQuery>;
export type OrganizationSpacesWithContactGroupsLazyQueryHookResult = ReturnType<typeof useOrganizationSpacesWithContactGroupsLazyQuery>;
export type OrganizationSpacesWithContactGroupsQueryResult = ApolloReactCommon.QueryResult<OrganizationSpacesWithContactGroupsQuery, OrganizationSpacesWithContactGroupsQueryVariables>;
export const OrganizationUsersDocument = gql`
    query organizationUsers($id: GlobalID!, $query: String, $after: String, $first: Int, $order: OrganizationUserOrder) {
  organization(id: $id) {
    id
    users(query: $query, first: $first, after: $after, order: $order) {
      totalCount
      edges {
        cursor
        node {
          ...ListedUser
        }
      }
    }
    spaces(all: true) {
      pageInfo {
        startCursor
      }
    }
  }
}
    ${ListedUserFragmentDoc}`;

/**
 * __useOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrganizationUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, baseOptions);
      }
export function useOrganizationUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationUsersQuery, OrganizationUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationUsersQuery, OrganizationUsersQueryVariables>(OrganizationUsersDocument, baseOptions);
        }
export type OrganizationUsersQueryHookResult = ReturnType<typeof useOrganizationUsersQuery>;
export type OrganizationUsersLazyQueryHookResult = ReturnType<typeof useOrganizationUsersLazyQuery>;
export type OrganizationUsersQueryResult = ApolloReactCommon.QueryResult<OrganizationUsersQuery, OrganizationUsersQueryVariables>;
export const SearchOrganizationUsersDocument = gql`
    query searchOrganizationUsers($id: GlobalID!, $query: String, $first: Int) {
  organization(id: $id) {
    id
    users(query: $query, first: $first) {
      edges {
        node {
          ...SearchedUser
        }
      }
    }
  }
}
    ${SearchedUserFragmentDoc}`;

/**
 * __useSearchOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useSearchOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOrganizationUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchOrganizationUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchOrganizationUsersQuery, SearchOrganizationUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchOrganizationUsersQuery, SearchOrganizationUsersQueryVariables>(SearchOrganizationUsersDocument, baseOptions);
      }
export function useSearchOrganizationUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchOrganizationUsersQuery, SearchOrganizationUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchOrganizationUsersQuery, SearchOrganizationUsersQueryVariables>(SearchOrganizationUsersDocument, baseOptions);
        }
export type SearchOrganizationUsersQueryHookResult = ReturnType<typeof useSearchOrganizationUsersQuery>;
export type SearchOrganizationUsersLazyQueryHookResult = ReturnType<typeof useSearchOrganizationUsersLazyQuery>;
export type SearchOrganizationUsersQueryResult = ApolloReactCommon.QueryResult<SearchOrganizationUsersQuery, SearchOrganizationUsersQueryVariables>;
export const SearchSpaceMembersDocument = gql`
    query searchSpaceMembers($id: GlobalID!, $query: String, $first: Int) {
  space(id: $id) {
    id
    members(query: $query, first: $first) {
      edges {
        node {
          id
          role
          user {
            ...SearchedUser
          }
        }
      }
    }
  }
}
    ${SearchedUserFragmentDoc}`;

/**
 * __useSearchSpaceMembersQuery__
 *
 * To run a query within a React component, call `useSearchSpaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSpaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSpaceMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchSpaceMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchSpaceMembersQuery, SearchSpaceMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchSpaceMembersQuery, SearchSpaceMembersQueryVariables>(SearchSpaceMembersDocument, baseOptions);
      }
export function useSearchSpaceMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSpaceMembersQuery, SearchSpaceMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchSpaceMembersQuery, SearchSpaceMembersQueryVariables>(SearchSpaceMembersDocument, baseOptions);
        }
export type SearchSpaceMembersQueryHookResult = ReturnType<typeof useSearchSpaceMembersQuery>;
export type SearchSpaceMembersLazyQueryHookResult = ReturnType<typeof useSearchSpaceMembersLazyQuery>;
export type SearchSpaceMembersQueryResult = ApolloReactCommon.QueryResult<SearchSpaceMembersQuery, SearchSpaceMembersQueryVariables>;
export const SpaceDocument = gql`
    query space($id: GlobalID!, $after: String, $first: Int, $order: SpaceMembershipOrder, $query: String, $activated: Boolean) {
  space(id: $id) {
    ...Space
    members(
      after: $after
      first: $first
      order: $order
      query: $query
      activated: $activated
    ) {
      totalCount
      edges {
        node {
          ...SpaceMember
        }
      }
    }
  }
}
    ${SpaceFragmentDoc}
${SpaceMemberFragmentDoc}`;

/**
 * __useSpaceQuery__
 *
 * To run a query within a React component, call `useSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      order: // value for 'order'
 *      query: // value for 'query'
 *      activated: // value for 'activated'
 *   },
 * });
 */
export function useSpaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceQuery, SpaceQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceQuery, SpaceQueryVariables>(SpaceDocument, baseOptions);
      }
export function useSpaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceQuery, SpaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceQuery, SpaceQueryVariables>(SpaceDocument, baseOptions);
        }
export type SpaceQueryHookResult = ReturnType<typeof useSpaceQuery>;
export type SpaceLazyQueryHookResult = ReturnType<typeof useSpaceLazyQuery>;
export type SpaceQueryResult = ApolloReactCommon.QueryResult<SpaceQuery, SpaceQueryVariables>;
export const SpaceGroupsDocument = gql`
    query spaceGroups($id: GlobalID!) {
  space(id: $id) {
    id
    contactGroups {
      edges {
        node {
          ...SearchedContactGroup
        }
      }
    }
  }
}
    ${SearchedContactGroupFragmentDoc}`;

/**
 * __useSpaceGroupsQuery__
 *
 * To run a query within a React component, call `useSpaceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpaceGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpaceGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpaceGroupsQuery, SpaceGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpaceGroupsQuery, SpaceGroupsQueryVariables>(SpaceGroupsDocument, baseOptions);
      }
export function useSpaceGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpaceGroupsQuery, SpaceGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpaceGroupsQuery, SpaceGroupsQueryVariables>(SpaceGroupsDocument, baseOptions);
        }
export type SpaceGroupsQueryHookResult = ReturnType<typeof useSpaceGroupsQuery>;
export type SpaceGroupsLazyQueryHookResult = ReturnType<typeof useSpaceGroupsLazyQuery>;
export type SpaceGroupsQueryResult = ApolloReactCommon.QueryResult<SpaceGroupsQuery, SpaceGroupsQueryVariables>;
export const UserDocument = gql`
    query user($id: GlobalID!, $organization: GlobalID!) {
  user(id: $id) {
    ...User
    memberships(organization: $organization) {
      edges {
        node {
          ...UserMembership
        }
      }
    }
  }
}
    ${UserFragmentDoc}
${UserMembershipFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;