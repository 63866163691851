import React, { ChangeEvent, memo, useCallback, useState } from "react";
import { Box, TextInput } from "grommet";
import { Close, Save } from "grommet-icons";
import { GroupsRowAction } from "components";
import { tk, useTranslation } from "translations";

interface Props {
  onSave: (name: string) => any;
  onCancel: () => any;
}

export const GroupAddRow = memo(({ onSave, onCancel }: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);

  const handleSubmit = useCallback(() => {
    if (!value) return;

    onSave(value);
  }, [onSave, value]);

  return (
    <Box
      width={"100%"}
      direction={"row"}
      justify={"between"}
      align={"center"}
      gap={"small"}
      wrap={true}
      background={"light-2"}
      pad={{ vertical: "xxsmall", horizontal: "xsmall" }}
    >
      <Box align={"start"} width={"200px"}>
        <TextInput
          name={"newGroup"}
          value={value}
          placeholder={t(tk.groups.groups.placeholder)}
          size={"xsmall"}
          autoFocus={true}
          style={{ padding: "4px 8px", background: "white" }}
          onChange={handleChange}
        />
      </Box>

      <Box direction={"row"} gap={"small"}>
        <GroupsRowAction
          tooltip={t(tk.common.save)}
          icon={<Save size={"small"} />}
          disabled={!value}
          onClick={handleSubmit}
        />
        <GroupsRowAction tooltip={t(tk.common.cancel)} icon={<Close size={"small"} />} onClick={onCancel} />
      </Box>
    </Box>
  );
});
