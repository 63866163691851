import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { spaceColors } from "components";
import { useActiveOrganizationChange, useMedia, useNotification } from "hooks";
import { MediaTypes, SpaceState, SpaceUpdateInput, useSpaceQuery, useSpaceUpdateMutation } from "api";
import { tk, useTranslation } from "translations";
import { routes } from "routes";

const inputs = { name: "name", officialName: "officialName", color: "color" };

type Value = typeof inputs;

const defaultValue = { [inputs.name]: "", [inputs.officialName]: "", [inputs.color]: spaceColors[0] } as Value;

export const useSpaceEdit = (id: string) => {
  const { t } = useTranslation();

  useTitle(t(tk.common.editSpace) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const notification = useNotification();

  // On organization change, go to spaces
  useActiveOrganizationChange(() => history.replace(routes.spaces));

  const { data, loading: loadingSpace } = useSpaceQuery({
    variables: { id, after: null, first: 0, order: null, query: null, activated: null },
    fetchPolicy: "cache-and-network",
  });

  const space = useMemo(() => data?.space, [data]);

  const [update, { loading: loadingUpdate }] = useSpaceUpdateMutation();

  const [value, setValue] = useState<Value>(defaultValue);

  const media = {
    logo: useMedia(MediaTypes.SpaceLogo),
    header: useMedia(MediaTypes.SpaceHeader),
  };

  const [color, setColor] = useState(defaultValue.color);

  useEffect(() => {
    if (!space) return;

    let { name, officialName, color } = space;

    if (!spaceColors.includes(color.toLowerCase())) {
      color = spaceColors[0];
    }

    setValue({ name, officialName, color });
    setColor(color);
  }, [space]);

  const loading = useMemo(
    () => media.logo.loading || media.header.loading || loadingUpdate || loadingSpace,
    [loadingSpace, loadingUpdate, media.header.loading, media.logo.loading]
  );

  const handleChange = useCallback((_value: any) => setValue(_value), []);

  const handleChangeColor = useCallback((_value: string) => setColor(_value), []);

  const handleSubmit = useCallback(async () => {
    if (loading) return;

    try {
      const { name, officialName, color } = value;

      const input: SpaceUpdateInput = { space: id };

      if (!!media.logo.preview) input.logo = await media.logo.upload();
      if (!!media.header.preview) input.header = await media.header.upload();
      if (name !== space?.name && name.trim().length > 0) input.name = name;
      if (officialName !== space?.officialName && officialName.trim().length > 0) input.officialName = officialName;
      if (color !== space?.color) input.color = color;

      if (Object.keys(input).length === 1) {
        await notification.show(t(tk.spaceEdit.notifications.success));
        return history.goBack();
      }

      await update({ variables: { input } });

      await notification.show(t(tk.spaceEdit.notifications.success));

      history.goBack();
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [history, id, loading, media.header, media.logo, notification, space, t, update, value]);

  const handleCancel = useCallback(() => history.goBack(), [history]);

  useEffect(() => {
    if (space && space.state === SpaceState.Archived) handleCancel();
  }, [handleCancel, space]);

  return {
    inputs,
    media,
    data: { space },
    state: { loading, value, color },
    handlers: { handleChange, handleChangeColor, handleSubmit, handleCancel },
  };
};
