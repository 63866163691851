import React from "react";
import { Box, Image } from "grommet";
import { MainMenu, OrganizationSwitch, UserMenu } from "components";
import logo from "assets/images/moyobo.png";

export const Navigation = () => {
  return (
    <Box fill={true} align="center">
      <Image src={logo} margin={{ top: "medium" }} width={200} />

      <Box fill={true} justify={"start"}>
        <OrganizationSwitch />
        <MainMenu />
      </Box>

      <UserMenu />
    </Box>
  );
};
