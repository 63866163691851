import React from "react";
import { Box, Text } from "grommet";
import { LinkDown, LinkUp } from "grommet-icons";

interface Props {
  value: string;
  sortable?: boolean;
  order?: "asc" | "desc";
  onClick?: () => any;
}

export const DataTableHeader = ({ value, sortable = false, order, onClick = () => {} }: Props) => {
  if (!sortable || !order)
    return (
      <Text size={"small"} weight={"bold"}>
        {value}
      </Text>
    );

  return (
    <Box direction={"row"} align={"center"} gap={"xsmall"} onClick={onClick}>
      <Text size={"small"} weight={"bold"}>
        {value}
      </Text>
      {order === "desc" ? <LinkUp size={"small"} /> : <LinkDown size={"small"} />}
    </Box>
  );
};
