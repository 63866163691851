import React from "react";
import { Box, Drop, DropProps, Text } from "grommet";

interface Props {
  value: string;
  heading?: string;
  visible: boolean;
  target: object;
  align?: DropProps["align"];
  maxWidth?: string;
}

export const Tooltip = ({
  value,
  heading,
  visible,
  target,
  align = { top: "bottom", left: "left" },
  maxWidth,
}: Props) => {
  if (!visible || !target) return null;

  return (
    <Drop align={align} elevation={"none"} stretch={false} plain={true} overflow={"visible"} target={target}>
      <Box
        align="start"
        round="xsmall"
        background="light-2"
        margin={"xsmall"}
        width={{ max: maxWidth }}
        pad={{ vertical: "xsmall", horizontal: "small" }}
      >
        {heading && (
          <Text size={"small"} weight={"bold"} color={"dark-2"} margin={{ bottom: "xsmall" }}>
            {heading}
          </Text>
        )}
        <Text size={"small"} color={"dark-2"}>
          {value}
        </Text>
      </Box>
    </Drop>
  );
};
