import { useCallback, useMemo, useRef } from "react";
import useStore, { createStore } from "global-hook-store";
import { useUpdateEffect } from "react-use";
import { Trans } from "react-i18next";

type MessageValue = string | ReturnType<typeof Trans>;

type Message = {
  id: string;
  message: MessageValue;
  title?: string;
  error?: boolean;
};

const notificationStore = createStore(
  {
    visible: false,
    queue: [] as Message[],
  },
  {
    setQueue: (_state, queue: Message[]) => ({ ..._state, queue }),
    show: (_state) => ({ ..._state, visible: true }),
    hide: (_state) => ({ ..._state, visible: false }),
  }
);

export const useNotification = () => {
  const { state, actions } = useStore(notificationStore);

  const message = useMemo(() => state.queue[0], [state.queue]);
  const visible = useMemo(() => state.visible && !!message, [message, state.visible]);

  useUpdateEffect(() => {
    if (!!message) window.setTimeout(actions.show, 300);
  }, [actions, message]);

  const messageRef = useRef(message);
  messageRef.current = message;

  const queueRef = useRef(state.queue);
  queueRef.current = state.queue;

  const show = useCallback(
    async (message: MessageValue, error: boolean = false, title?: string) => {
      const id = (new Date().getTime() + Math.random()).toString();
      await actions.setQueue([...queueRef.current, { id, message, error, title }]);
    },
    [actions]
  );

  const hide = useCallback(
    async (id: string) => {
      if (!messageRef.current || messageRef.current.id !== id) return;

      await actions.hide();
      await actions.setQueue([...queueRef.current.slice(1)]);
    },
    [actions]
  );

  useMemo(() => {
    if (!message) return;

    window.setTimeout(() => hide(message.id), 6000);
  }, [hide, message]);

  return { visible, message, show, hide };
};
