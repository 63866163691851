import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDebounce, useTitle, useUpdateEffect } from "react-use";
import { useDeleteOrganizationMember, useMe, usePagination, useSorting, useTableCheckboxes } from "hooks";
import {
  ListedUserFragment,
  mapConnection,
  Ordering,
  OrganizationUserOrder,
  SpaceMembershipFragment,
  useOrganizationUsersQuery,
} from "api";
import { tk, useTranslation } from "translations";
import { MemberParams, routes, setRouteParams } from "routes";

const PAGE_SIZE = 10;

export const useMembers = () => {
  const { t } = useTranslation();

  useTitle(t(tk.members.heading) + t(tk.common.documentTitleSuffix));

  const history = useHistory();

  const { activeOrganization, refetch: refetchMe } = useMe();

  const pagination = usePagination(PAGE_SIZE);

  const sorting = useSorting<OrganizationUserOrder>(
    { asc: { lastName: Ordering.Asc }, desc: { lastName: Ordering.Desc } },
    "asc"
  );

  // Reset pagination and sorting on organization change
  useUpdateEffect(() => {
    if (!activeOrganization?.id || !pagination.pageCount) return;
    pagination.reset();
    sorting.reset();
  }, [activeOrganization]);

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState<string | null>(null);

  const { data, loading, called, refetch } = useOrganizationUsersQuery({
    variables: {
      id: activeOrganization?.id || "",
      query: debouncedQuery,
      after: pagination.after,
      first: PAGE_SIZE,
      order: sorting.current,
    },
    fetchPolicy: "cache-and-network",
    skip: !activeOrganization?.id,
  });

  const totalCount = useMemo(() => data?.organization?.users.totalCount || 0, [data]);

  useEffect(() => pagination.setTotalCount(totalCount), [pagination, totalCount]);

  useDebounce(
    async () => {
      if (query === debouncedQuery) return;
      if (query === "" && debouncedQuery === null) return;

      setDebouncedQuery(query || null);
      pagination.reset();
    },
    500,
    [query, debouncedQuery]
  );

  const hasSpaces = useMemo(() => !!data?.organization?.spaces.pageInfo.startCursor, [data]);

  const users = useMemo(
    () =>
      mapConnection<ListedUserFragment>(data?.organization?.users).map(({ memberships, ...user }) => ({
        spaces: mapConnection<SpaceMembershipFragment>(memberships || undefined)
          .map(({ space }) => space.name)
          .join(", "),
        ...user,
      })),
    [data]
  );

  const { checked, check, checkAll, clear } = useTableCheckboxes(users);

  // On page or sorting change, clear checkboxes
  useUpdateEffect(clear, [pagination.current, sorting.current]);

  const handleDeleteOrganizationMemberSuccess = useCallback(async () => {
    if (!activeOrganization) return;

    await clear();
    await refetch();
    await refetchMe();
  }, [activeOrganization, clear, refetch, refetchMe]);

  const deleteOrganizationMember = useDeleteOrganizationMember(users, checked, handleDeleteOrganizationMemberSuccess);

  const goToDetail = useCallback(
    (id: string) => {
      const route = setRouteParams<MemberParams>(routes.member, { id });
      history.push(route);
    },
    [history]
  );

  const goToEdit = useCallback(
    (id: string) => {
      const route = setRouteParams<MemberParams>(routes.memberEdit, { id });
      history.push(route);
    },
    [history]
  );

  const goToAdd = useCallback(() => history.push(routes.membersAddSelectSpace), [history]);

  const goToSpaces = useCallback(() => history.push(routes.spaces), [history]);

  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value), []);

  return {
    pagination,
    sorting,
    data: { users, totalCount, hasSpaces, activeOrganizationId: activeOrganization?.id || "" },
    state: { loading, called, checked, query },
    handlers: {
      check,
      checkAll,
      goToDetail,
      goToEdit,
      goToAdd,
      goToSpaces,
      handleChangeSearch,
    },
    deleteOrganizationMember,
  };
};
