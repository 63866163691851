import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import { useUpdateEffect } from "react-use";

export function offsetToCursor(offset: number): string {
  // Converts the offset to a relay-style cursor, which can be used in `after` argument.
  // Items are zero-indexed, so on page 1 with a per-page of 10, we see items 0..9.
  // On page 2 we need `after` with index 9 - that's why we subtract 1 from the actual offset.
  return btoa(`arrayconnection:${offset - 1}`);
}

export const usePagination = (pageSize: number = 10) => {
  const history = useHistory();

  const query = useMemo(() => parse(history.location.search), [history.location.search]);

  const initial = useMemo(() => {
    return !query.page || Array.isArray(query.page) ? 1 : parseInt(query.page) || 1;
  }, [query.page]);

  const [current, setCurrent] = useState(initial);

  const [totalCount, setTotalCount] = useState(0);

  const pageCount = useMemo(() => Math.ceil(totalCount / pageSize), [pageSize, totalCount]);

  const skip = useMemo(() => current * pageSize - pageSize, [current, pageSize]);

  const setPage = useCallback(
    (page: number) => {
      if (page > pageCount) return;

      setCurrent(page);
    },
    [pageCount]
  );

  const reset = useCallback(() => setCurrent(1), []);

  useUpdateEffect(() => {
    if (totalCount > 0 && skip >= totalCount) reset();
  }, [skip, totalCount]);

  useUpdateEffect(() => {
    const newQuery = !query ? { page: current } : { ...query, page: current };
    history.replace({ search: `?${stringify(newQuery)}` });
  }, [current]);

  return { current, pageCount, skip, after: offsetToCursor(skip), setTotalCount, setPage, reset };
};
