import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { spaceColors } from "components";
import { useMe, useMedia, useNotification } from "hooks";
import { MediaTypes, useSpaceCreateMutation } from "api";
import { tk, useTranslation } from "translations";
import { routes } from "routes";

const inputs = { name: "name", officialName: "officialName", color: "color" };

type Value = typeof inputs;

const defaultValue = { [inputs.name]: "", [inputs.officialName]: "", [inputs.color]: spaceColors[0] } as Value;

export const useSpacesCreate = () => {
  const { t } = useTranslation();

  useTitle(t(tk.common.createSpace) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const notification = useNotification();

  const { activeOrganization } = useMe();
  const [create, { loading: loadingCreate }] = useSpaceCreateMutation();

  const [value, setValue] = useState<Value>(defaultValue);

  const media = {
    logo: useMedia(MediaTypes.SpaceLogo),
    header: useMedia(MediaTypes.SpaceHeader),
  };

  const [color, setColor] = useState(defaultValue.color);

  const [mediaError, setMediaError] = useState({ logo: false });

  useEffect(() => setMediaError({ logo: false }), [media.logo.preview]);

  const loading = useMemo(() => media.logo.loading || media.header.loading || loadingCreate, [
    loadingCreate,
    media.header.loading,
    media.logo.loading,
  ]);

  const handleChange = useCallback((_value: any) => {
    setValue(_value);
    setMediaError({ logo: false });
  }, []);

  const handleChangeColor = useCallback((_value: string) => setColor(_value), []);

  const handleSubmit = useCallback(async () => {
    if (loading || !activeOrganization?.id) return;

    if (!media.logo.preview) {
      setMediaError({ logo: true });
      return;
    }

    try {
      const organization = activeOrganization.id;
      const { name, officialName, color } = value;
      const logo = (await media.logo.upload()) || null;
      const header = (await media.header.upload()) || null;

      await create({ variables: { input: { organization, name, officialName, color, logo, header } } });

      await notification.show(t(tk.spacesCreate.notifications.success));

      history.replace(routes.spaces);
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [activeOrganization, create, history, loading, media.header, media.logo, notification, t, value]);

  const handleCancel = useCallback(() => history.push(routes.spaces), [history]);

  return {
    inputs,
    media,
    state: { loading, value, color, mediaError },
    handlers: { handleChange, handleChangeColor, handleSubmit, handleCancel },
  };
};
