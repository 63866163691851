import React, { ChangeEvent, useCallback } from "react";
import { Box, RadioButtonGroup } from "grommet";
import { Checkmark } from "grommet-icons";

export const spaceColors = [
  "#0a9187",
  "#2b729c",
  "#fb8c00",
  "#ad2b2b",
  "#606e79",
  "#d62246",
  "#ef476f",
  "#f2542d",
  "#039137",
  "#2179c4",
  "#6761a8",
  "#e3be08",
  // special colors
  "#c8963e",
  "#a6a2a2",
  "#be7c4d",
];

interface Props {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => any;
}

export const SpaceColorSelect = ({ name, value, disabled = false, onChange }: Props) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [onChange]);

  return (
    <RadioButtonGroup
      name={name}
      value={value}
      options={spaceColors}
      direction="row"
      gap="xsmall"
      margin={{ top: "xsmall" }}
      disabled={disabled}
      onChange={handleChange}
    >
      {(option: string, { checked }: { checked: boolean; hover: boolean }) => {
        return (
          <Box align={"center"} justify={"center"} width={"24px"} height={"24px"} background={option} round={"full"}>
            {checked ? <Checkmark size={"small"} color={"white"} /> : null}
          </Box>
        );
      }}
    </RadioButtonGroup>
  );
};
