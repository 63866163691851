import React, { useMemo } from "react";
import { Box, Table, TableBody, Text } from "grommet";
import { Spinner, GroupUserRow, GroupUserAdd, GroupUserAddByGroup, Tooltip } from "components";
import { useTooltip } from "hooks";
import { Roles } from "api";
import { tk, useTranslation } from "translations";
import { boxAnimation } from "styles";
import { CircleQuestion } from "grommet-icons";

export type GroupUser = {
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  isActive: boolean;
  role: Roles;
};

type Data = {
  id: string;
  name: string;
  private: boolean;
  userCount: number;
  users: GroupUser[];
};

interface Props {
  data?: Data;
  spaceId?: string;
  groups?: { id: string; name: string }[];
  loading?: boolean;
  onAdd: (id: string) => any;
  onRemove: (id: string) => any;
  onAddFromGroup: (id: string) => any;
}

export const GroupDetail = ({
  spaceId,
  data,
  groups = [],
  loading = false,
  onAdd,
  onRemove,
  onAddFromGroup,
}: Props) => {
  const { t } = useTranslation();

  const tooltip = useTooltip();

  const noGroupSelected = useMemo(() => !data, [data]);
  const noMembers = useMemo(() => !!data && !data.userCount, [data]);
  const userIds = useMemo(() => (!data?.users ? [] : data.users.map(({ id }) => id)), [data]);
  const otherGroups = useMemo(() => groups.filter(({ id }) => id !== data?.id), [data, groups]);

  return (
    <Box flex={true} elevation={"small"} round={"xsmall"} pad={"medium"}>
      {!loading && !noGroupSelected && (
        <Box align={"start"} justify={"start"} margin={{ bottom: "small" }} animation={boxAnimation}>
          <Box direction={"row"} align={"center"} justify={"between"} fill={"horizontal"}>
            <Text>
              {t(tk.groups.detail.group)} <strong>{data?.name}</strong>
            </Text>
            {!!data?.private && (
              <Box direction={"row"} align={"center"} {...tooltip.targetProps}>
                <Text size={"small"} margin={{ right: "xxsmall" }}>
                  {t(tk.groups.privateGroup)}
                </Text>
                <CircleQuestion size={"small"} />
                <Tooltip
                  heading={t(tk.groups.privateGroup)}
                  value={t(tk.groups.privateGroupInfo)}
                  visible={tooltip.visible}
                  target={tooltip.target}
                  maxWidth={"250px"}
                  align={{ right: "left" }}
                />
              </Box>
            )}
          </Box>

          <GroupUserAdd spaceId={spaceId} onAdd={onAdd} userIds={userIds} />

          <GroupUserAddByGroup groups={otherGroups} onAdd={onAddFromGroup} />

          {!!data && (data?.users.length || 0) > 0 && (
            <Box width={"100%"} margin={{ top: "small" }}>
              <Text size={"small"} weight={"bold"}>
                {t(tk.groups.detail.members)}
              </Text>

              <Table margin={{ top: "xsmall" }}>
                <TableBody>
                  {data.users.map((user) => (
                    <GroupUserRow key={user.id} user={user} onRemove={onRemove} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      )}

      <Box align={"center"} justify={"center"} flex={true} animation={boxAnimation}>
        {loading && <Spinner />}

        {!loading && noGroupSelected && <Text size={"small"}>{t(tk.groups.detail.noGroup)}</Text>}
        {!loading && noMembers && <Text size={"small"}>{t(tk.groups.detail.noMembers)}</Text>}
      </Box>
    </Box>
  );
};
