import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDebounce, useTitle, useUpdateEffect } from "react-use";
import { parse, stringify } from "query-string";
import {
  useActiveOrganizationChange,
  useDeleteSpaceMember,
  useMembersAddStore,
  useNotification,
  usePagination,
  useSorting,
  useTableCheckboxes,
} from "hooks";
import {
  mapConnection,
  Ordering,
  SpaceFragment,
  SpaceMembershipOrder,
  useSpaceQuery,
  useSpaceSendInviteMutation,
} from "api";
import { tk, useTranslation } from "translations";
import { MemberParams, routes, setRouteParams, SpaceParams } from "routes";

const PAGE_SIZE = 10;

export type ActivatedOption = { value: "all" | false | true; label: string };

export const useSpace = (id: string) => {
  const { t } = useTranslation();

  useTitle(t(tk.common.spaceDetail) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const notification = useNotification();

  // On organization change, go to spaces
  useActiveOrganizationChange(() => history.replace(routes.spaces));

  const store = useMembersAddStore();

  const pagination = usePagination(PAGE_SIZE);

  const sorting = useSorting<SpaceMembershipOrder>(
    { asc: { user_LastName: Ordering.Asc }, desc: { user_LastName: Ordering.Desc } },
    "asc"
  );

  /** Search */
  const [query, setQuery] = useState("");
  const isQueryChangedRef = useRef(false);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
    isQueryChangedRef.current = true;
  }, []);

  useDebounce(
    () => {
      if (!isQueryChangedRef.current) return;

      setDebouncedQuery(query);
      pagination.setPage(1);
    },
    500,
    [query]
  );

  /** Filter */
  const activatedOptions = useMemo<ActivatedOption[]>(
    () => [
      { value: "all", label: t(tk.spaceMembers.filter.all) },
      { value: true, label: t(tk.spaceMembers.filter.activated) },
      { value: false, label: t(tk.spaceMembers.filter.deactivated) },
    ],
    [t]
  );

  const initialActivated = useMemo(() => {
    const urlQuery = parse(history.location.search);
    if (urlQuery.activated === "true") return activatedOptions[1];
    if (urlQuery.activated === "false") return activatedOptions[2];
    return activatedOptions[0];
  }, [activatedOptions, history.location.search]);

  const [activated, setActivated] = useState(initialActivated);
  const activatedQuery = useMemo(() => (activated.value === "all" ? null : activated.value), [activated.value]);
  const handleChangeActivated = useCallback(
    (value: ActivatedOption) => {
      setActivated(value);
      pagination.setPage(1);
    },
    [pagination]
  );

  useUpdateEffect(() => {
    const urlQuery = parse(history.location.search);
    history.replace({ search: stringify({ ...(urlQuery ? urlQuery : {}), activated: activated.value }) });
  }, [activated]);

  /** Data fetch */
  const { data, loading, refetch } = useSpaceQuery({
    variables: {
      id,
      after: pagination.after,
      first: PAGE_SIZE,
      order: sorting.current,
      query: debouncedQuery,
      activated: activatedQuery,
    },
    fetchPolicy: "cache-and-network",
  });

  const totalCount = useMemo(() => data?.space?.members.totalCount || 0, [data]);

  useEffect(() => pagination.setTotalCount(totalCount), [pagination, totalCount]);

  const { space, members } = useMemo(() => {
    if (!data || !data.space) return { space: undefined, members: [] };

    const space: SpaceFragment = data.space;

    const members = mapConnection(data.space.members).map(({ contactGroups, ...rest }) => ({
      ...rest,
      contactGroups: mapConnection(contactGroups || undefined)
        .map(({ name }) => name)
        .sort((a, b) => a.localeCompare(b))
        .join(", "),
    }));

    return { space, members };
  }, [data]);

  const { checked, check, checkAll, clear } = useTableCheckboxes(members);

  // On page or sorting change, clear checkboxes
  useUpdateEffect(clear, [pagination.current, sorting.current]);

  const deleteSpaceMember = useDeleteSpaceMember(members, checked, refetch);

  const [memberToEdit, setMemberToEdit] = useState<typeof members[0] | undefined>(undefined);

  const editMembership = useCallback(
    (id: string) => setMemberToEdit(members.find((member) => member.id === id)),
    [members]
  );

  const [invite] = useSpaceSendInviteMutation();

  const sendInvitation = useCallback(
    async (id: string) => {
      try {
        await invite({ variables: { input: { membership: id } } });
        await notification.show(t(tk.common.invitationSent));
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [invite, notification, t]
  );

  const handleEditMembershipCancel = useCallback(() => setMemberToEdit(undefined), []);
  const handleEditMembershipSuccess = useCallback(async () => setMemberToEdit(undefined), []);

  const addMembers = useCallback(async () => {
    if (!space) return;

    const { id, name } = space;
    const origin = setRouteParams<SpaceParams>(routes.space, { id });

    await store.setSpace({ id, name });
    await store.setOrigin(origin);

    history.push(routes.membersAddSelectMembers);
  }, [history, space, store]);

  const goBack = useCallback(() => history.push(routes.spaces), [history]);

  const edit = useCallback(() => {
    const route = setRouteParams<SpaceParams>(routes.spaceEdit, { id });
    history.push(route);
  }, [history, id]);

  const goToMemberDetail = useCallback(
    (id: string) => {
      const route = setRouteParams<MemberParams>(routes.member, { id });
      history.push(route);
    },
    [history]
  );

  return {
    pagination,
    sorting,
    data: { space, members, activatedOptions },
    state: { loading, checked, memberToEdit, query, debouncedQuery, activated },
    handlers: {
      goBack,
      edit,
      goToMemberDetail,
      editMembership,
      sendInvitation,
      check,
      checkAll,
      addMembers,
      handleEditMembershipCancel,
      handleEditMembershipSuccess,
      handleChangeSearch,
      handleChangeActivated,
    },
    deleteSpaceMember,
  };
};
