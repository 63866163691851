import React, { useCallback } from "react";
import { Box, Select, Text } from "grommet";
import { Space } from "controllers";
import { tk, useTranslation } from "translations";

interface Props {
  space?: Space;
  spaces: Space[];
  disabled?: boolean;
  onChange: (space: Space) => any;
}

export const SpaceSelect = ({ space, spaces, disabled = false, onChange }: Props) => {
  const { t } = useTranslation();

  const handleChange = useCallback(({ option }: { option: Space }) => onChange(option), [onChange]);

  return (
    <Box elevation={"small"} round={"xsmall"} pad={"medium"}>
      <Text>{t(tk.groups.space.heading)}</Text>

      <Text size={"small"} weight={"bold"} margin={{ top: "small", left: "small" }}>
        {t(tk.common.selectSpace)}
      </Text>
      <Select
        name={"space"}
        value={space}
        options={spaces}
        valueKey={"id"}
        labelKey={"name"}
        placeholder={t(tk.common.space)}
        emptySearchMessage={t(tk.groups.space.noSpaces)}
        disabled={disabled}
        margin={{ top: "xsmall" }}
        onChange={handleChange}
      />
    </Box>
  );
};
