import React from "react";
import { Blank, IconProps } from "grommet-icons";

export const MaskOnIcon = (props: IconProps & React.SVGProps<SVGSVGElement>) => (
  <Blank {...props}>
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 1.41421 }}
    >
      <path d="M12.932,16.117c-0.554,-0.33 -1.247,-0.33 -1.801,0c-1.233,1.043 -2.879,2.976 -4.625,2.976c-3.907,0 -6.456,-3.247 -6.456,-7.14c0.008,-3.873 3.202,-7.053 7.074,-7.046l9.752,0c3.872,-0.007 7.066,3.173 7.074,7.046c0,3.893 -2.549,7.14 -6.456,7.14c-1.744,0 -3.33,-1.935 -4.562,-2.976Zm-2.698,-4.363c-0.729,-0.916 -1.839,-1.451 -3.01,-1.451c-1.162,0 -2.263,0.526 -2.993,1.429c-0.072,0.072 -0.114,0.171 -0.114,0.275c0,0.103 0.042,0.202 0.114,0.274c0.74,0.897 1.844,1.417 3.007,1.417c1.167,0 2.275,-0.524 3.015,-1.427c0.127,-0.15 0.119,-0.375 -0.019,-0.517Zm3.59,-0.022c0.73,-0.903 1.831,-1.429 2.992,-1.429c1.171,0 2.28,0.535 3.01,1.451c0.137,0.142 0.144,0.367 0.016,0.517c-0.739,0.903 -1.847,1.427 -3.014,1.427c-1.164,0 -2.267,-0.52 -3.007,-1.417c-0.072,-0.072 -0.112,-0.17 -0.112,-0.271c0,-0.105 0.042,-0.203 0.116,-0.276l-0.001,-0.002Z" />
    </svg>
  </Blank>
);
