import React from "react";
import { Box, Main, Text } from "grommet";
import { useGroups } from "controllers";
import { GroupDetail, GroupList, ResponsiveContainer, SpaceSelect } from "components";
import { tk, useTranslation } from "translations";

export const Groups = () => {
  const { t } = useTranslation();

  const { data, state, handlers } = useGroups();

  if (!data.spaces) return null;

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>{t(tk.common.groups)}</Text>

      <ResponsiveContainer width={"xlarge"} align={"center"} margin={{ top: "medium" }}>
        <Box direction={"row"} gap={"medium"} fill={true} wrap={true}>
          {/** Left */}
          <Box flex={true} width={{ min: "350px" }} margin={{ vertical: "small" }}>
            {/** Space */}
            <SpaceSelect
              spaces={data.spaces}
              space={state.space}
              disabled={state.loading}
              onChange={handlers.handleChangeSpace}
            />

            {/** Groups */}
            <GroupList
              space={state.space}
              groups={data.groups}
              group={state.group}
              loadingGroups={state.loadingGroups}
              onGroupAdd={handlers.addGroup}
              onGroupSelect={handlers.selectGroup}
              onGroupRename={handlers.renameGroup}
              onGroupTogglePrivate={handlers.togglePrivateGroup}
              onGroupDelete={handlers.deleteGroup}
            />
          </Box>

          {/** Right */}
          <Box flex={true} width={{ min: "350px" }} margin={{ vertical: "small" }}>
            {/** Detail */}
            <GroupDetail
              data={data.groupDetail}
              spaceId={state.space?.id}
              groups={data.groups}
              loading={state.loadingGroupDetail}
              onAdd={handlers.addUser}
              onRemove={handlers.removeUser}
              onAddFromGroup={handlers.addUsersFromGroup}
            />
          </Box>
        </Box>
      </ResponsiveContainer>
    </Main>
  );
};
