import { Row } from "components/MembersAddTable/useTable";
import { Roles, SimpleUser } from "api";

export const mapRowsToSimpleUsers = (rows: Row[]): SimpleUser[] =>
  rows.map((member) => ({
    firstName: member.firstName.trim(),
    lastName: member.lastName.trim(),
    email: member.email.replace(/\s/g, ""),
    phoneNumber: member.phoneNumber.replace(/\s/g, ""),
    notificationNumber: member.notificationNumber.replace(/\s/g, ""),
    position: member.position.trim(),
    role: member.role === "regular" ? Roles.Regular : Roles.Manager,
    contactGroups: member.contactGroups.split(",").map((group) => group.trim()),
  }));
