import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle, useUpdateEffect } from "react-use";
import { Row } from "components/MembersAddTable/useTable";
import { mapRowsToSimpleUsers } from "components/MembersAddTable/helpers";
import { useMe, useMembersAddStore, useNotification } from "hooks";
import { isOriginalDataEmpty, parseRawData, setIds, validateData } from "helpers";
import { tk, useTranslation } from "translations";
import { routes } from "routes";
import { mapConnection, useSpaceCheckUsersMutation, useSpaceGroupsQuery } from "api";
import { DuplicitySuggestion } from "components";

export const useMembersAddSelectMembers = () => {
  const { t } = useTranslation();

  useTitle(t(tk.common.addMembersToSpace) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const { show } = useNotification();

  const store = useMembersAddStore();
  const { activeOrganization } = useMe();

  const { data: spaceGroupsData } = useSpaceGroupsQuery({
    variables: { id: store.space?.id || "" },
    skip: !store.space?.id,
  });

  const spaceGroups = useMemo(() => mapConnection(spaceGroupsData?.space?.contactGroups).map(({ name }) => name), [
    spaceGroupsData,
  ]);

  const spaceName = useMemo(() => store.space?.name, [store]);
  const members = useMemo(() => store.members, [store]);

  const [rows, setRows] = useState<Row[]>([]);
  const [importedRows, setImportedRows] = useState<Row[]>([]);

  /** Holding data on import, if dialog action is required */
  const [rowsForImport, setRowsForImport] = useState<Row[]>([]);
  const csvImportDialogOpen = useMemo(() => rowsForImport.length > 0, [rowsForImport]);
  const csvImportDialogRowsCount = useMemo(() => rowsForImport.length, [rowsForImport]);

  const valid = useMemo(() => rows.length > 0, [rows.length]);

  /** Duplicity check */
  const [checkUsers, { loading: loadingCheckUsers }] = useSpaceCheckUsersMutation();

  const [duplicityChecked, setDuplicityChecked] = useState(false);
  const [duplicityDialog, setDuplicityDialog] = useState(false);
  const [duplicityData, setDuplicityData] = useState<DuplicitySuggestion[]>([]);

  const handleDuplicityEdit = useCallback(() => {
    setDuplicityChecked(false);
    setDuplicityDialog(false);
    setDuplicityData([]);
  }, []);

  useEffect(() => {
    if (!store.space) history.replace(routes.membersAddSelectSpace);
  }, [history, store.space]);

  /** On activeOrganization change, redirect back to Members */
  useUpdateEffect(() => history.replace(routes.members), [activeOrganization, history]);

  const handleLoadCsv = useCallback(
    async (data: any[]) => {
      const sanitizedData = parseRawData(data);

      if (isOriginalDataEmpty(rows)) {
        await setImportedRows([...setIds(sanitizedData)]);
        await show(t(tk.membersAdd.selectMembers.notifications.csvImported));
        return;
      }

      setRowsForImport(sanitizedData);
    },
    [rows, show, t]
  );

  const handleCsvImportDialogClose = useCallback(() => setRowsForImport([]), []);
  const handleCsvImportDialogSubmit = useCallback(
    async (replace: boolean) => {
      const newRows = replace ? [...setIds(rowsForImport)] : [...rows, ...setIds(rowsForImport, rows.length + 1)];

      await setImportedRows(newRows);
      await setRowsForImport([]);
      await show(t(tk.membersAdd.selectMembers.notifications.csvImported));
    },
    [rows, rowsForImport, show, t]
  );

  const handleTableChange = useCallback((data: Row[]) => setRows(data), []);

  const cancel = useCallback(() => history.replace(!store.origin ? routes.members : store.origin), [
    history,
    store.origin,
  ]);

  const back = useCallback(() => history.replace(!store.origin ? routes.membersAddSelectSpace : store.origin), [
    history,
    store.origin,
  ]);

  const submit = useCallback(async () => {
    const validation = validateData(rows);

    if (validation.valid && !duplicityChecked && store.space?.id) {
      try {
        const users = mapRowsToSimpleUsers(rows);

        const { data } = await checkUsers({ variables: { input: { space: store.space.id, users } } });

        const suggestions = data?.spaceCheckUsers.suggestedChanges || [];

        if (suggestions.length) {
          setDuplicityChecked(true);
          setDuplicityDialog(true);
          setDuplicityData(
            suggestions.map((s) => {
              const original = rows.find(({ email }) => email.replace(/\s/g, "") === s?.originalEmail);
              return {
                row: original?.id || "",
                name: original?.firstName + " " + original?.lastName,
                email: original?.email || "",
                existingEmail: s?.suggestedEmail || "",
              };
            })
          );
          return;
        }
      } catch (e) {}
    }

    if (validation.valid) {
      await store.setMembers(rows);
      history.push(routes.membersAddConfirm);
      return;
    }

    if (validation.error?.type === "required") {
      const missingFields = !validation.error.missingFields
        ? ""
        : ` (${validation.error.missingFields.map((field) => t(tk.membersAdd.table.header[field])).join(", ")})`;
      await show(
        t(tk.membersAdd.selectMembers.notifications.error.required, { row: validation.error.row, missingFields }),
        true
      );
    }

    if (validation.error?.type === "invalidEmail") {
      await show(t(tk.membersAdd.selectMembers.notifications.error.invalidEmail, { row: validation.error.row }), true);
    }

    if (validation.error?.type === "invalidPhone") {
      await show(t(tk.membersAdd.selectMembers.notifications.error.invalidPhone, { row: validation.error.row }), true);
    }

    if (validation.error?.type === "duplicate") {
      await show(
        t(tk.membersAdd.selectMembers.notifications.error.duplicate.message, { row: validation.error.row }),
        true,
        t(tk.membersAdd.selectMembers.notifications.error.duplicate.title)
      );
    }
  }, [checkUsers, duplicityChecked, history, rows, show, store, t]);

  return {
    data: { spaceName, spaceGroups, members },
    state: {
      valid,
      importedRows,
      csvImportDialogOpen,
      csvImportDialogRowsCount,
      loadingCheckUsers,
      duplicityDialog,
      duplicityData,
    },
    handlers: {
      handleLoadCsv,
      handleTableChange,
      cancel,
      back,
      submit,
      handleCsvImportDialogClose,
      handleCsvImportDialogSubmit,
      handleDuplicityEdit,
    },
  };
};
