import React from "react";
import { Box, Button, Layer, Table, TableBody, TableCell, TableRow, Text } from "grommet";
import { tk, useTranslation } from "translations";

export type DuplicitySuggestion = {
  row: string;
  name: string;
  email: string;
  existingEmail: string;
};

interface Props {
  open: boolean;
  data: DuplicitySuggestion[];
  handleClose: () => any;
  handleSubmit: () => any;
}

export const ImportDuplicityDialog = ({ open, data, handleClose, handleSubmit }: Props) => {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <Layer onEsc={handleClose} onClickOutside={handleClose}>
      <Box width={{ max: "550px" }} pad={"medium"} round={"xsmall"} elevation={"medium"}>
        <Box align={"start"}>
          <Text weight={"bold"}>{t(tk.membersAdd.selectMembers.importDuplicityDialog.heading)}</Text>
        </Box>

        <Box margin={{ top: "small" }}>
          <Text size={"small"}>{t(tk.membersAdd.selectMembers.importDuplicityDialog.info1)}</Text>
          <Text size={"small"}>{t(tk.membersAdd.selectMembers.importDuplicityDialog.info2)}</Text>
        </Box>

        <Table margin={{ top: "small", horizontal: "-12px" }}>
          <TableBody>
            <TableRow>
              <TableCell pad={{ vertical: "none", horizontal: "xsmall" }} align={"center"}>
                <Text size={"xsmall"}>
                  <strong>{t(tk.membersAdd.table.header.id)}</strong>
                </Text>
              </TableCell>
              <TableCell pad={{ vertical: "none", horizontal: "xsmall" }}>
                <Text size={"xsmall"}>
                  <strong>{t(tk.membersAdd.table.header.firstName)}</strong>
                </Text>
              </TableCell>
              <TableCell pad={{ vertical: "none", horizontal: "xsmall" }}>
                <Text size={"xsmall"}>
                  <strong>{t(tk.membersAdd.selectMembers.importDuplicityDialog.enteredEmail)}</strong>
                </Text>
              </TableCell>
              <TableCell pad={{ vertical: "none", horizontal: "xsmall" }}>
                <Text size={"xsmall"}>
                  <strong>{t(tk.membersAdd.selectMembers.importDuplicityDialog.existingEmail)}</strong>
                </Text>
              </TableCell>
            </TableRow>

            {data.map(({ row, name, email, existingEmail }, i) => (
              <TableRow key={`r${row}-${i}`}>
                <TableCell pad={{ vertical: "none", horizontal: "xsmall" }} align={"center"}>
                  <Text size={"xsmall"}>{row}</Text>
                </TableCell>
                <TableCell pad={{ vertical: "none", horizontal: "xsmall" }}>
                  <Text size={"xsmall"}>{name}</Text>
                </TableCell>
                <TableCell pad={{ vertical: "none", horizontal: "xsmall" }}>
                  <Text size={"xsmall"}>{email}</Text>
                </TableCell>
                <TableCell pad={{ vertical: "none", horizontal: "xsmall" }}>
                  <Text size={"xsmall"}>{existingEmail}</Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Box margin={{ top: "medium" }}>
          <Text size={"small"}>{t(tk.membersAdd.selectMembers.importDuplicityDialog.confirmation)}</Text>
        </Box>

        <Box direction={"row"} justify={"between"} margin={{ top: "medium" }} gap={"small"}>
          <Button
            label={t(tk.membersAdd.selectMembers.importDuplicityDialog.editData)}
            size={"small"}
            onClick={handleClose}
          />
          <Button label={t(tk.common.continue)} size={"small"} primary={true} onClick={handleSubmit} />
        </Box>
      </Box>
    </Layer>
  );
};
