import { createUploadLink } from "apollo-upload-client";
import { createHttpLink } from "apollo-link-http";
import { getEnv } from "helpers";

const API = getEnv("REACT_APP_API_URL");

const uploadLink = createUploadLink({ uri: API });

export const httpLink = uploadLink;

export const refreshTokenHttpLink = createHttpLink({ uri: API });
