import React from "react";
import { Box, Button, Form, FormField, Image, Main, Text, TextInput } from "grommet";
import { Spinner } from "components";
import { tk, useTranslation } from "translations";
import { useOrganizationEdit } from "controllers";
import { boxAnimation } from "styles";

export const OrganizationEdit = () => {
  const { t } = useTranslation();

  const { inputs, media, data, state, handlers } = useOrganizationEdit();

  if (!data.activeOrganization) return null;

  return (
    <Main align={"center"} pad={"medium"}>
      <Text size={"large"}>{t(tk.organizationEdit.heading)}</Text>

      <Box width="medium" align={"center"} flex={{ shrink: 0 }} margin={{ top: "medium" }} animation={boxAnimation}>
        <Text size={"small"} alignSelf={"start"} margin={{ left: "small" }}>
          <strong>{t(tk.common.logo) + "*"}</strong> {t(tk.organizationEdit.form.logo.info)}
        </Text>

        <Image src={media.preview || data.activeOrganization.logo} height={120} margin={{ top: "small" }} />

        <input {...media.fileInputProps} />

        <Button
          size={"medium"}
          primary={true}
          disabled={state.loading}
          label={t(tk.common.uploadImage)}
          margin={{ top: "small" }}
          onClick={media.openFileBrowser}
        />
      </Box>

      <Box width="medium" flex={{ shrink: 0 }} margin={{ top: "medium" }} animation={boxAnimation}>
        <Form
          value={state.value}
          validate={"blur"}
          messages={{ required: t(tk.form.messages.required), invalid: t(tk.form.messages.invalid) }}
          onChange={handlers.handleChange}
          onSubmit={handlers.handleSubmit}
        >
          <FormField
            name={inputs.name}
            label={t(tk.common.shortName) + "*"}
            info={t(tk.organizationEdit.form.name.info)}
            required={true}
          >
            <TextInput name={inputs.name} maxLength={50} disabled={state.loading} />
          </FormField>

          <FormField
            name={inputs.officialName}
            label={t(tk.common.longName) + "*"}
            info={t(tk.organizationEdit.form.officialName.info)}
            required={true}
          >
            <TextInput name={inputs.officialName} maxLength={100} disabled={state.loading} />
          </FormField>

          <FormField name={inputs.crn} label={t(tk.common.crn) + "*"} required={true}>
            <TextInput name={inputs.crn} maxLength={20} disabled={state.loading} />
          </FormField>

          <FormField name={inputs.tin} label={t(tk.common.tin)}>
            <TextInput name={inputs.tin} maxLength={20} disabled={state.loading} />
          </FormField>

          <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "medium" }}>
            <Button
              label={t(tk.common.cancel)}
              size={"medium"}
              disabled={state.loading}
              onClick={handlers.handleCancel}
            />

            <Button
              type="submit"
              primary={true}
              size={"medium"}
              disabled={state.loading}
              label={t(tk.common.saveChanges)}
            />

            {state.loading && <Spinner />}
          </Box>
        </Form>
      </Box>
    </Main>
  );
};
