import React, { Component, ErrorInfo } from "react";
import * as Sentry from "@sentry/browser";
import { withTranslation, WithTranslation } from "react-i18next";
import { Box, Button, Image, Main, Text } from "grommet";
import { tk } from "translations";
import { routes } from "routes";

import logo from "../assets/images/moyobo.png";
import image from "../assets/images/500.png";

interface Props extends WithTranslation {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  private reload = () => window.location.reload();

  private redirect = () => window.location.replace(routes.dashboard);

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as Record<string, any>);
      Sentry.captureException(error);
    });
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    const { t } = this.props;

    return (
      <Main align={"center"} fill={true} pad={"medium"}>
        <Text size={"large"}>{t(tk.errorBoundary.heading)}</Text>

        <Image src={logo} margin={{ top: "large" }} width={200} />

        <Box margin={{ top: "medium" }} width={{ max: "large" }}>
          <Image src={image} />
        </Box>

        <Text size={"xlarge"} weight={"bold"} margin={{ top: "small" }}>
          {t(tk.errorBoundary.subheading)}
        </Text>

        <Text margin={{ top: "small" }}>{t(tk.errorBoundary.info)}</Text>
        <Text margin={{ top: "xsmall" }}>{t(tk.errorBoundary.info2)}</Text>

        <Button
          label={t(tk.errorBoundary.reload)}
          size={"medium"}
          primary={true}
          margin={{ top: "large" }}
          onClick={this.reload}
        />
        <Button label={t(tk.errorBoundary.back)} size={"medium"} margin={{ top: "medium" }} onClick={this.redirect} />
      </Main>
    );
  }
}

export default withTranslation()(ErrorBoundary);
