import React from "react";
import { Box, Button, Form, FormField, Main, Text, TextInput } from "grommet";
import { tk, useTranslation } from "translations";
import { useSpaceEdit } from "controllers";
import { RouteComponentProps } from "react-router-dom";
import { SpaceParams } from "routes";
import { ResponsiveContainer, SpaceColorSelect, SpaceHeader, Spinner } from "components";
import { Upload } from "grommet-icons";

interface Props extends RouteComponentProps<SpaceParams> {}

export const SpaceEdit = ({ match }: Props) => {
  const { t } = useTranslation();

  const { inputs, media, data, state, handlers } = useSpaceEdit(match.params.id);

  if (!data.space) return null;

  // Intentional SpacesCreate duplication
  // noinspection DuplicatedCode
  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>{t(tk.common.editSpace)}</Text>

      <ResponsiveContainer width={"large"} margin={{ top: "medium" }}>
        <Form
          value={state.value}
          validate={"blur"}
          messages={{ required: t(tk.form.messages.required), invalid: t(tk.form.messages.invalid) }}
          onChange={handlers.handleChange}
          onSubmit={handlers.handleSubmit}
        >
          <Box direction={"row"} justify={"center"} gap={"xlarge"} wrap={true}>
            {/** Inputs */}
            <Box flex={true} align={"start"} width={{ min: "350px" }} margin={{ top: "medium" }}>
              <Text size={"large"} weight={"bold"}>
                {t(tk.common.generalInfo)}
              </Text>

              <Box width={"100%"} margin={{ top: "medium" }}>
                <FormField
                  name={inputs.name}
                  label={t(tk.common.shortName) + "*"}
                  info={t(tk.spaceEdit.generalInfo.form.name.info)}
                  required={true}
                >
                  <TextInput
                    name={inputs.name}
                    placeholder={t(tk.common.shortName)}
                    maxLength={50}
                    disabled={state.loading}
                  />
                </FormField>

                <FormField
                  name={inputs.officialName}
                  label={t(tk.common.longName) + "*"}
                  info={t(tk.spaceEdit.generalInfo.form.officialName.info)}
                  required={true}
                >
                  <TextInput
                    name={inputs.officialName}
                    placeholder={t(tk.common.longName)}
                    maxLength={100}
                    disabled={state.loading}
                  />
                </FormField>
              </Box>

              {/** Images */}
              <Text size={"large"} weight={"bold"} margin={{ top: "medium" }}>
                {t(tk.spaceEdit.media.heading)}
              </Text>

              <Box direction={"row"} justify={"between"} gap={"small"} width={"100%"}>
                <Box align={"start"} margin={{ top: "medium" }}>
                  <Text size={"small"} alignSelf={"start"} margin={{ left: "small" }}>
                    <strong>{t(tk.spaceEdit.media.logo.label)}</strong> {t(tk.spaceEdit.media.logo.info)}
                  </Text>
                  <Button
                    size={"medium"}
                    primary={true}
                    disabled={state.loading}
                    label={t(tk.common.upload)}
                    icon={<Upload size={"small"} />}
                    margin={{ top: "xsmall" }}
                    onClick={media.logo.openFileBrowser}
                  />
                </Box>

                <Box align={"start"} margin={{ top: "medium" }}>
                  <Text size={"small"} alignSelf={"start"} margin={{ left: "small" }}>
                    <strong>{t(tk.spaceEdit.media.image.label)}</strong> {t(tk.spaceEdit.media.image.info)}
                  </Text>
                  <Button
                    size={"medium"}
                    primary={true}
                    disabled={state.loading}
                    label={t(tk.common.upload)}
                    icon={<Upload size={"small"} />}
                    margin={{ top: "xsmall" }}
                    onClick={media.header.openFileBrowser}
                  />
                </Box>
              </Box>

              <input {...media.logo.fileInputProps} />
              <input {...media.header.fileInputProps} />

              {/** Color */}
              <Text size={"small"} weight={"bold"} margin={{ left: "small", top: "medium" }}>
                {t(tk.common.backgroundColor)}
              </Text>
              <SpaceColorSelect
                name={inputs.color}
                value={state.color}
                disabled={state.loading}
                onChange={handlers.handleChangeColor}
              />
            </Box>

            {/** Preview */}
            <Box align={"center"} width={{ min: "350px" }} margin={{ top: "medium" }}>
              <Text size={"large"} weight={"bold"} margin={{ bottom: "small" }}>
                {t(tk.common.preview)}
              </Text>
              <SpaceHeader
                color={state.color}
                background={media.header.preview || data.space.header || undefined}
                logo={media.logo.preview || data.space.logo}
                name={state.value.name || t(tk.common.shortName)}
                officialName={state.value.officialName || t(tk.common.longName)}
                margin={{ top: "medium" }}
              />
            </Box>
          </Box>

          {/** Actions */}
          <Box direction={"row"} justify={"end"} gap={"small"} margin={{ top: "medium" }}>
            <Button
              label={t(tk.common.cancel)}
              size={"medium"}
              disabled={state.loading}
              onClick={handlers.handleCancel}
            />

            <Button
              type="submit"
              primary={true}
              size={"medium"}
              disabled={state.loading}
              label={t(tk.common.editSpace)}
            />

            {state.loading && <Spinner />}
          </Box>
        </Form>
      </ResponsiveContainer>
    </Main>
  );
};
