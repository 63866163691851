import React, { useMemo } from "react";
import { Box, Button, CheckBox, DataTable, DataTableProps, Image, Main, Text, TextInput } from "grommet";
import { ContactInfo, Edit, FormSearch, Trash, UserAdd } from "grommet-icons";
import { useMembers } from "controllers";
import {
  ActionsMenu,
  DataTableHeader,
  DeleteOrganizationMember,
  Pagination,
  ResponsiveContainer,
  Spinner,
  Tooltip,
} from "components";
import { useTooltip } from "hooks";
import { tk, useTranslation } from "translations";
import { boxAnimation } from "styles";

export const Members = () => {
  const { t } = useTranslation();

  const { pagination, sorting, data, state, handlers, deleteOrganizationMember } = useMembers();
  const tooltip = useTooltip();

  type User = typeof data.users[0];

  const columns = useMemo<DataTableProps["columns"]>(() => {
    const checkedAll = state.checked.length === data.users.length;

    return [
      {
        property: "id",
        header: <CheckBox checked={checkedAll} onChange={handlers.checkAll} />,
        sortable: false,
        render: ({ id }: User) => (
          <CheckBox key={id} checked={state.checked.indexOf(id) !== -1} onChange={(e) => handlers.check(e, id)} />
        ),
      },
      {
        property: "profileImage",
        header: "",
        render: ({ profileImage }: User) => (
          <Box justify={"center"}>
            <Image src={profileImage} height={40} />
          </Box>
        ),
      },
      {
        property: "name",
        header: (
          <DataTableHeader
            value={t(tk.common.firstName)}
            sortable={true}
            order={sorting.order}
            onClick={sorting.toggle}
          />
        ),
        render: ({ id, firstName, lastName, email, isActive }: User) => (
          <Box width={{ min: "200px" }} onClick={() => handlers.goToDetail(id)}>
            <Text size={"small"} color={textColor(isActive)}>
              {lastName} {firstName}
            </Text>
            <Text size={"xsmall"} color={textColor(isActive)}>
              {email}
            </Text>
          </Box>
        ),
      },
      {
        property: "spaces",
        header: <DataTableHeader value={t(tk.common.spaces)} />,
        align: "center",
        render: ({ spaces, isActive }: User) => (
          <Box width={{ min: "300px" }}>
            <Text size={"small"} color={textColor(isActive)}>
              {spaces}
            </Text>
          </Box>
        ),
      },
      {
        property: "actions",
        header: <DataTableHeader value={t(tk.common.actions)} />,
        align: "center",
        render: ({ id, isActive }: User) => (
          <ActionsMenu
            id={id}
            items={[
              { label: t(tk.common.showDetail), icon: <ContactInfo size={"small"} />, onClick: handlers.goToDetail },
              { label: t(tk.common.editMember), icon: <Edit size={"small"} />, onClick: handlers.goToEdit },
              {
                label: t(tk.common.deleteMember),
                icon: <Trash size={"small"} />,
                onClick: deleteOrganizationMember.deleteMember,
              },
            ]}
            iconColor={textColor(isActive)}
          />
        ),
      },
    ];
  }, [
    data.users.length,
    deleteOrganizationMember.deleteMember,
    handlers,
    sorting.order,
    sorting.toggle,
    state.checked,
    t,
  ]);

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>{t(tk.members.heading)}</Text>

      <ResponsiveContainer width={"medium"} margin={{ top: "medium" }}>
        {/** Header - search + buttons */}
        <Box align={"start"}>
          <Text weight={"bold"}>
            {t(tk.common.membersCount)}: {data.totalCount}
          </Text>

          <Box direction={"row"} alignSelf={"stretch"} justify={"between"} margin={{ top: "small" }} wrap={true}>
            <Box width={{ max: "medium" }} flex={"grow"}>
              <TextInput
                type={"search"}
                value={state.query}
                placeholder={t(tk.members.search.placeholder)}
                icon={state.loading ? <Spinner /> : <FormSearch color={data.hasSpaces ? "dark-3" : "light-3"} />}
                disabled={!data.hasSpaces && !state.loading}
                onChange={handlers.handleChangeSearch}
              />
            </Box>

            <Box direction={"row"} gap={"small"} wrap={true}>
              <Tooltip
                value={t(tk.members.deleteTooltip)}
                visible={!state.checked.length && tooltip.visible}
                target={tooltip.target}
                align={{ bottom: "top" }}
              />
              <Button
                label={t(tk.members.delete)}
                size={"small"}
                icon={<Trash size={"small"} />}
                disabled={!state.checked.length}
                onClick={deleteOrganizationMember.deleteMembers}
                {...tooltip.targetProps}
              />
              <Button
                label={t(tk.common.addMembers)}
                size={"small"}
                icon={<UserAdd size={"small"} />}
                primary={true}
                disabled={!data.hasSpaces && !state.loading}
                onClick={handlers.goToAdd}
              />
            </Box>
          </Box>
        </Box>

        {/** Table */}
        {state.loading && !state.called ? null : (
          <Box
            margin={{ top: "medium" }}
            elevation={"small"}
            round={"xsmall"}
            animation={boxAnimation}
            overflow={"auto"}
          >
            <DataTable
              columns={columns}
              data={data.users}
              pad={{ vertical: "small", horizontal: "small" }}
              border={{ body: { side: "bottom", color: "light-4" }, header: { side: "bottom", color: "light-4" } }}
            />

            {/** No results */}
            {!data.users.length && (
              <Box align={"center"} justify={"center"} height={"small"}>
                {!data.hasSpaces && (
                  <>
                    <Text size={"small"}>{t(tk.members.table.empty.noSpaces)}</Text>
                    <Button
                      label={t(tk.members.table.empty.goToSpaces)}
                      size={"medium"}
                      primary={true}
                      margin={{ top: "small" }}
                      onClick={handlers.goToSpaces}
                    />
                  </>
                )}

                {data.hasSpaces && !!state.query && <Text size={"small"}>{t(tk.members.table.empty.search)}</Text>}

                {data.hasSpaces && !state.query && (
                  <>
                    <Text size={"small"}>{t(tk.members.table.empty.noUsers)}</Text>
                    <Button
                      label={t(tk.common.addMembers)}
                      icon={<UserAdd size={"small"} />}
                      size={"medium"}
                      primary={true}
                      margin={{ top: "small" }}
                      onClick={handlers.goToAdd}
                    />
                  </>
                )}
              </Box>
            )}

            {/** Pagination */}
            <Box align={"end"} margin={pagination.pageCount > 1 ? "small" : undefined}>
              <Pagination
                pageCount={pagination.pageCount}
                current={pagination.current}
                onPageClick={pagination.setPage}
              />
            </Box>
          </Box>
        )}
      </ResponsiveContainer>

      <DeleteOrganizationMember
        users={deleteOrganizationMember.membersToDelete}
        organizationId={data.activeOrganizationId}
        onCancel={deleteOrganizationMember.handleDeleteMemberCancel}
        onSuccess={deleteOrganizationMember.handleDeleteMemberSuccess}
      />
    </Main>
  );
};

const textColor = (isActive: boolean) => (!isActive ? "status-disabled" : undefined);
