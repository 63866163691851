import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { parse, stringify } from "query-string";
import { useUpdateEffect } from "react-use";

type Options<T> = { asc: T; desc: T };

type Order = keyof Options<any>;

export const useSorting = <T>(options: Options<T>, defaultOption: Order) => {
  const history = useHistory();

  const query = useMemo(() => parse(history.location.search), [history.location.search]);

  const initial = useMemo(() => {
    if (!query.order || Array.isArray(query.order) || !["asc", "desc"].includes(query.order)) {
      return defaultOption;
    }
    return query.order as keyof Options<any>;
  }, [defaultOption, query.order]);

  const [order, setOrder] = useState<keyof Options<any>>(initial);

  const toggle = useCallback(() => {
    setOrder(order === "asc" ? "desc" : "asc");
  }, [order]);

  const reset = useCallback(() => setOrder(defaultOption), [defaultOption]);
  const [current, setCurrent] = useState<T>(options[order]);

  useUpdateEffect(() => {
    const newQuery = !query ? { order: order } : { ...query, order: order };
    history.replace({ search: `?${stringify(newQuery)}` });
    setCurrent(options[order]);
  }, [order]);

  return { current, order, toggle, reset };
};
