import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useMe } from "hooks";
import { mapConnection, OwnerFragment } from "api";
import { routes } from "routes";
import { tk, useTranslation } from "translations";

export const useOrganization = () => {
  const { t } = useTranslation();

  const history = useHistory();

  useTitle(t(tk.common.organizationProfile) + t(tk.common.documentTitleSuffix));

  const { me, activeOrganization } = useMe(false, true);

  const owners = useMemo(() => mapConnection<OwnerFragment>(activeOrganization?.owners), [activeOrganization]);

  const [managerAddOpen, setManagerAddOpen] = useState(false);

  const handleManagerAddOpen = useCallback(() => setManagerAddOpen(true), []);
  const handleManagerAddClose = useCallback(() => setManagerAddOpen(false), []);
  const handleClickEdit = useCallback(() => history.push(routes.organizationEdit), [history]);

  return {
    data: {
      me,
      owners,
      activeOrganization,
    },
    state: {
      managerAddOpen,
    },
    handlers: {
      handleManagerAddOpen,
      handleManagerAddClose,
      handleClickEdit,
    },
  };
};
