import React, { memo, useCallback, useMemo, useState } from "react";
import { Box, Select, Text } from "grommet";
import { Roles } from "api";
import { tk, useTranslation } from "translations";

interface Props {
  role: string;
  onChange: (value: string) => any;
}

export const RoleSelect = memo(({ role, onChange }: Props) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      { value: Roles.Manager, label: t(tk.role.manager) },
      { value: Roles.Regular, label: t(tk.role.regular) },
    ],
    [t]
  );

  type Option = typeof options[0];

  const defaultValue = useMemo(() => options.find(({ value }) => value === role) || options[0], [options, role]);

  const [value, setValue] = useState<Option>(defaultValue);

  const handleChange = useCallback(
    ({ value }: { value: Option }) => {
      setValue(value);
      onChange(value.value);
    },
    [onChange]
  );

  if (!value) return null;

  return (
    <Select
      valueKey={"value"}
      labelKey={"label"}
      value={value}
      valueLabel={<Label value={value.label} />}
      options={options}
      plain={true}
      icon={false}
      onChange={handleChange}
    />
  );
});

const Label = memo((props: { value: string }) => (
  <Box border={true} round={"4px"} width={{ min: "80px", max: "80px" }} pad={"4px"} background={"white"}>
    <Text size={"xsmall"}>{props.value}</Text>
  </Box>
));
