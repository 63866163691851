import React from "react";
import { Box, Image, Main, Text } from "grommet";
import { Edit, UserAdd } from "grommet-icons";
import { useOrganization } from "controllers";
import { ManagerAdd, ManagerRemove, ResponsiveContainer, SimpleTable } from "components";
import { OwnerFragment } from "api";
import { useTranslation, tk } from "translations";

export const Organization = () => {
  const { t } = useTranslation();

  const { data, state, handlers } = useOrganization();

  if (!data.activeOrganization) return null;

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>{t(tk.common.organizationProfile)}</Text>

      <Image src={data.activeOrganization.logo} height={120} margin={{ top: "medium" }} />

      <Text size={"xlarge"} weight={"bold"} margin={{ top: "medium" }}>
        {data.activeOrganization.name}
      </Text>

      <Text margin={{ top: "small" }}>{data.activeOrganization.officialName}</Text>

      <ResponsiveContainer>
        <SimpleTable
          label={t(tk.common.generalInfo)}
          columns={{ label: { primary: true }, value: { right: true } }}
          data={[
            { label: t(tk.common.crn), value: data.activeOrganization.crn },
            { label: t(tk.common.tin), value: data.activeOrganization.tin ?? "" },
          ]}
          buttons={[
            { label: t(tk.organization.edit), icon: <Edit size={"small"} />, onClick: handlers.handleClickEdit },
          ]}
          margin={{ top: "medium" }}
        />

        <SimpleTable
          label={t(tk.organization.managers.heading)}
          columns={{
            name: {
              render: ({ firstName, lastName, profileImage }: OwnerFragment) => (
                <Box direction={"row"} align={"center"}>
                  <Image src={profileImage} height={40} />
                  <Box margin={{ left: "small" }}>
                    <Text size={"small"}>
                      {firstName} {lastName}
                    </Text>
                    <Text size={"xsmall"}>{t(tk.common.admin)}</Text>
                  </Box>
                </Box>
              ),
            },
            email: {},
            remove: {
              render: ({ id, firstName, lastName }: OwnerFragment) =>
                data.me?.userId === id || !data.activeOrganization ? null : (
                  <ManagerRemove
                    organizationId={data.activeOrganization.id}
                    id={id}
                    firstName={firstName}
                    lastName={lastName}
                  />
                ),
            },
          }}
          data={data.owners.map((owner) => ({
            name: owner,
            email: owner.email,
            remove: owner,
          }))}
          buttons={[
            {
              label: t(tk.organization.managers.add.addManager),
              icon: <UserAdd size={"small"} />,
              onClick: handlers.handleManagerAddOpen,
            },
          ]}
          border={false}
          margin={{ top: "large" }}
        />

        <ManagerAdd
          open={state.managerAddOpen}
          ownerIds={data.owners.map(({ id }) => id)}
          organizationId={data.activeOrganization.id}
          onClose={handlers.handleManagerAddClose}
        />
      </ResponsiveContainer>
    </Main>
  );
};
