import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
// Do not change following imports order!
import App from "./App";
import { getEnv } from "helpers";

Sentry.init({
  dsn: getEnv("REACT_APP_SENTRY_DSN"),
  release: getEnv("REACT_APP_SENTRY_RELEASE"),
  enabled: getEnv("NODE_ENV") === "production",
  environment: window.location.hostname,
});

createRoot(document.getElementById("root")!).render(<App />);
