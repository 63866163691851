import React, { useEffect, useState } from "react";
import { Grommet } from "grommet";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "api";
import * as Page from "pages";
import * as Layouts from "layouts";
import ErrorBoundary from "components/ErrorBoundary";
import { theme } from "styles";
import { initTranslations, Language } from "translations";
import { routes } from "routes";

function App() {
  const [render, setRender] = useState(false);

  useEffect(() => {
    initTranslations(Language.Cs).then(() => setRender(true));
  }, []);

  if (!render) return null;

  return (
    <Grommet theme={theme} full={true}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Router>
            <Switch>
              <Redirect from={routes.root} to={routes.login} exact={true} />

              <Route path={routes.login} component={Page.Login} />
              <Route path={routes.dashboard} component={Layouts.Dashboard} />
            </Switch>
          </Router>
        </ApolloProvider>
      </ErrorBoundary>
    </Grommet>
  );
}

export default App;
