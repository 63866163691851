import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Layer, Text } from "grommet";
import { Spinner } from "components";
import { SpaceMembership, useNotification } from "hooks";
import { useSpaceDeleteMembershipsMutation } from "api";
import { tk, useTranslation } from "translations";

interface Props {
  memberships: SpaceMembership[];
  onCancel: () => any;
  onSuccess: () => any;
}

export const DeleteSpaceMember = ({ memberships, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation();

  const { show } = useNotification();

  const [deleteMembership] = useSpaceDeleteMembershipsMutation();

  const [loading, setLoading] = useState(false);

  const isSingleUser = useMemo(() => memberships?.length === 1, [memberships]);
  const plural = useMemo(() => (isSingleUser ? "single" : "multiple"), [isSingleUser]);

  const handleSubmit = useCallback(async () => {
    if (!memberships.length) return;

    setLoading(true);

    try {
      const _memberships = memberships.map(({ id }) => id);
      await deleteMembership({ variables: { input: { memberships: _memberships } } });
      await show(t(tk.deleteSpaceMembership.notifications.success[plural]));
      setLoading(false);
      onSuccess();
    } catch (e) {
      setLoading(false);
      await show(t(tk.deleteSpaceMembership.notifications.error), true);
    }
  }, [deleteMembership, memberships, onSuccess, plural, show, t]);

  if (!memberships.length) return null;

  return (
    <Layer onEsc={onCancel} onClickOutside={onCancel}>
      <Box width={{ max: "550px" }} pad={"medium"} round={"xsmall"} elevation={"medium"}>
        <Box align={"start"}>
          <Text weight={"bold"}>{t(tk.deleteSpaceMembership.heading[plural])}</Text>
        </Box>

        <Box margin={{ top: "medium" }}>
          <Text size={"small"} weight={"bold"}>
            {t(tk.deleteSpaceMembership.warning)}
          </Text>

          <Text size={"small"} margin={{ top: "xsmall" }}>
            {t(tk.deleteSpaceMembership.info)}
          </Text>

          <Text size={"small"}>{t(tk.deleteSpaceMembership.info2)}</Text>

          <Text size={"small"} margin={{ top: "small" }}>
            {t(tk.deleteSpaceMembership.confirmation[plural])}
          </Text>
        </Box>

        {!isSingleUser && (
          <Box height={{ max: "275px" }} overflow={"auto"} margin={{ top: "small" }}>
            {memberships.map(({ id, user }) => (
              <Text key={id} size={"small"} margin={{ top: "xsmall" }}>
                <strong>
                  {user.firstName} {user.lastName}
                </strong>{" "}
                ({user.email})
              </Text>
            ))}
          </Box>
        )}

        <Box direction={"row"} justify={"end"} margin={{ top: "medium" }} gap={"small"}>
          <Button label={t(tk.common.cancel)} size={"small"} disabled={loading} onClick={onCancel} />
          <Button
            label={t(tk.common.removeFromSpace)}
            size={"small"}
            primary={true}
            color={"status-critical"}
            disabled={loading}
            onClick={handleSubmit}
          />
          {loading && <Spinner />}
        </Box>
      </Box>
    </Layer>
  );
};
