import { useCallback, useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import { Roles } from "api";

export type Row = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  notificationNumber: string;
  position: string;
  role: string;
  contactGroups: string;
};

const defaultRow: Row = {
  id: "1",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  notificationNumber: "",
  position: "",
  role: Roles.Manager,
  contactGroups: "",
};

export const useTable = (
  defaultRows: Row[] = [{ ...defaultRow }],
  importedRows: Row[] = [],
  onChange: (rows: Row[]) => any,
  onAddRow: () => any = () => {}
) => {
  const [, setI] = useState(0);
  const [rows, setRows] = useState<Row[]>(defaultRows);

  const [edit, setEdit] = useState<string | undefined>(rows.length === 1 ? "1" : undefined);

  const handleRowChange = useCallback((id: string, property: keyof Row, value: string) => {
    setRows((_rows) => {
      const _id = parseInt(id) - 1;
      _rows[_id][property] = value;

      return _rows;
    });
    setI((prevState) => prevState + 1);
  }, []);

  const editRow = useCallback((id: string) => setEdit(id), []);

  const addRow = useCallback(() => {
    let id = "";

    setRows((_rows) => {
      id = (_rows.length + 1).toString();
      const newRow = { ...defaultRow, id };
      return [..._rows, newRow];
    });

    setEdit(id);
    onAddRow();
  }, [onAddRow]);

  const deleteRow = useCallback((id: string) => {
    setRows((_rows) =>
      _rows
        .filter((row) => row.id !== id)
        .map((row, i) => ({
          ...row,
          id: (i + 1).toString(),
        }))
    );
    setEdit(undefined);
  }, []);

  const saveRow = useCallback(() => setEdit(undefined), []);

  useEffect(() => onChange(rows), [edit, onChange, rows]);

  useUpdateEffect(() => {
    setRows(importedRows);
    setEdit(undefined);
  }, [importedRows]);

  return {
    rows,
    edit,
    handleRowChange,
    editRow,
    addRow,
    deleteRow,
    saveRow,
  };
};
