import React, { ChangeEvent, memo, useCallback, useMemo, useRef, useState } from "react";
import { Box, Drop, FormField, Image, Text, TextInput } from "grommet";
import { AddCircle, Checkmark, FormSearch } from "grommet-icons";
import { useDebounce, useUpdateEffect } from "react-use";
import { GroupsRowAction, GroupUser, Spinner } from "components";
import { mapConnection, resolveError, useSearchSpaceMembersLazyQuery } from "api";
import { tk, useTranslation } from "translations";

const input = "search";

interface Props {
  spaceId?: string;
  userIds: string[];
  onAdd: (id: string) => any;
}

export const GroupUserAdd = ({ spaceId, userIds, onAdd }: Props) => {
  const { t } = useTranslation();

  const dropTargetRef = useRef(null);

  const [query, setQuery] = useState("");
  const [dropVisible, setDropVisible] = useState(false);

  const [fetchUsers, { data, loading }] = useSearchSpaceMembersLazyQuery({ fetchPolicy: "cache-and-network" });

  const users = useMemo(() => {
    if (!query.length) return [];

    return mapConnection(data?.space?.members).map(({ user, role }) => ({ role, ...user }));
  }, [data, query.length]);

  const [debouncedUsers, setDebouncedUsers] = useState(users);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value), []);

  const handleFocus = useCallback(() => {
    if (query.length > 0) setDropVisible(true);
  }, [query.length]);

  const search = useCallback(async () => {
    if (!spaceId || !query.length) return;

    try {
      await fetchUsers({ variables: { id: spaceId, first: 20, query } });

      setDropVisible(true);
    } catch (e: any) {
      resolveError(e);
    }
  }, [fetchUsers, query, spaceId]);

  const hideDrop = useCallback(() => setDropVisible(false), []);

  const handleAdd = useCallback(
    (id: string) => {
      setDropVisible(false);
      onAdd(id);
    },
    [onAdd]
  );

  useDebounce(search, 500, [query]);

  useDebounce(() => setDebouncedUsers(users), 100, [users]);

  useUpdateEffect(() => {
    if (!query.length) setDropVisible(false);
  }, [query.length]);

  return (
    <Box width={"100%"} margin={{ top: "xsmall" }}>
      <FormField
        ref={dropTargetRef}
        name={input}
        label={t(tk.groups.detail.addMember.label)}
        onChange={handleChange}
        onFocus={handleFocus}
      >
        <TextInput
          name={input}
          placeholder={t(tk.groups.detail.addMember.placeholder)}
          icon={!loading ? <FormSearch /> : <Spinner />}
        />
      </FormField>

      {dropVisible && (
        <Drop
          align={{ top: "bottom", left: "left" }}
          target={dropTargetRef.current || undefined}
          onEsc={hideDrop}
          onClickOutside={hideDrop}
        >
          <Box height={{ min: "60px" }} justify={"center"} align={"center"} pad={"small"}>
            {!debouncedUsers.length && !loading && (
              <Text size={"small"}>{t(tk.groups.detail.addMember.noResults)}</Text>
            )}

            {debouncedUsers.map((user) => (
              <ResultRow key={user.id} user={user} added={userIds.includes(user.id)} onAdd={handleAdd} />
            ))}
          </Box>
        </Drop>
      )}
    </Box>
  );
};

interface ResultRowProps {
  user: GroupUser;
  added: boolean;
  onAdd: (id: string) => any;
}

const ResultRow = memo(({ user, added, onAdd }: ResultRowProps) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (added) return;

    onAdd(user.id);
  }, [added, onAdd, user.id]);

  return (
    <Box width={"100%"} direction={"row"} justify={"between"} align={"center"} margin={{ vertical: "2px" }}>
      <Box flex={true} direction={"row"} align={"center"} gap={"xsmall"}>
        <Image src={user.profileImage} height={25} />
        <Text size={"small"} color={textColor(user.isActive)}>
          {user.firstName} {user.lastName}
        </Text>
      </Box>
      <Box flex={true} align={"center"}>
        <Text size={"small"} color={textColor(user.isActive)}>
          {t(tk.role[user.role])}
        </Text>
      </Box>
      <Box flex={true} align={"end"}>
        <GroupsRowAction
          label={t(tk.groups.detail.addMember[added ? "added" : "add"])}
          icon={added ? <Checkmark size={"small"} /> : <AddCircle size={"small"} />}
          disabled={added}
          onClick={handleClick}
        />
      </Box>
    </Box>
  );
});

const textColor = (isActive: boolean) => (!isActive ? "status-disabled" : undefined);
