import React, { useCallback } from "react";
import { Box, Button, Layer, Text } from "grommet";
import { useNotification } from "hooks";
import { tk, useTranslation } from "translations";

export const Notification = () => {
  const { t } = useTranslation();
  const { visible, message, hide } = useNotification();

  const handleClose = useCallback(async () => {
    if (message) await hide(message.id);
  }, [hide, message]);

  if (!visible) return null;

  return (
    <Layer position={"top-right"} modal={false} margin={"medium"} responsive={false} plain={true} onEsc={handleClose}>
      <Box
        direction="row"
        align="center"
        justify="between"
        gap="large"
        round="small"
        elevation="medium"
        width={{ max: "medium" }}
        pad={{ vertical: "small", horizontal: "medium" }}
        background={message.error ? "status-critical" : "light-2"}
      >
        <Box>
          {!!message.title && (
            <Text size={"small"} weight={"bold"} margin={{ bottom: "small" }}>
              {message.title}
            </Text>
          )}

          <Text size={"small"}>{message.message}</Text>
        </Box>

        <Button
          label={
            <Text size={"small"} weight={"bold"}>
              {t(tk.notification.close)}
            </Text>
          }
          plain={true}
          onClick={handleClose}
        />
      </Box>
    </Layer>
  );
};
