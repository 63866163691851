import React, { useCallback, useRef } from "react";
import { Box, Button, Main, Text } from "grommet";
import { Download } from "grommet-icons";
import { useMembersAddSelectMembers } from "controllers";
import { CsvDownloadDialog, CsvImportDialog, CsvReader, ImportDuplicityDialog, ProgressBar, Spinner } from "components";
import { EditableTable } from "components/MembersAddTable";
import { useModal } from "hooks";
import { tk, useTranslation } from "translations";
import { boxAnimation } from "styles";

export const MembersAddSelectMembers = () => {
  const { t } = useTranslation();

  const scrollEndRef = useRef<HTMLDivElement>(null);

  const { data, state, handlers } = useMembersAddSelectMembers();

  const csvDownloadDialog = useModal();

  const handleAddRow = useCallback(() => window.setTimeout(() => scrollEndRef.current?.scrollIntoView()), []);

  if (!data.spaceName) return null;

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>
        {t(tk.common.addMembersToSpace)} <strong>{data.spaceName}</strong>
      </Text>

      <ProgressBar
        steps={[t(tk.membersAdd.steps.space), t(tk.membersAdd.steps.members), t(tk.membersAdd.steps.confirm)]}
        current={1}
        margin={{ top: "medium" }}
      />

      <Box
        width={"xxlarge"}
        margin={{ top: "medium" }}
        pad={{ horizontal: "small" }}
        overflow={"auto"}
        animation={boxAnimation}
      >
        <Text size={"large"}>{t(tk.membersAdd.selectMembers.subheading)}</Text>
        <Text size={"small"} margin={{ top: "small" }}>
          {t(tk.membersAdd.selectMembers.info)}
        </Text>

        {/** CSV actions */}
        <Box flex={false} direction={"row"} justify={"between"} margin={{ top: "medium" }} wrap={true}>
          <Button
            icon={<Download size={"small"} />}
            label={t(tk.membersAdd.selectMembers.downloadCSV)}
            size={"medium"}
            color={"neutral-3"}
            primary={true}
            disabled={state.loadingCheckUsers}
            onClick={csvDownloadDialog.show}
          />
          <CsvDownloadDialog open={csvDownloadDialog.visible} handleClose={csvDownloadDialog.hide} />

          <CsvReader
            label={t(tk.membersAdd.selectMembers.uploadCSV)}
            disabled={state.loadingCheckUsers}
            onLoad={handlers.handleLoadCsv}
          />
          <CsvImportDialog
            open={state.csvImportDialogOpen}
            rowsCount={state.csvImportDialogRowsCount}
            handleClose={handlers.handleCsvImportDialogClose}
            handleSubmit={handlers.handleCsvImportDialogSubmit}
          />
        </Box>

        {/** Table */}
        <EditableTable
          defaultData={data.members}
          importedData={state.importedRows}
          spaceGroups={data.spaceGroups}
          disabled={state.loadingCheckUsers}
          onChange={handlers.handleTableChange}
          onAddRow={handleAddRow}
        />

        <div ref={scrollEndRef} />
      </Box>

      {/** Footer actions */}
      <Box
        flex={{ shrink: 0 }}
        direction={"row"}
        justify={"between"}
        width={"xxlarge"}
        pad={"small"}
        gap={"small"}
        wrap={true}
        margin={{ top: "small" }}
      >
        <Button
          label={t(tk.common.cancel)}
          size={"medium"}
          disabled={state.loadingCheckUsers}
          onClick={handlers.cancel}
        />
        <Box direction={"row"} gap={"small"} wrap={true}>
          <Button
            label={t(tk.common.back)}
            size={"medium"}
            disabled={state.loadingCheckUsers}
            onClick={handlers.back}
          />
          <Button
            label={t(tk.common.continue)}
            size={"medium"}
            primary={true}
            disabled={!state.valid || state.loadingCheckUsers}
            onClick={handlers.submit}
          />
          {state.loadingCheckUsers && <Spinner />}
        </Box>
      </Box>

      <ImportDuplicityDialog
        open={state.duplicityDialog}
        data={state.duplicityData}
        handleClose={handlers.handleDuplicityEdit}
        handleSubmit={handlers.submit}
      />
    </Main>
  );
};
