import React, { memo, useCallback, useMemo, useState } from "react";
import { Box, Collapsible, Image, Text } from "grommet";
import { Down, Up } from "grommet-icons";
import { sortByName } from "api";
import { useMe } from "hooks";

export const OrganizationSwitch = () => {
  const { organizations, activeOrganization, setActiveOrganization } = useMe();

  const selectable = useMemo(() => {
    return sortByName(organizations).filter(({ id }) => id !== activeOrganization?.id);
  }, [activeOrganization, organizations]);

  const hasOptions = useMemo(() => !!selectable.length, [selectable.length]);

  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => setOpen((open) => !open), []);

  const handleClick = useCallback(
    async (id: string) => {
      setOpen(false);
      await setActiveOrganization(id);
    },
    [setActiveOrganization]
  );

  if (!activeOrganization) return null;

  return (
    <Box pad={"medium"}>
      <Box direction={"row"} align={"center"} onClick={hasOptions ? toggle : undefined}>
        <Image src={activeOrganization.logo} height={60} />
        <Box fill={true} justify={"center"}>
          <Text margin={{ left: "small" }}>{activeOrganization.name}</Text>
        </Box>
        {!hasOptions ? null : open ? <Up /> : <Down />}
      </Box>

      <Collapsible open={open}>
        <Box>
          {selectable.map(({ id, name, logo }) => (
            <Selectable key={id} id={id} name={name} logo={logo} onClick={handleClick} />
          ))}
        </Box>
      </Collapsible>
    </Box>
  );
};

interface SelectableProps {
  id: string;
  name: string;
  logo: string;
  onClick: (id: string) => any;
}

const Selectable = memo(({ id, name, logo, onClick }: SelectableProps) => {
  const handleClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <Box direction={"row"} align={"center"} margin={{ top: "small" }} onClick={handleClick}>
      <Image src={logo} height={60} />
      <Text margin={{ left: "small" }}>{name}</Text>
    </Box>
  );
});
