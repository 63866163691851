import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Form, FormField, Layer, RadioButtonGroup, Text, TextInput } from "grommet";
import { Spinner } from "components";
import { useNotification } from "hooks";
import { Roles, useOrganizationChangeMembershipMutation } from "api";
import { tk, useTranslation } from "translations";

const inputs = { role: "role", position: "position" };

type Value = typeof inputs;

const defaultValue = { [inputs.role]: Roles.Regular, [inputs.position]: "" } as Value;

const MAX_POSITION_LENGTH = 50;

interface Props {
  space?: { id: string; name: string };
  membership?: {
    id: string;
    role: Roles;
    position?: string | null;
    user: { email: string; firstName: string; lastName: string };
  };
  onCancel: () => any;
  onSuccess: () => any;
}

export const EditMembership = ({ space, membership, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation();

  const notification = useNotification();

  const [value, setValue] = useState<Value>(defaultValue);

  const [change, { loading }] = useOrganizationChangeMembershipMutation();

  const handleChange = useCallback((_value: any) => setValue(_value), []);

  const handleSubmit = useCallback(async () => {
    if (!membership) return;

    if (value.role === membership.role && value.position === (membership.position || "")) {
      await notification.show(t(tk.editMembership.notifications.same));
      onCancel();
      return;
    }

    if (value.position.length > MAX_POSITION_LENGTH) {
      value.position = value.position.substring(0, MAX_POSITION_LENGTH);
    }

    try {
      await change({
        variables: {
          input: {
            membership: membership.id,
            position: value.position || null,
            role: value.role as Roles,
          },
        },
      });

      await notification.show(t(tk.editMembership.notifications.success));
      onSuccess();
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [change, membership, notification, onCancel, onSuccess, t, value]);

  useEffect(() => {
    if (!membership) return;

    setValue({ role: membership.role, position: membership.position || "" });
  }, [membership]);

  if (!membership || !space) return null;

  const { firstName, lastName } = membership.user;
  const { name } = space;

  return (
    <Layer onEsc={onCancel} onClickOutside={onCancel}>
      <Box width={{ min: "350px", max: "550px" }} pad={"medium"} round={"xsmall"} elevation={"medium"}>
        <Box align={"start"}>
          <Text weight={"bold"}>{t(tk.editMembership.heading, { firstName, lastName, name })}</Text>
        </Box>

        <Box margin={{ top: "medium" }}>
          <Form value={value} onChange={handleChange} onSubmit={handleSubmit}>
            <Text size={"small"} weight={"bold"} margin={{ left: "small" }}>
              {t(tk.common.role)}
            </Text>

            <RadioButtonGroup
              name={inputs.role}
              options={[
                {
                  id: Roles.Regular,
                  value: Roles.Regular,
                  label: <Text size={"small"}>{t(tk.role.regular)}</Text>,
                },
                {
                  id: Roles.Manager,
                  value: Roles.Manager,
                  label: <Text size={"small"}>{t(tk.role.manager)}</Text>,
                },
              ]}
              disabled={loading}
              margin={{ top: "xsmall" }}
            />

            <FormField
              name={inputs.position}
              label={t(tk.editMembership.form.position.label)}
              margin={{ top: "small" }}
            >
              <TextInput
                name={inputs.position}
                placeholder={t(tk.editMembership.form.position.placeholder)}
                disabled={loading}
                maxLength={MAX_POSITION_LENGTH}
              />
            </FormField>
          </Form>
        </Box>

        <Box direction={"row"} justify={"end"} margin={{ top: "medium" }} gap={"small"}>
          <Button label={t(tk.common.cancel)} size={"small"} disabled={loading} onClick={onCancel} />
          <Button label={t(tk.common.edit)} size={"small"} primary={true} disabled={loading} onClick={handleSubmit} />
          {loading && <Spinner />}
        </Box>
      </Box>
    </Layer>
  );
};
