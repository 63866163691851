import React, { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import { Box, Text, TextInput } from "grommet";
import { Close, Edit, Save, Trash } from "grommet-icons";
import { Group } from "controllers";
import { GroupsRowAction as Action } from "components";
import { MaskOffIcon } from "Icons/MaskOff";
import { MaskOnIcon } from "Icons/MaskOn";
import { tk, useTranslation } from "translations";
import { boxAnimation } from "styles";

interface Props {
  group: Group;
  active: boolean;
  onSelect: (group: Group) => any;
  onRename: (id: string, name: string) => any;
  onTogglePrivate: (id: string, value: boolean) => any;
  onDelete: (id: string) => any;
}

export const GroupRow = memo(({ group, active, onSelect, onRename, onTogglePrivate, onDelete }: Props) => {
  const { t } = useTranslation();

  const [edit, setEdit] = useState(false);
  const [newName, setNewName] = useState(group.name);

  const handleClickSelect = useCallback(() => onSelect(group), [group, onSelect]);

  const handleClickEdit = useCallback(() => {
    setNewName(group.name);
    setEdit(true);
  }, [group.name]);

  const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => setNewName(e.target.value), []);

  const handleClickSave = useCallback(() => {
    if (!newName) return;

    setEdit(false);
    onRename(group.id, newName);
  }, [group.id, newName, onRename]);

  const handleClickCancel = useCallback(() => setEdit(false), []);

  const handleClickTogglePrivate = useCallback(() => {
    onTogglePrivate(group.id, !group.private);
  }, [group.id, group.private, onTogglePrivate]);

  const handleClickDelete = useCallback(() => onDelete(group.id), [group.id, onDelete]);

  const selectLabel = useMemo(() => t(tk.groups.groups[active ? "active" : "show"]), [active, t]);

  return (
    <Box
      width={"100%"}
      direction={"row"}
      justify={"between"}
      align={"center"}
      gap={"small"}
      wrap={true}
      background={edit ? { color: "light-2" } : undefined}
      hoverIndicator={{ color: "light-1" }}
      focusIndicator={false}
      onClick={() => {}}
      pad={edit ? { vertical: "xxsmall", horizontal: "xsmall" } : { vertical: "xsmall", horizontal: "xxsmall" }}
      animation={boxAnimation}
    >
      {!edit ? (
        <Box align={"start"} flex={"grow"} title={selectLabel} focusIndicator={false} onClick={handleClickSelect}>
          <Text size={"small"} weight={active ? "bold" : "normal"}>
            {group.name}
          </Text>
        </Box>
      ) : (
        <Box align={"start"} width={"200px"}>
          <TextInput
            name={"newGroup"}
            value={newName}
            placeholder={t(tk.groups.groups.placeholder)}
            size={"xsmall"}
            autoFocus={true}
            style={{ padding: "4px 8px", background: "white" }}
            onChange={handleChangeName}
          />
        </Box>
      )}

      {!edit ? (
        <Box direction={"row"} gap={"small"}>
          <Action tooltip={t(tk.groups.groups.rename)} icon={<Edit size={"small"} />} onClick={handleClickEdit} />
          <Action
            title={t(tk.groups.privateGroup)}
            tooltip={t(tk.groups.privateGroupInfo)}
            icon={group.private ? <MaskOnIcon size={"small"} /> : <MaskOffIcon size={"small"} />}
            onClick={handleClickTogglePrivate}
          />
          <Action tooltip={t(tk.common.delete)} icon={<Trash size={"small"} />} onClick={handleClickDelete} />
        </Box>
      ) : (
        <Box direction={"row"} gap={"small"}>
          <Action
            tooltip={t(tk.common.save)}
            icon={<Save size={"small"} />}
            disabled={!newName}
            onClick={handleClickSave}
          />
          <Action
            tooltip={t(tk.groups.groups.cancelChanges)}
            icon={<Close size={"small"} />}
            onClick={handleClickCancel}
          />
        </Box>
      )}
    </Box>
  );
});
