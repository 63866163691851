import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useMe, useMembersAddStore } from "hooks";
import {
  mapConnection,
  OrganizationSpaceSelectFragment,
  sortByName,
  SpaceState,
  useOrganizationSpacesSelectQuery,
} from "api";
import { tk, useTranslation } from "translations";
import { routes } from "routes";

export const useMembersAddSelectSpace = () => {
  const { t } = useTranslation();

  useTitle(t(tk.common.addMembersToSpace) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const store = useMembersAddStore();

  const { activeOrganization } = useMe();
  const { data } = useOrganizationSpacesSelectQuery({
    variables: { id: activeOrganization?.id || "" },
    skip: !activeOrganization?.id,
  });

  const spaces = useMemo(() => {
    return sortByName(mapConnection<OrganizationSpaceSelectFragment>(data?.organization?.spaces)).filter(
      ({ state }) => state !== SpaceState.Archived
    );
  }, [data]);

  const [space, setSpace] = useState<string | undefined>(undefined);

  const handleChange = useCallback((id: string) => setSpace(id), []);

  const handleSubmit = useCallback(async () => {
    if (!space) return;

    await store.setSpace(spaces.find(({ id }) => id === space));

    history.push(routes.membersAddSelectMembers);
  }, [history, space, spaces, store]);

  const handleCancel = useCallback(() => history.push(routes.members), [history]);

  useEffect(() => setSpace(undefined), [activeOrganization]);

  return {
    data: { spaces },
    state: { space },
    handlers: { handleChange, handleSubmit, handleCancel },
  };
};
