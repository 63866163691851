import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, ButtonExtendedProps, Drop, DropProps, Keyboard, Text, TextInput } from "grommet";
import { FormClose } from "grommet-icons";
import { useUpdateEffect } from "react-use";

interface Props {
  name: string;
  value: string[];
  suggestions: string[];
  placeholder: string;
  autoFocus?: boolean;
  onChange: (event: { target: { value: string[] } }) => any;
}

export const TagInput = ({ value = [], suggestions, placeholder, autoFocus = false, onChange }: Props) => {
  const [current, setCurrent] = useState("");
  const [focused, setFocused] = useState(false);
  const [dropVisible, setDropVisible] = useState(false);

  const filteredSuggestions = useMemo(() => {
    return suggestions
      .filter((s) => !value.includes(s))
      .filter((suggestion) => suggestion.toLowerCase().indexOf(current.toLowerCase()) >= 0);
  }, [current, suggestions, value]);

  const dropTargetRef = useRef(null);

  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (dropVisible) setDropVisible(false);
      if (focused && !dropVisible && filteredSuggestions.length) setDropVisible(true);
    },
    [dropVisible, focused, filteredSuggestions.length]
  );

  const handleClickOutside = useCallback<Exclude<DropProps["onClickOutside"], undefined>>((e) => {
    if (!(e.target instanceof HTMLElement)) return;
    if (e.target.id !== "tags-input") setDropVisible(false);
  }, []);

  const handleChangeCurrent = useCallback((e: ChangeEvent<HTMLInputElement>) => setCurrent(e.target.value), []);

  const handleAddTag = useCallback(
    (tag: string) => {
      setCurrent("");
      const trimmed = tag.trim();
      onChange({ target: { value: [...value, ...(!value.includes(trimmed) ? [trimmed] : [])] } });
    },
    [onChange, value]
  );

  const handleRemoveTag = useCallback(
    (tag: string) => {
      onChange({ target: { value: [...value.filter((_tag) => _tag !== tag)] } });
    },
    [onChange, value]
  );

  const onEnter = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (current.length) handleAddTag(current);
    },
    [current, handleAddTag]
  );

  const onEsc = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (!dropVisible) return;

      setDropVisible(false);
      e.preventDefault();
      e.stopPropagation();
    },
    [dropVisible]
  );

  useUpdateEffect(() => {
    if (dropVisible && !filteredSuggestions.length) setDropVisible(false);
    if (!dropVisible && filteredSuggestions.length) setDropVisible(true);
  }, [filteredSuggestions.length]);

  useEffect(() => {
    if (autoFocus) setDropVisible(true);
  }, [autoFocus]);

  return (
    <Keyboard onEnter={onEnter} onEsc={onEsc}>
      <Box ref={dropTargetRef} direction="row" align="center" wrap={true}>
        {value.length > 0 && (
          <Box direction="row" wrap={true} margin={{ top: "xsmall", left: "xsmall" }}>
            {value.map((v, i) => (
              <Tag value={v} key={`${v}${i}`} onRemove={handleRemoveTag} />
            ))}
          </Box>
        )}

        <Box flex style={{ minWidth: "120px" }}>
          <TextInput
            id={"tags-input"}
            type="search"
            plain={true}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onChange={handleChangeCurrent}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleClick}
            value={current}
          />
        </Box>

        {dropVisible && (
          <Drop
            align={{ top: "bottom", left: "left" }}
            target={dropTargetRef.current || undefined}
            onClickOutside={handleClickOutside}
          >
            <Box justify={"center"} align={"start"}>
              {filteredSuggestions.map((suggestion) => (
                <Box
                  key={suggestion}
                  width={"100%"}
                  pad={{ horizontal: "small", vertical: "xsmall" }}
                  hoverIndicator={"background"}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleAddTag(suggestion);
                  }}
                >
                  <Text size={"small"}>{suggestion}</Text>
                </Box>
              ))}
            </Box>
          </Drop>
        )}
      </Box>
    </Keyboard>
  );
};

interface TagProps {
  value: string;
  onRemove: (value: string) => any;
}

const Tag = ({ value, onRemove }: TagProps) => {
  const handleClick = useCallback<Exclude<ButtonExtendedProps["onClick"], undefined>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onRemove(value);
    },
    [onRemove, value]
  );

  return (
    <Button primary={true} color={"brand"} margin={{ right: "xsmall", bottom: "xsmall" }} onClick={handleClick}>
      <Box direction={"row"} align={"center"} round={"xsmall"} pad={{ horizontal: "xsmall", vertical: "xxsmall" }}>
        <Text size="xsmall" margin={{ right: "xxsmall" }}>
          {value}
        </Text>
        <FormClose size="small" color="white" />
      </Box>
    </Button>
  );
};
