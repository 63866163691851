// automatically generated by scripts/keys

export const tk = {
  "addMemberToSpace": {
    "form": {
      "contactGroups": {
        "label": "addMemberToSpace.form.contactGroups.label",
        "placeholder": "addMemberToSpace.form.contactGroups.placeholder"
      },
      "position": {
        "placeholder": "addMemberToSpace.form.position.placeholder"
      }
    },
    "notifications": {
      "success": "addMemberToSpace.notifications.success"
    }
  },
  "common": {
    "actions": "common.actions",
    "activate": "common.activate",
    "activationCode": "common.activationCode",
    "addMembers": "common.addMembers",
    "addMembersToSpace": "common.addMembersToSpace",
    "addToSpace": "common.addToSpace",
    "admin": "common.admin",
    "back": "common.back",
    "backgroundColor": "common.backgroundColor",
    "cancel": "common.cancel",
    "continue": "common.continue",
    "createSpace": "common.createSpace",
    "crn": "common.crn",
    "deactivate": "common.deactivate",
    "delete": "common.delete",
    "deleteMember": "common.deleteMember",
    "documentTitleSuffix": "common.documentTitleSuffix",
    "edit": "common.edit",
    "editMember": "common.editMember",
    "editSpace": "common.editSpace",
    "email": "common.email",
    "firstName": "common.firstName",
    "generalInfo": "common.generalInfo",
    "group": "common.group",
    "groups": "common.groups",
    "lastName": "common.lastName",
    "login": "common.login",
    "logo": "common.logo",
    "logout": "common.logout",
    "longName": "common.longName",
    "member": "common.member",
    "memberDetail": "common.memberDetail",
    "members": "common.members",
    "membersCount": "common.membersCount",
    "notificationNumber": "common.notificationNumber",
    "organizationProfile": "common.organizationProfile",
    "phoneNumber": "common.phoneNumber",
    "position": "common.position",
    "preview": "common.preview",
    "remove": "common.remove",
    "removeFromSpace": "common.removeFromSpace",
    "role": "common.role",
    "save": "common.save",
    "saveChanges": "common.saveChanges",
    "selectSpace": "common.selectSpace",
    "shortName": "common.shortName",
    "showDetail": "common.showDetail",
    "space": "common.space",
    "spaceDetail": "common.spaceDetail",
    "spaceName": "common.spaceName",
    "spaces": "common.spaces",
    "state": "common.state",
    "tin": "common.tin",
    "upload": "common.upload",
    "uploadImage": "common.uploadImage",
    "sendInvitation": "common.sendInvitation",
    "invitationSent": "common.invitationSent",
    "aboutMe": "common.aboutMe"
  },
  "deleteSpaceMembership": {
    "confirmation": {
      "multiple": "deleteSpaceMembership.confirmation.multiple",
      "single": "deleteSpaceMembership.confirmation.single"
    },
    "heading": {
      "multiple": "deleteSpaceMembership.heading.multiple",
      "single": "deleteSpaceMembership.heading.single"
    },
    "info": "deleteSpaceMembership.info",
    "info2": "deleteSpaceMembership.info2",
    "notifications": {
      "error": "deleteSpaceMembership.notifications.error",
      "success": {
        "multiple": "deleteSpaceMembership.notifications.success.multiple",
        "single": "deleteSpaceMembership.notifications.success.single"
      }
    },
    "warning": "deleteSpaceMembership.warning"
  },
  "editMembership": {
    "form": {
      "position": {
        "label": "editMembership.form.position.label",
        "placeholder": "editMembership.form.position.placeholder"
      }
    },
    "heading": "editMembership.heading",
    "notifications": {
      "same": "editMembership.notifications.same",
      "success": "editMembership.notifications.success"
    }
  },
  "errorBoundary": {
    "back": "errorBoundary.back",
    "heading": "errorBoundary.heading",
    "info": "errorBoundary.info",
    "info2": "errorBoundary.info2",
    "reload": "errorBoundary.reload",
    "subheading": "errorBoundary.subheading"
  },
  "errors": {
    "general": "errors.general"
  },
  "form": {
    "email": {
      "messages": {
        "invalid": "form.email.messages.invalid"
      }
    },
    "messages": {
      "invalid": "form.messages.invalid",
      "required": "form.messages.required"
    },
    "password": {
      "label": "form.password.label",
      "reset": "form.password.reset",
      "tooltip": "form.password.tooltip"
    },
    "phone": {
      "messages": {
        "invalid": "form.phone.messages.invalid"
      }
    }
  },
  "groups": {
    "detail": {
      "addMember": {
        "add": "groups.detail.addMember.add",
        "added": "groups.detail.addMember.added",
        "byExistingGroup": "groups.detail.addMember.byExistingGroup",
        "label": "groups.detail.addMember.label",
        "noResults": "groups.detail.addMember.noResults",
        "placeholder": "groups.detail.addMember.placeholder",
        "use": "groups.detail.addMember.use"
      },
      "group": "groups.detail.group",
      "members": "groups.detail.members",
      "noGroup": "groups.detail.noGroup",
      "noMembers": "groups.detail.noMembers"
    },
    "groups": {
      "active": "groups.groups.active",
      "add": "groups.groups.add",
      "addTooltip": "groups.groups.addTooltip",
      "cancelChanges": "groups.groups.cancelChanges",
      "heading": "groups.groups.heading",
      "noGroups": "groups.groups.noGroups",
      "noSpace": "groups.groups.noSpace",
      "placeholder": "groups.groups.placeholder",
      "rename": "groups.groups.rename",
      "show": "groups.groups.show"
    },
    "notifications": {
      "addGroup": {
        "success": "groups.notifications.addGroup.success"
      },
      "addMember": {
        "noChange": "groups.notifications.addMember.noChange",
        "success": "groups.notifications.addMember.success"
      },
      "deleteGroup": {
        "hasMembers": {
          "message": "groups.notifications.deleteGroup.hasMembers.message",
          "title": "groups.notifications.deleteGroup.hasMembers.title"
        },
        "success": "groups.notifications.deleteGroup.success"
      },
      "removeMember": {
        "noGroup": {
          "message": "groups.notifications.removeMember.noGroup.message",
          "title": "groups.notifications.removeMember.noGroup.title"
        },
        "success": "groups.notifications.removeMember.success"
      },
      "renameGroup": {
        "notUnique": "groups.notifications.renameGroup.notUnique",
        "success": "groups.notifications.renameGroup.success"
      },
      "privateGroup": {
        "active": "groups.notifications.privateGroup.active",
        "inactive": "groups.notifications.privateGroup.inactive"
      }
    },
    "space": {
      "heading": "groups.space.heading",
      "noSpaces": "groups.space.noSpaces"
    },
    "privateGroup": "groups.privateGroup",
    "privateGroupInfo": "groups.privateGroupInfo"
  },
  "login": {
    "heading": "login.heading",
    "messages": {
      "invalid": "login.messages.invalid",
      "permissions": "login.messages.permissions"
    },
    "subheading": "login.subheading"
  },
  "memberEdit": {
    "form": {
      "email": {
        "tooltip": "memberEdit.form.email.tooltip"
      },
      "logo": {
        "info": "memberEdit.form.logo.info"
      },
      "notificationNumber": {
        "tooltip": "memberEdit.form.notificationNumber.tooltip"
      }
    },
    "success": "memberEdit.success"
  },
  "members": {
    "delete": "members.delete",
    "deleteTooltip": "members.deleteTooltip",
    "dialog": {
      "delete": {
        "confirmation": {
          "multiple": "members.dialog.delete.confirmation.multiple",
          "single": "members.dialog.delete.confirmation.single"
        },
        "heading": {
          "multiple": "members.dialog.delete.heading.multiple",
          "single": "members.dialog.delete.heading.single"
        },
        "info": {
          "multiple": "members.dialog.delete.info.multiple",
          "single": "members.dialog.delete.info.single"
        },
        "notifications": {
          "error": "members.dialog.delete.notifications.error",
          "success": "members.dialog.delete.notifications.success"
        },
        "submit": "members.dialog.delete.submit",
        "warning": "members.dialog.delete.warning"
      }
    },
    "heading": "members.heading",
    "search": {
      "placeholder": "members.search.placeholder"
    },
    "table": {
      "empty": {
        "goToSpaces": "members.table.empty.goToSpaces",
        "noSpaces": "members.table.empty.noSpaces",
        "noUsers": "members.table.empty.noUsers",
        "search": "members.table.empty.search"
      }
    }
  },
  "membersAdd": {
    "confirm": {
      "info": "membersAdd.confirm.info",
      "notifications": {
        "error": "membersAdd.confirm.notifications.error",
        "success": "membersAdd.confirm.notifications.success"
      },
      "subheading": "membersAdd.confirm.subheading",
      "submit": "membersAdd.confirm.submit"
    },
    "selectMembers": {
      "addRow": "membersAdd.selectMembers.addRow",
      "csvImportDialog": {
        "add": "membersAdd.selectMembers.csvImportDialog.add",
        "foundRows": "membersAdd.selectMembers.csvImportDialog.foundRows",
        "heading": "membersAdd.selectMembers.csvImportDialog.heading",
        "info": "membersAdd.selectMembers.csvImportDialog.info",
        "replace": "membersAdd.selectMembers.csvImportDialog.replace"
      },
      "downloadCSV": "membersAdd.selectMembers.downloadCSV",
      "info": "membersAdd.selectMembers.info",
      "notifications": {
        "csvImported": "membersAdd.selectMembers.notifications.csvImported",
        "error": {
          "duplicate": {
            "message": "membersAdd.selectMembers.notifications.error.duplicate.message",
            "title": "membersAdd.selectMembers.notifications.error.duplicate.title"
          },
          "invalidEmail": "membersAdd.selectMembers.notifications.error.invalidEmail",
          "invalidPhone": "membersAdd.selectMembers.notifications.error.invalidPhone",
          "required": "membersAdd.selectMembers.notifications.error.required"
        }
      },
      "subheading": "membersAdd.selectMembers.subheading",
      "uploadCSV": "membersAdd.selectMembers.uploadCSV",
      "csvDownloadDialog": {
        "heading": "membersAdd.selectMembers.csvDownloadDialog.heading",
        "requirementsInfo": "membersAdd.selectMembers.csvDownloadDialog.requirementsInfo",
        "requirement1": "membersAdd.selectMembers.csvDownloadDialog.requirement1",
        "requirement2": "membersAdd.selectMembers.csvDownloadDialog.requirement2",
        "requirement3": "membersAdd.selectMembers.csvDownloadDialog.requirement3",
        "requirement4": "membersAdd.selectMembers.csvDownloadDialog.requirement4",
        "importToExcel": "membersAdd.selectMembers.csvDownloadDialog.importToExcel",
        "importToNumbers": "membersAdd.selectMembers.csvDownloadDialog.importToNumbers",
        "excel1": "membersAdd.selectMembers.csvDownloadDialog.excel1",
        "excel2": "membersAdd.selectMembers.csvDownloadDialog.excel2",
        "excel3": "membersAdd.selectMembers.csvDownloadDialog.excel3",
        "excel4": "membersAdd.selectMembers.csvDownloadDialog.excel4",
        "excel5": "membersAdd.selectMembers.csvDownloadDialog.excel5",
        "numbers1": "membersAdd.selectMembers.csvDownloadDialog.numbers1",
        "numbers2": "membersAdd.selectMembers.csvDownloadDialog.numbers2",
        "numbers3": "membersAdd.selectMembers.csvDownloadDialog.numbers3"
      },
      "importDuplicityDialog": {
        "heading": "membersAdd.selectMembers.importDuplicityDialog.heading",
        "info1": "membersAdd.selectMembers.importDuplicityDialog.info1",
        "info2": "membersAdd.selectMembers.importDuplicityDialog.info2",
        "enteredEmail": "membersAdd.selectMembers.importDuplicityDialog.enteredEmail",
        "existingEmail": "membersAdd.selectMembers.importDuplicityDialog.existingEmail",
        "confirmation": "membersAdd.selectMembers.importDuplicityDialog.confirmation",
        "editData": "membersAdd.selectMembers.importDuplicityDialog.editData"
      }
    },
    "selectSpace": {
      "info": "membersAdd.selectSpace.info",
      "subheading": "membersAdd.selectSpace.subheading"
    },
    "steps": {
      "confirm": "membersAdd.steps.confirm",
      "members": "membersAdd.steps.members",
      "space": "membersAdd.steps.space"
    },
    "table": {
      "header": {
        "actions": "membersAdd.table.header.actions",
        "contactGroups": "membersAdd.table.header.contactGroups",
        "email": "membersAdd.table.header.email",
        "firstName": "membersAdd.table.header.firstName",
        "id": "membersAdd.table.header.id",
        "lastName": "membersAdd.table.header.lastName",
        "notificationNumber": "membersAdd.table.header.notificationNumber",
        "phoneNumber": "membersAdd.table.header.phoneNumber",
        "position": "membersAdd.table.header.position",
        "role": "membersAdd.table.header.role"
      }
    }
  },
  "notification": {
    "close": "notification.close"
  },
  "organization": {
    "edit": "organization.edit",
    "managers": {
      "add": {
        "addManager": "organization.managers.add.addManager",
        "noResults": "organization.managers.add.noResults",
        "search": "organization.managers.add.search",
        "success": "organization.managers.add.success"
      },
      "heading": "organization.managers.heading",
      "remove": {
        "confirm": "organization.managers.remove.confirm",
        "removeManager": "organization.managers.remove.removeManager",
        "success": "organization.managers.remove.success"
      }
    }
  },
  "organizationEdit": {
    "form": {
      "crn": {
        "messages": {
          "invalid": "organizationEdit.form.crn.messages.invalid"
        }
      },
      "logo": {
        "info": "organizationEdit.form.logo.info"
      },
      "name": {
        "info": "organizationEdit.form.name.info"
      },
      "officialName": {
        "info": "organizationEdit.form.officialName.info"
      },
      "tin": {
        "messages": {
          "invalid": "organizationEdit.form.tin.messages.invalid"
        }
      }
    },
    "heading": "organizationEdit.heading",
    "success": "organizationEdit.success"
  },
  "role": {
    "manager": "role.manager",
    "regular": "role.regular"
  },
  "space": {
    "notifications": {
      "stateChangeActive": "space.notifications.stateChangeActive",
      "stateChangeInactive": "space.notifications.stateChangeInactive"
    },
    "stateTooltip": {
      "concept": "space.stateTooltip.concept",
      "active": "space.stateTooltip.active",
      "inactive": "space.stateTooltip.inactive"
    },
    "publish": {
      "text": "space.publish.text",
      "confirmation": "space.publish.confirmation",
      "submit": "space.publish.submit"
    }
  },
  "spaceEdit": {
    "generalInfo": {
      "form": {
        "name": {
          "info": "spaceEdit.generalInfo.form.name.info"
        },
        "officialName": {
          "info": "spaceEdit.generalInfo.form.officialName.info"
        }
      }
    },
    "media": {
      "heading": "spaceEdit.media.heading",
      "image": {
        "info": "spaceEdit.media.image.info",
        "label": "spaceEdit.media.image.label"
      },
      "logo": {
        "info": "spaceEdit.media.logo.info",
        "label": "spaceEdit.media.logo.label"
      }
    },
    "notifications": {
      "success": "spaceEdit.notifications.success"
    }
  },
  "spaceMembers": {
    "actions": {
      "edit": "spaceMembers.actions.edit",
      "remove": "spaceMembers.actions.remove"
    },
    "empty": "spaceMembers.empty",
    "heading": "spaceMembers.heading",
    "remove": "spaceMembers.remove",
    "removeTooltip": "spaceMembers.removeTooltip",
    "filter": {
      "all": "spaceMembers.filter.all",
      "activated": "spaceMembers.filter.activated",
      "deactivated": "spaceMembers.filter.deactivated"
    }
  },
  "spaceState": {
    "active": "spaceState.active",
    "inactive": "spaceState.inactive",
    "concept": "spaceState.concept"
  },
  "spaces": {
    "count": "spaces.count",
    "empty": {
      "noSpaces": "spaces.empty.noSpaces",
      "search": "spaces.empty.search"
    },
    "filter": {
      "state": {
        "default": "spaces.filter.state.default"
      }
    },
    "heading": "spaces.heading",
    "members": {
      "manager": "spaces.members.manager",
      "manager_plural": "spaces.members.manager_plural",
      "regular": "spaces.members.regular",
      "regular_plural": "spaces.members.regular_plural"
    },
    "search": {
      "placeholder": "spaces.search.placeholder"
    }
  },
  "spacesCreate": {
    "generalInfo": {
      "form": {
        "name": {
          "info": "spacesCreate.generalInfo.form.name.info"
        },
        "officialName": {
          "info": "spacesCreate.generalInfo.form.officialName.info"
        }
      }
    },
    "media": {
      "heading": "spacesCreate.media.heading",
      "image": {
        "info": "spacesCreate.media.image.info",
        "label": "spacesCreate.media.image.label"
      },
      "logo": {
        "info": "spacesCreate.media.logo.info",
        "label": "spacesCreate.media.logo.label"
      }
    },
    "notifications": {
      "success": "spacesCreate.notifications.success"
    }
  },
  "userState": {
    "active": "userState.active",
    "inactive": "userState.inactive"
  }
};
