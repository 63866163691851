import React, { ReactNode } from "react";
import { Box, BoxProps, Button, Table, TableBody, TableCell, TableRow, Text } from "grommet";

interface Props {
  label: string;
  columns: { [key: string]: { primary?: boolean; right?: boolean; render?: (value: any) => ReactNode } };
  data: { [key: string]: string | number | object }[];
  buttons?: { label: string; icon?: JSX.Element; primary?: boolean; onClick: () => any }[];
  border?: boolean;
  margin?: BoxProps["margin"];
}

export const SimpleTable = ({ label, columns, data, buttons = [], border = true, margin }: Props) => (
  <Box width={"100%"} margin={margin}>
    <Text size={"small"} alignSelf={"start"}>
      {label}
    </Text>
    <Box
      elevation={"xsmall"}
      round={"xsmall"}
      margin={{ top: "small" }}
      pad={"small"}
      overflow={{ horizontal: "auto" }}
    >
      <Box>
        <Table>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {Object.keys(row).map((property, cellIndex) => {
                  const { primary, right, render } = columns[property];
                  const borderStyle: BoxProps["border"] = border ? { side: "bottom", color: "light-3" } : undefined;
                  const cellProps = { border: borderStyle, flex: true, pad: "small" };

                  if (!!render)
                    return (
                      <TableCell key={cellIndex} {...cellProps}>
                        {render(row[property])}
                      </TableCell>
                    );

                  return (
                    <TableCell key={cellIndex} {...cellProps}>
                      <Text size={"small"} weight={primary ? "bold" : "normal"} textAlign={right ? "end" : "start"}>
                        {typeof row[property] !== "object" ? row[property] : ""}
                      </Text>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {buttons.length > 0 && (
          <Box direction={"row"} justify={"end"} gap={"small"}>
            {buttons.map(({ label, icon, primary = true, onClick }, i) => (
              <Button
                key={i}
                label={label}
                icon={icon}
                primary={primary}
                size={"medium"}
                alignSelf={"end"}
                margin={{ top: "small" }}
                onClick={onClick}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  </Box>
);
