import React, { useCallback, useMemo } from "react";
import { Box, BoxProps, Button as GrommetButton } from "grommet";
import { FormNext, FormPrevious } from "grommet-icons";
import styled from "styled-components";
import { theme } from "styles";

interface Props {
  pageCount: number;
  current: number;
  margin?: BoxProps["margin"];
  onPageClick: (page: number) => any;
}

export const Pagination = ({ pageCount, current, margin, onPageClick }: Props) => {
  const pages = useMemo(() => {
    const _pages = [];

    for (let i = 0; i < pageCount; i++) {
      const pageNumber = i + 1;

      _pages.push({
        page: pageNumber,
        active: current === pageNumber,
        onClick: () => onPageClick(pageNumber),
      });
    }

    return _pages;
  }, [current, onPageClick, pageCount]);

  const handleClickPrevious = useCallback(() => {
    if (current > 1) onPageClick(current - 1);
  }, [current, onPageClick]);

  const handleClickNext = useCallback(() => {
    if (current < pageCount) onPageClick(current + 1);
  }, [current, onPageClick, pageCount]);

  if (pageCount <= 1) return null;

  return (
    <Box direction={"row"} wrap={true} margin={margin}>
      <Button
        size={"small"}
        icon={<FormPrevious size={"18px"} />}
        disabled={current === 1}
        onClick={handleClickPrevious}
      />

      {pages.map(({ page, active, onClick }) => (
        <Button key={page} size={"small"} primary={active} onClick={onClick}>
          {page}
        </Button>
      ))}

      <Button
        size={"small"}
        icon={<FormNext size={"18px"} />}
        disabled={current === pageCount}
        onClick={handleClickNext}
      />
    </Box>
  );
};

const Button = styled(GrommetButton)`
  width: 24px;
  text-align: center;
  margin: 0;
  padding: 0;

  ${(props) => (!!props.icon ? "box-shadow: none" : null)};

  &:hover {
    font-weight: ${(props) => (props.primary ? "normal" : "bold")};
    color: ${(props) => (props.primary ? theme.global?.colors?.["light-1"] : theme.button?.primary?.color)};

    svg {
      stroke: ${theme.button?.primary?.color};
    }
  }

  &:disabled {
    svg {
      stroke: #666666;
    }
  }
`;
