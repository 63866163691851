import React, { ChangeEvent, ComponentProps, useCallback, useMemo, useState } from "react";
import { Box, Button, Form, FormField, Layer, RadioButtonGroup, Select, Text, TextInput } from "grommet";
import { Spinner, TagInput } from "components";
import { useNotification } from "hooks";
import {
  mapConnection,
  Roles,
  sortByName,
  SpaceState,
  useOrganizationSpacesWithContactGroupsQuery,
  useSpaceInviteUsersMutation,
} from "api";
import { tk, useTranslation } from "translations";

const inputs = { space: "space", role: "role", contactGroups: "contactGroups", position: "position" };

interface Props {
  activeOrganizationId: string;
  currentSpaceIds: string[];
  user?: { id: string; email: string };
  onCancel: () => any;
  onSuccess: () => any;
}

export const AddMemberToSpace = ({ activeOrganizationId, currentSpaceIds, user, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation();

  const notification = useNotification();

  const { data, loading: loadingSpaces } = useOrganizationSpacesWithContactGroupsQuery({
    variables: { id: activeOrganizationId },
    fetchPolicy: "cache-and-network",
  });

  const [invite, { loading: loadingInvite }] = useSpaceInviteUsersMutation();

  const loading = useMemo(() => loadingSpaces || loadingInvite, [loadingInvite, loadingSpaces]);

  const spaces = useMemo(() => {
    return sortByName(
      mapConnection(data?.organization?.spaces)
        .filter(({ id, state }) => !currentSpaceIds.includes(id) && state !== SpaceState.Archived)
        .map(({ contactGroups, id, name }) => ({
          id,
          name,
          contactGroups: mapConnection(contactGroups).map(({ id, name }) => ({ id, name })),
        }))
    );
  }, [currentSpaceIds, data]);

  const [space, setSpace] = useState<(typeof spaces)[0] | undefined>(undefined);
  const [role, setRole] = useState<Roles>(Roles.Regular);
  const [contactGroups, setContactGroups] = useState<string[]>([]);
  const [position, setPosition] = useState("");

  const allSuggestions = useMemo(() => {
    const currentSpace = spaces.find(({ id }) => id === space?.id);

    if (!currentSpace) {
      return spaces
        .flatMap(({ contactGroups }) => contactGroups.map(({ name }) => name))
        .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [] as string[]);
    }

    return currentSpace.contactGroups.map(({ name }) => name);
  }, [space, spaces]);

  const handleChangeSpace = useCallback((option: { value: (typeof spaces)[number] }) => setSpace(option.value), []);
  const handleChangeRole = useCallback((e: ChangeEvent<HTMLInputElement>) => setRole(e.target.value as Roles), []);
  const handleChangeContactGroups = useCallback<ComponentProps<typeof TagInput>["onChange"]>(
    (e) => setContactGroups(e.target.value),
    []
  );
  const handleChangePosition = useCallback((e: ChangeEvent<HTMLInputElement>) => setPosition(e.target.value), []);

  const validateContactGroups = useCallback(() => {
    if (!contactGroups.length) return t(tk.form.messages.required);
  }, [contactGroups.length, t]);

  const clear = useCallback(() => {
    setSpace(undefined);
    setRole(Roles.Regular);
    setContactGroups([]);
    setPosition("");
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!user || !space || !contactGroups) return;

    try {
      await invite({
        variables: {
          input: {
            space: space.id,
            users: [
              {
                email: user.email,
                role: role,
                position: position || null,
                contactGroups: contactGroups,
              },
            ],
          },
        },
      });

      await notification.show(t(tk.addMemberToSpace.notifications.success));
      onSuccess();
      clear();
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [clear, contactGroups, invite, notification, onSuccess, position, role, space, t, user]);

  const handleCancel = useCallback(() => {
    onCancel();
    clear();
  }, [clear, onCancel]);

  if (!spaces.length || !user) return null;

  return (
    <Layer onEsc={handleCancel} onClickOutside={handleCancel}>
      <Box width={"550px"} pad={"medium"} round={"xsmall"} elevation={"medium"}>
        <Form
          messages={{ required: t(tk.form.messages.required), invalid: t(tk.form.messages.invalid) }}
          onSubmit={handleSubmit}
        >
          <Box align={"start"}>
            <Text weight={"bold"}>{t(tk.common.addToSpace)}</Text>
          </Box>

          <Box margin={{ top: "medium" }}>
            <FormField name={inputs.space} label={t(tk.common.spaceName)} required={true}>
              <Select
                name={inputs.space}
                value={space}
                options={spaces}
                valueKey={"id"}
                labelKey={"name"}
                placeholder={t(tk.common.selectSpace)}
                disabled={loading}
                focusIndicator={false} // Scroll bug fix
                onChange={handleChangeSpace}
              />
            </FormField>

            <Text size={"small"} weight={"bold"} margin={{ left: "small", top: "small" }}>
              {t(tk.common.role)}
            </Text>
            <RadioButtonGroup
              name={inputs.role}
              value={role}
              options={[
                {
                  id: Roles.Regular,
                  value: Roles.Regular,
                  label: <Text size={"small"}>{t(tk.role.regular)}</Text>,
                },
                {
                  id: Roles.Manager,
                  value: Roles.Manager,
                  label: <Text size={"small"}>{t(tk.role.manager)}</Text>,
                },
              ]}
              disabled={loading}
              margin={{ top: "xsmall" }}
              onChange={handleChangeRole}
            />

            <FormField
              name={inputs.contactGroups}
              label={t(tk.addMemberToSpace.form.contactGroups.label)}
              validate={validateContactGroups}
              margin={{ top: "small" }}
            >
              <TagInput
                name={inputs.contactGroups}
                value={contactGroups}
                suggestions={allSuggestions}
                placeholder={t(tk.addMemberToSpace.form.contactGroups.placeholder)}
                onChange={handleChangeContactGroups}
              />
            </FormField>

            <FormField name={inputs.position} label={t(tk.common.position)} margin={{ top: "small" }}>
              <TextInput
                name={inputs.position}
                value={position}
                placeholder={t(tk.addMemberToSpace.form.position.placeholder)}
                disabled={loading}
                onChange={handleChangePosition}
              />
            </FormField>
          </Box>

          <Box direction={"row"} justify={"end"} margin={{ top: "medium" }} gap={"small"}>
            <Button label={t(tk.common.cancel)} size={"small"} disabled={loading} onClick={handleCancel} />
            <Button label={t(tk.common.addToSpace)} size={"small"} type={"submit"} primary={true} disabled={loading} />
            {loading && <Spinner />}
          </Box>
        </Form>
      </Box>
    </Layer>
  );
};
