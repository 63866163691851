import React, { memo, useCallback, useRef, useState } from "react";
import { Box, Button, Drop, DropProps } from "grommet";
import { More, MoreVertical } from "grommet-icons";
import { useClickAway } from "react-use";

type ClickHandler = (id: string) => any;

interface Props {
  id: string;
  items: { label: string; icon: JSX.Element; disabled?: boolean; border?: boolean; onClick: ClickHandler }[];
  align?: DropProps["align"];
  iconColor?: string;
}

export const ActionsMenu = memo(({ id, items, align = { top: "top", left: "right" }, iconColor }: Props) => {
  const [visible, setVisible] = useState(false);

  const targetRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggle = useCallback(() => setVisible((_visible) => !_visible), []);
  const hide = useCallback(() => setVisible(false), []);

  const handleClick = useCallback(
    (onClick: ClickHandler) => {
      hide();
      onClick(id);
    },
    [hide, id]
  );

  useClickAway(menuRef, hide);

  return (
    <Box>
      <Box ref={targetRef} justify={"center"} align={"center"} onClick={toggle}>
        {visible ? <MoreVertical color={iconColor} /> : <More color={iconColor} />}
      </Box>
      {targetRef.current && visible && (
        <Drop align={align} target={targetRef.current} onEsc={hide}>
          <Box ref={menuRef} pad={{ horizontal: "small", vertical: "xsmall" }} align={"start"}>
            {items.map(({ label, icon, disabled = false, border = false, onClick }, i) => (
              <Box
                key={i}
                border={!border ? false : { side: "bottom", color: "light-4" }}
                width={"100%"}
                align={"start"}
                pad={{ vertical: "xsmall" }}
              >
                <Button
                  label={label}
                  icon={icon}
                  size={"medium"}
                  plain={true}
                  disabled={disabled}
                  onClick={() => handleClick(onClick)}
                />
              </Box>
            ))}
          </Box>
        </Drop>
      )}
    </Box>
  );
});
