import React from "react";
import { Box, Button, Main, Text } from "grommet";
import { tk, useTranslation } from "translations";
import { useMembersAddConfirm } from "controllers";
import { ProgressBar, Spinner } from "components";
import { SimpleTable } from "components/MembersAddTable";
import { boxAnimation } from "styles";

export const MembersAddConfirm = () => {
  const { t } = useTranslation();

  const { data, state, handlers } = useMembersAddConfirm();

  if (!data.spaceName || !data.rows) return null;

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>
        {t(tk.common.addMembersToSpace)} <strong>{data.spaceName}</strong>
      </Text>

      <ProgressBar
        steps={[t(tk.membersAdd.steps.space), t(tk.membersAdd.steps.members), t(tk.membersAdd.steps.confirm)]}
        current={2}
        margin={{ top: "medium" }}
      />

      <Box
        width={"xxlarge"}
        margin={{ top: "medium" }}
        pad={{ horizontal: "small" }}
        overflow={"auto"}
        animation={boxAnimation}
      >
        <Text size={"large"}>{t(tk.membersAdd.confirm.subheading)}</Text>
        <Text size={"small"} margin={{ top: "small" }}>
          {t(tk.membersAdd.confirm.info)}
        </Text>

        {/** Table */}
        <SimpleTable data={data.rows} />
      </Box>

      {/** Footer actions */}
      <Box
        flex={{ shrink: 0 }}
        direction={"row"}
        justify={"between"}
        width={"xxlarge"}
        pad={"small"}
        gap={"small"}
        wrap={true}
        margin={{ top: "small" }}
      >
        <Button label={t(tk.common.cancel)} size={"medium"} disabled={state.loading} onClick={handlers.cancel} />
        <Box direction={"row"} gap={"small"} wrap={true}>
          <Button label={t(tk.common.back)} size={"medium"} disabled={state.loading} onClick={handlers.back} />
          <Button
            label={t(tk.common.addMembers)}
            size={"medium"}
            primary={true}
            disabled={state.loading}
            onClick={handlers.submit}
          />
          {state.loading && <Spinner />}
        </Box>
      </Box>
    </Main>
  );
};
