import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Button, DataTable, DataTableProps, Image, Main, Text } from "grommet";
import { Add, CircleInformation, Edit, SubtractCircle, Trash } from "grommet-icons";
import { useMember } from "controllers";
import {
  ActionsMenu,
  AddMemberToSpace,
  DeleteOrganizationMember,
  DeleteSpaceMember,
  EditMembership,
  ResponsiveContainer,
  SimpleTable,
} from "components";
import { tk, useTranslation } from "translations";
import { MemberParams } from "routes";

interface Props extends RouteComponentProps<MemberParams> {}

export const Member = ({ match }: Props) => {
  const { t } = useTranslation();

  const { data, state, handlers, deleteOrganizationMember, deleteSpaceMember } = useMember(match.params.id);

  const membershipColumns = useMemo<DataTableProps["columns"]>(() => {
    type Row = typeof data.memberships[0];

    return [
      {
        property: "name",
        header: <Header value={t(tk.common.spaceName)} />,
        render: ({ space }: Row) => (
          <Box direction={"row"} align={"center"} onClick={() => handlers.goToSpace(space.id)}>
            <Image src={space.logo} height={40} />
            <Text size={"small"} margin={{ left: "small" }}>
              {space.name}
            </Text>
          </Box>
        ),
      },
      {
        property: "role",
        header: <Header value={t(tk.common.role)} />,
        align: "center",
        render: ({ role }: Row) => <Text size={"small"}>{t(tk.role[role])}</Text>,
      },
      {
        property: "contactGroups",
        header: <Header value={t(tk.common.groups)} />,
        align: "center",
        render: ({ contactGroups }: Row) => <Text size={"small"}>{contactGroups || "-"}</Text>,
      },
      {
        property: "actions",
        header: <Header value={t(tk.common.actions)} />,
        align: "center",
        render: ({ id, space }: Row) => (
          <ActionsMenu
            id={space.id}
            items={[
              {
                label: t(tk.common.spaceDetail),
                icon: <CircleInformation size={"small"} />,
                onClick: handlers.goToSpace,
              },
              {
                label: t(tk.spaceMembers.actions.edit),
                icon: <Edit size={"small"} />,
                disabled: !space.active,
                onClick: () => handlers.editMembership(id),
              },
              {
                label: t(tk.common.removeFromSpace),
                icon: <SubtractCircle size={"small"} />,
                disabled: !space.active,
                onClick: () => deleteSpaceMember.deleteMember(id),
              },
            ]}
          />
        ),
      },
    ];
  }, [data, deleteSpaceMember, handlers, t]);

  if (!data || !data.user) return null;

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>{t(tk.common.memberDetail)}</Text>

      <ResponsiveContainer width={"medium"}>
        <Box direction={"row"} justify={"between"} align={"start"} width={"100%"} margin={{ top: "medium" }}>
          <Box width={"200px"} align={"start"}>
            <Button label={t(tk.common.back)} size={"small"} onClick={handlers.goBack} />
          </Box>

          <Image src={data.user.profileImage} height={120} />

          <Box width={"200px"} />
        </Box>

        <Text size={"xlarge"} weight={"bold"} margin={{ top: "medium" }}>
          {data.user.firstName} {data.user.lastName}
        </Text>

        <SimpleTable
          label={t(tk.common.generalInfo)}
          columns={{ label: { primary: true }, value: { right: true } }}
          data={[
            { label: t(tk.common.email), value: data.user.email },
            { label: t(tk.common.phoneNumber), value: data.user.phoneNumber || "-" },
            { label: t(tk.common.notificationNumber), value: data.user.notificationNumber || "-" },
            {
              label: `${t(tk.common.activationCode)} (${t(tk.userState[data.user.isActive ? "active" : "inactive"])})`,
              value: data.user.activationCode || "-",
            },
            { label: t(tk.common.aboutMe), value: truncate(data.user.about || "-", 60) },
          ]}
          buttons={[
            {
              label: t(tk.common.deleteMember),
              icon: <Trash size={"small"} />,
              primary: false,
              onClick: handlers.deleteMember,
            },
            {
              label: t(tk.common.editMember),
              icon: <Edit size={"small"} />,
              onClick: handlers.edit,
            },
          ]}
          margin={{ top: "small" }}
        />

        <Box width={"100%"} margin={{ top: "medium" }}>
          <Text size={"small"}>{t(tk.common.spaces)}</Text>

          <Box
            elevation={"small"}
            round={"xsmall"}
            overflow={"auto"}
            margin={{ top: "small" }}
            style={{ whiteSpace: "nowrap" }}
          >
            <DataTable
              columns={membershipColumns}
              data={data.memberships}
              pad={{ vertical: "small", horizontal: "medium" }}
              border={{ body: { side: "bottom", color: "light-4" }, header: { side: "bottom", color: "light-4" } }}
              replace={true}
            />
            <Box direction={"row"} justify={"end"} pad={{ horizontal: "small", bottom: "small" }}>
              <Button
                label={t(tk.common.addToSpace)}
                icon={<Add size={"small"} />}
                size={"medium"}
                primary={true}
                disabled={!data.canAddToSpace}
                onClick={handlers.addToSpace}
              />
            </Box>
          </Box>
        </Box>
      </ResponsiveContainer>

      <AddMemberToSpace
        activeOrganizationId={data.activeOrganizationId}
        currentSpaceIds={data.currentSpaceIds}
        user={state.memberToAddToSpace}
        onCancel={handlers.handleAddMemberToSpaceCancel}
        onSuccess={handlers.handleAddMemberToSpaceSuccess}
      />

      <DeleteOrganizationMember
        users={deleteOrganizationMember.membersToDelete}
        organizationId={data.activeOrganizationId}
        onCancel={deleteOrganizationMember.handleDeleteMemberCancel}
        onSuccess={deleteOrganizationMember.handleDeleteMemberSuccess}
      />

      <DeleteSpaceMember
        memberships={deleteSpaceMember.membersToDelete}
        onCancel={deleteSpaceMember.handleDeleteSpaceMemberCancel}
        onSuccess={deleteSpaceMember.handleDeleteSpaceMemberSuccess}
      />

      <EditMembership
        space={state.membershipToEdit?.space}
        membership={state.membershipToEdit}
        onCancel={handlers.handleEditMembershipCancel}
        onSuccess={handlers.handleEditMembershipSuccess}
      />
    </Main>
  );
};

const Header = ({ value }: { value: string }) => (
  <Text size={"small"} weight={"bold"}>
    {value}
  </Text>
);

const truncate = (input: string, max: number) => (input.length > max ? `${input.substring(0, max)}...` : input);
