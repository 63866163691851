import React, { memo } from "react";
import { Button } from "grommet";
import { useTooltip } from "hooks";
import { Tooltip } from "components/Tooltip";

interface Props {
  label?: string;
  title?: string;
  tooltip?: string;
  icon: JSX.Element;
  disabled?: boolean;
  onClick: () => any;
}

export const GroupsRowAction = memo(({ label, title, tooltip, icon, disabled = false, onClick }: Props) => {
  const tt = useTooltip();

  return (
    <>
      <Button
        {...tt.targetProps}
        label={label}
        title={!tooltip ? title : undefined}
        icon={icon}
        size={"small"}
        plain={true}
        style={{ padding: 5, borderRadius: 5, fontSize: "12px", lineHeight: "12px" }}
        hoverIndicator={"background"}
        disabled={disabled}
        onClick={onClick}
      />
      {!!tooltip && (
        <Tooltip value={tooltip} heading={title} visible={tt.visible} target={tt.target} maxWidth={"250px"} />
      )}
    </>
  );
});
