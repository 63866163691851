import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useActiveOrganizationChange, useMe, useMedia, useNotification } from "hooks";
import {
  MediaTypes,
  OrganizationChangeUserInput,
  useOrganizationChangeUserMutation,
  UserFragment,
  useUserQuery,
} from "api";
import { tk, useTranslation } from "translations";
import { routes } from "routes";

const inputs = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  phoneNumber: "phoneNumber",
  notificationNumber: "notificationNumber",
  about: "about",
};

type Value = typeof inputs;

const defaultValue = {
  [inputs.firstName]: "",
  [inputs.lastName]: "",
  [inputs.email]: "",
  [inputs.phoneNumber]: "",
  [inputs.notificationNumber]: "",
  [inputs.about]: "",
} as Value;

export const useMemberEdit = (id: string) => {
  const { t } = useTranslation();

  useTitle(t(tk.common.editMember) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const notification = useNotification();

  const { activeOrganization, me, refetch: refetchMe, loading: loadingMe } = useMe();

  // On organization change, go to members
  useActiveOrganizationChange(() => history.replace(routes.members));

  const media = useMedia(MediaTypes.ProfileImage);

  const [value, setValue] = useState<Value>(defaultValue);

  const { data, loading: loadingUser } = useUserQuery({
    variables: { id, organization: activeOrganization?.id || "" },
    skip: !activeOrganization,
    fetchPolicy: "cache-and-network",
  });

  const user = useMemo(() => {
    if (!data || !data.user) return undefined;

    return data.user as UserFragment;
  }, [data]);

  const [update, { loading: loadingUpdate }] = useOrganizationChangeUserMutation();

  const loading = useMemo(() => loadingUser || loadingUpdate || media.loading || loadingMe, [
    loadingMe,
    loadingUpdate,
    loadingUser,
    media.loading,
  ]);

  const handleCancel = useCallback(() => history.goBack(), [history]);

  const handleChange = useCallback((_value: any) => setValue(_value), []);

  const handleSubmit = useCallback(async () => {
    if (!user || loading) return;

    try {
      const { firstName, lastName, phoneNumber, notificationNumber, about } = value;

      const input: OrganizationChangeUserInput = { user: id };

      if (!!media.preview) input.profileImage = await media.upload();
      if (firstName !== user.firstName) input.firstName = firstName;
      if (lastName !== user.lastName) input.lastName = lastName;
      if ((phoneNumber || null) !== user.phoneNumber) input.phoneNumber = phoneNumber || null;
      if ((notificationNumber || null) !== user.notificationNumber)
        input.notificationNumber = notificationNumber || null;
      if ((about || null) !== user.about) input.about = about || null;

      if (Object.keys(input).length === 1) {
        await notification.show(t(tk.memberEdit.success));
        return history.goBack();
      }

      await update({ variables: { input } });

      if (id === me?.userId) {
        await refetchMe();
      }

      await notification.show(t(tk.memberEdit.success));
      history.goBack();
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [history, id, loading, me, media, notification, refetchMe, t, update, user, value]);

  useEffect(() => {
    let isUnmounted = false;

    if (isUnmounted || !user) return;

    const { firstName, lastName, email, phoneNumber, notificationNumber, about } = user;

    setValue({
      firstName,
      lastName,
      email,
      phoneNumber: phoneNumber || "",
      notificationNumber: notificationNumber || "",
      about: about || "",
    });

    return () => {
      isUnmounted = true;
    };
  }, [user]);

  return {
    inputs,
    media,
    data: { user },
    state: { loading, value },
    handlers: { handleChange, handleCancel, handleSubmit },
  };
};
