import { useEffect, useRef } from "react";
import { useMe } from "hooks/useMe";

export const useActiveOrganizationChange = (onChange: () => any) => {
  const previousActiveOrganizationId = useRef<string | undefined>(undefined);

  const { activeOrganization } = useMe();

  useEffect(() => {
    if (!activeOrganization) return;
    if (!previousActiveOrganizationId.current) {
      previousActiveOrganizationId.current = activeOrganization.id;
      return;
    }

    if (activeOrganization.id !== previousActiveOrganizationId.current) {
      onChange();
      return;
    }

    previousActiveOrganizationId.current = activeOrganization.id;
  }, [activeOrganization, onChange]);
};
