import React, { useCallback, useRef } from "react";
import { parse, ParseConfig } from "papaparse";
import { detect } from "jschardet";
import { Box, Button } from "grommet";
import { Upload } from "grommet-icons";

export interface IFileInfo {
  name: string;
  size: number;
  type: string;
}

interface Props {
  label: string;
  disabled?: boolean;
  fileEncoding?: string;
  parserOptions?: ParseConfig;
  onLoad?: (data: any[][], fileInfo: IFileInfo) => any;
  onError?: () => any;
}

export const CsvReader = ({
  label,
  disabled = false,
  fileEncoding = "UTF-8",
  parserOptions = {} as ParseConfig,
  onLoad = () => {},
  onError = () => {},
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const resetInput = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.value = "";
    inputRef.current.files = null;
  }, []);

  const handleChangeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files.length) return resetInput();

      const files: FileList = e.target.files;

      const fileInfo: IFileInfo = {
        name: files[0].name,
        size: files[0].size,
        type: files[0].type,
      };

      let binaryReader: FileReader = new FileReader();

      binaryReader.onload = () => {
        if (typeof binaryReader.result !== "string") return onError();

        let encoding = detect(binaryReader.result)?.encoding || fileEncoding;

        // Change Hebrew false positive
        if (encoding === "windows-1255") encoding = "windows-1250";

        parse(files[0], {
          ...parserOptions,
          encoding: encoding,
          error: onError,
          complete: (result) => {
            resetInput();
            onLoad(result?.data ?? [], fileInfo);
          },
        });
      };

      binaryReader.readAsBinaryString(files[0]);
    },
    [fileEncoding, onError, onLoad, parserOptions, resetInput]
  );

  const handleClickButton = useCallback(() => inputRef.current?.click(), []);

  return (
    <Box>
      <input ref={inputRef} type={"file"} accept={".csv, text/csv"} hidden={true} onChange={handleChangeFile} />
      <Button
        icon={<Upload size={"small"} />}
        label={label}
        size={"medium"}
        color={"neutral-3"}
        primary={true}
        disabled={disabled}
        onClick={handleClickButton}
      />
    </Box>
  );
};
