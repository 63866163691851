import React, { memo, useCallback } from "react";
import { tk, useTranslation } from "translations";
import { Box, Image, TableCell, TableRow, Text } from "grommet";
import { GroupsRowAction, GroupUser } from "components";
import { SubtractCircle } from "grommet-icons";

interface Props {
  user: GroupUser;
  onRemove: (id: string) => any;
}

export const GroupUserRow = memo(({ user, onRemove }: Props) => {
  const { t } = useTranslation();

  const handleClickRemove = useCallback(() => onRemove(user.id), [onRemove, user.id]);

  return (
    <TableRow>
      <TableCell pad={"none"}>
        <Box direction={"row"} align={"center"} gap={"xsmall"}>
          <Image src={user.profileImage} height={25} />
          <Text size={"small"} color={textColor(user.isActive)}>
            {user.firstName} {user.lastName}
          </Text>
        </Box>
      </TableCell>
      <TableCell align={"center"}>
        <Text size={"small"} color={textColor(user.isActive)}>
          {t(tk.role[user.role])}
        </Text>
      </TableCell>
      <TableCell pad={"none"}>
        <Box align={"end"}>
          <GroupsRowAction
            label={t(tk.common.remove)}
            icon={<SubtractCircle size={"small"} />}
            onClick={handleClickRemove}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
});

const textColor = (isActive: boolean) => (!isActive ? "status-disabled" : undefined);
