import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Image, Layer, Select, Text } from "grommet";
import { FormSearch } from "grommet-icons";
import { Spinner } from "components";
import { useModal, useNotification } from "hooks";
import {
  mapConnection,
  SearchedUserFragment,
  useOrganizationAddOwnerMutation,
  useSearchOrganizationUsersLazyQuery,
} from "api";
import { tk, useTranslation } from "translations";

interface Props {
  open: boolean;
  ownerIds: string[];
  organizationId: string;
  onClose: () => any;
}

export const ManagerAdd = ({ open, organizationId, ownerIds, onClose }: Props) => {
  const { t } = useTranslation();

  const { visible, show, hide } = useModal();
  const notification = useNotification();

  const [selected, setSelected] = useState<SearchedUserFragment | undefined>(undefined);
  const [options, setOptions] = useState<SearchedUserFragment[]>([]);

  const [search, { data }] = useSearchOrganizationUsersLazyQuery();
  const results = useMemo(() => mapConnection<SearchedUserFragment>(data?.organization?.users), [data]);

  const [mutate, { loading }] = useOrganizationAddOwnerMutation();

  useEffect(() => setOptions(results.filter(({ id }) => !ownerIds.includes(id))), [ownerIds, results]);

  const handleClose = useCallback(() => {
    hide();
    onClose();
    setOptions([]);
    setSelected(undefined);
  }, [hide, onClose]);

  const handleSubmit = useCallback(async () => {
    if (!selected || loading) return;

    handleClose();

    try {
      await mutate({ variables: { input: { organization: organizationId, user: selected.id } } });
      await notification.show(t(tk.organization.managers.add.success));
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [handleClose, loading, mutate, notification, organizationId, selected, t]);

  useEffect(() => {
    if (open) show();
  }, [open, show]);

  if (!visible) return null;

  return (
    <Layer onEsc={handleClose} onClickOutside={handleClose}>
      <Box width={{ min: "medium" }} pad={"medium"} round={"xsmall"} elevation={"medium"}>
        <Box align={"start"}>
          <Text weight={"bold"}>{t(tk.organization.managers.add.addManager)}</Text>
        </Box>
        <Box margin={{ top: "small" }}>
          <Select
            size="small"
            placeholder={<Placeholder value={t(tk.organization.managers.add.search)} />}
            emptySearchMessage={t(tk.organization.managers.add.noResults)}
            valueLabel={!selected ? undefined : <Option user={selected} />}
            options={options}
            children={(option: SearchedUserFragment) => <Option user={option} />}
            disabled={loading}
            onChange={({ option }) => setSelected(option)}
            onSearch={(query) => search({ variables: { id: organizationId, query, first: 20 } })}
          />
        </Box>
        <Box direction={"row"} justify={"end"} margin={{ top: "medium" }} gap={"small"}>
          <Button label={t(tk.common.cancel)} size={"small"} disabled={loading} onClick={handleClose} />
          <Button
            label={t(tk.organization.managers.add.addManager)}
            size={"small"}
            primary={true}
            disabled={!selected || loading}
            onClick={handleSubmit}
          />
          {loading && <Spinner />}
        </Box>
      </Box>
    </Layer>
  );
};

const Placeholder = ({ value }: { value: string }) => (
  <Box direction={"row"} align={"center"} pad={{ horizontal: "xxsmall" }}>
    <FormSearch />
    <Text size={"small"} color={"dark-4"} margin={{ left: "xsmall" }}>
      {value}
    </Text>
  </Box>
);

const Option = ({ user }: { user: SearchedUserFragment }) => {
  const { id, email, firstName, lastName, profileImage } = user;

  return (
    <Box key={id} direction={"row"} align={"center"} pad={"small"}>
      <Image src={profileImage} height={18} />
      <Text size={"small"} margin={{ left: "small" }}>
        {firstName} {lastName} ({email})
      </Text>
    </Box>
  );
};
