import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useTitle } from "react-use";
import { useMe, useNotification } from "hooks";
import * as Api from "api";
import { SpaceState } from "api";
import { tk, useTranslation } from "translations";

export type Space = Api.OrganizationSpaceSelectFragment;
export type Group = Api.SearchedContactGroupFragment;

export const useGroups = () => {
  const { t } = useTranslation();

  useTitle(t(tk.common.groups) + t(tk.common.documentTitleSuffix));

  const notification = useNotification();

  const [space, setSpace] = useState<Space | undefined>(undefined);

  const [group, setGroup] = useState<Group | undefined>(undefined);

  const [importFromGroup, setImportFromGroup] = useState(false);

  const { activeOrganization } = useMe();

  /** Queries */

  const { data: dataSpaces, loading: loadingSpaces } = Api.useOrganizationSpacesSelectQuery({
    variables: { id: activeOrganization?.id || "" },
    fetchPolicy: "cache-and-network",
    skip: !activeOrganization?.id,
  });

  const [fetchGroups, { data: dataGroups, loading: loadingGroups }] = Api.useSpaceGroupsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [fetchGroupDetail, { data: dataGroupDetail, loading: loadingGroupDetail }] = Api.useContactGroupLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [fetchGroupForImport, { data: dataGroupForImport }] = Api.useContactGroupLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  /** Mutations */

  const [createGroupMutation, { loading: loadingCreateGroup }] = Api.useContactGroupCreateMutation();

  const [updateGroup, { loading: loadingUpdateGroup }] = Api.useContactGroupUpdateMutation();

  const [deleteGroupMutation, { loading: loadingDeleteGroup }] = Api.useContactGroupDeleteMutation();

  /** Memos */

  const loading = useMemo(() => {
    return (
      loadingSpaces ||
      loadingGroups ||
      loadingGroupDetail ||
      loadingCreateGroup ||
      loadingUpdateGroup ||
      loadingDeleteGroup
    );
  }, [loadingCreateGroup, loadingDeleteGroup, loadingGroupDetail, loadingGroups, loadingSpaces, loadingUpdateGroup]);

  const spaces = useMemo(() => {
    return Api.sortByName(Api.mapConnection<Space>(dataSpaces?.organization?.spaces)).filter(
      ({ state }) => state !== SpaceState.Archived
    );
  }, [dataSpaces]);

  const groups = useMemo(() => {
    return !space ? [] : Api.sortByName(Api.mapConnection<Group>(dataGroups?.space?.contactGroups));
  }, [dataGroups, space]);

  const groupDetail = useMemo(() => {
    if (!group || !dataGroupDetail?.contactGroup) return undefined;

    const { users, userCount, ...user } = dataGroupDetail?.contactGroup;

    const mappedUsers = users.edges.flatMap((e) => {
      return !e || !e.node ? [] : [{ role: e.node.role, ...e.node.user }];
    });

    return {
      ...user,
      userCount: userCount || 0,
      users: mappedUsers,
    };
  }, [dataGroupDetail, group]);

  /** Callbacks */

  const handleChangeSpace = useCallback(
    async (space: Space) => {
      setImportFromGroup(false);
      setGroup(undefined);
      setSpace(space);

      try {
        fetchGroups({ variables: { id: space.id } });
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [fetchGroups, notification, t]
  );

  const selectGroup = useCallback(
    async (selectedGroup: Group) => {
      setImportFromGroup(false);

      const isActiveGroup = group?.id === selectedGroup.id;

      if (isActiveGroup) {
        setGroup(undefined);
        return;
      }

      setGroup(selectedGroup);

      try {
        fetchGroupDetail({ variables: { id: selectedGroup.id } });
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [fetchGroupDetail, group, notification, t]
  );

  const addGroup = useCallback(
    async (name: string) => {
      if (!space) return;

      if (groups.find((g) => g.name === name)) {
        await notification.show(t(tk.groups.notifications.renameGroup.notUnique), true);
        return;
      }

      try {
        const { data } = await createGroupMutation({ variables: { input: { space: space.id, name } } });

        if (!data?.contactGroupCreate.contactGroup) return;

        fetchGroups({ variables: { id: space.id } });
        await notification.show(t(tk.groups.notifications.addGroup.success));
        await selectGroup(data.contactGroupCreate.contactGroup);
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [createGroupMutation, fetchGroups, groups, notification, selectGroup, space, t]
  );

  const renameGroup = useCallback(
    async (id: string, name: string) => {
      if (!space) return;

      const potentialDuplicate = groups.find((g) => g.name === name);

      if (potentialDuplicate && potentialDuplicate.id === id) return;

      if (potentialDuplicate) {
        await notification.show(t(tk.groups.notifications.renameGroup.notUnique), true);
        return;
      }

      try {
        await updateGroup({ variables: { input: { contactGroup: id, name } } });
        await notification.show(t(tk.groups.notifications.renameGroup.success));
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [groups, notification, space, t, updateGroup]
  );

  const togglePrivateGroup = useCallback(
    async (id: string, value: boolean) => {
      if (!space) return;

      try {
        await updateGroup({ variables: { input: { contactGroup: id, private: value } } });
        await notification.show(
          value ? t(tk.groups.notifications.privateGroup.active) : t(tk.groups.notifications.privateGroup.inactive)
        );
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [notification, space, t, updateGroup]
  );

  const deleteGroup = useCallback(
    async (id: string) => {
      if (!space?.id) return;

      try {
        const { data } = await deleteGroupMutation({ variables: { input: { contactGroup: id } } });

        if (data?.contactGroupDelete.success) {
          if (id === groupDetail?.id) setGroup(undefined);

          fetchGroups({ variables: { id: space.id } });
          await notification.show(t(tk.groups.notifications.deleteGroup.success));
          return;
        }

        const failedUsers = (data?.contactGroupDelete.failedUsers || []) as { firstName: string; lastName: string }[];

        if (failedUsers.length > 0) {
          await notification.show(
            <Trans
              i18nKey={tk.groups.notifications.deleteGroup.hasMembers.message}
              values={{ members: failedUsers.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ") }}
              components={[<strong />]}
            />,
            true,
            t(tk.groups.notifications.deleteGroup.hasMembers.title)
          );
          return;
        }

        await notification.show(t(tk.errors.general), true);
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [deleteGroupMutation, fetchGroups, groupDetail, notification, space, t]
  );

  const addUser = useCallback(
    async (id: string | string[]) => {
      if (!groupDetail) return;

      const users = Array.isArray(id) ? id : [...groupDetail.users.map(({ id }) => id), id];

      try {
        await updateGroup({ variables: { input: { contactGroup: groupDetail.id, users } } });

        fetchGroupDetail({ variables: { id: groupDetail.id } });

        await notification.show(t(tk.groups.notifications.addMember.success));
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [fetchGroupDetail, groupDetail, notification, t, updateGroup]
  );

  const removeUser = useCallback(
    async (id: string) => {
      if (!groupDetail) return;

      const users = groupDetail.users.filter((user) => user.id !== id).map(({ id }) => id);

      try {
        const { data } = await updateGroup({ variables: { input: { contactGroup: groupDetail.id, users } } });

        if (!data?.contactGroupUpdate.success) {
          await notification.show(
            t(tk.groups.notifications.removeMember.noGroup.message),
            true,
            t(tk.groups.notifications.removeMember.noGroup.title)
          );
          return;
        }

        fetchGroupDetail({ variables: { id: groupDetail.id } });

        await notification.show(t(tk.groups.notifications.removeMember.success));
      } catch (e) {
        await notification.show(t(tk.errors.general), true);
      }
    },
    [fetchGroupDetail, groupDetail, notification, t, updateGroup]
  );

  const addUsersFromGroup = useCallback(
    async (id: string) => {
      try {
        await fetchGroupForImport({ variables: { id } });
        setImportFromGroup(true);
      } catch (e: any) {
        Api.resolveError(e);
      }
    },
    [fetchGroupForImport]
  );

  /** Effects */

  useEffect(() => {
    setSpace(undefined);
    setGroup(undefined);
  }, [activeOrganization]);

  useEffect(() => {
    if (!importFromGroup || !dataGroupForImport?.contactGroup?.users || !groupDetail) return;

    const ids = Api.mapConnection(dataGroupForImport.contactGroup.users).map(({ user: { id } }) => id);
    const currentIds = groupDetail.users.map(({ id }) => id);
    const newIds = [...currentIds, ...ids.filter((id) => !currentIds.includes(id))];

    setImportFromGroup(false);

    if (currentIds.length === newIds.length) {
      notification.show(t(tk.groups.notifications.addMember.noChange)).finally();
      return;
    }

    addUser(newIds).finally();
  }, [addUser, dataGroupForImport, groupDetail, importFromGroup, notification, t]);

  return {
    data: { spaces, groups, groupDetail },
    state: { loading, loadingSpaces, loadingGroups, loadingGroupDetail, space, group },
    handlers: {
      handleChangeSpace,
      addGroup,
      selectGroup,
      renameGroup,
      togglePrivateGroup,
      deleteGroup,
      addUser,
      removeUser,
      addUsersFromGroup,
    },
  };
};
