import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Layer, Text } from "grommet";
import { Trans } from "react-i18next";
import { Spinner } from "components";
import { useNotification, OrganizationMember } from "hooks";
import { useOrganizationRemoveUsersMutation } from "api";
import { tk, useTranslation } from "translations";

interface Props {
  organizationId: string;
  users?: OrganizationMember[];
  onCancel: () => any;
  onSuccess: () => any;
}

export const DeleteOrganizationMember = ({ organizationId, users, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation();

  const { show } = useNotification();

  const [remove] = useOrganizationRemoveUsersMutation();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!users || !organizationId) return;

    setLoading(true);

    const userIds = users.map(({ id }) => id);

    try {
      await remove({ variables: { input: { organization: organizationId, users: userIds } } });
      await show(t(tk.members.dialog.delete.notifications.success));
      setLoading(false);
      onSuccess();
    } catch (e) {
      setLoading(false);
      await show(t(tk.members.dialog.delete.notifications.error), true);
    }
  }, [onSuccess, organizationId, remove, show, t, users]);

  const isSingleUser = useMemo(() => users?.length === 1, [users]);
  const plural = useMemo(() => (isSingleUser ? "single" : "multiple"), [isSingleUser]);

  if (!users || !users.length) return null;

  return (
    <Layer onEsc={onCancel} onClickOutside={onCancel}>
      <Box width={{ max: "550px" }} pad={"medium"} round={"xsmall"} elevation={"medium"}>
        <Box align={"start"}>
          <Text weight={"bold"}>{t(tk.members.dialog.delete.heading[plural])}</Text>
        </Box>

        <Box margin={{ top: "medium" }}>
          <Text size={"small"} weight={"bold"}>
            {t(tk.members.dialog.delete.warning)}
          </Text>

          <Text size={"small"} margin={{ top: "xsmall" }}>
            {t(tk.members.dialog.delete.info[plural])}
          </Text>

          <Text size={"small"} margin={{ top: "small" }}>
            {isSingleUser ? (
              <Trans
                i18nKey={tk.members.dialog.delete.confirmation[plural]}
                values={users[0]}
                components={[<strong />]}
              />
            ) : (
              t(tk.members.dialog.delete.confirmation[plural])
            )}
          </Text>
        </Box>

        {!isSingleUser && (
          <Box height={{ max: "275px" }} overflow={"auto"} margin={{ top: "small" }}>
            {users.map(({ id, firstName, lastName, email }) => (
              <Text key={id} size={"small"} margin={{ top: "xsmall" }}>
                <strong>
                  {firstName} {lastName}
                </strong>{" "}
                ({email})
              </Text>
            ))}
          </Box>
        )}

        <Box direction={"row"} justify={"end"} margin={{ top: "medium" }} gap={"small"}>
          <Button label={t(tk.common.cancel)} size={"small"} disabled={loading} onClick={onCancel} />
          <Button
            label={t(tk.members.dialog.delete.submit)}
            size={"small"}
            primary={true}
            color={"status-critical"}
            disabled={loading}
            onClick={handleSubmit}
          />
          {loading && <Spinner />}
        </Box>
      </Box>
    </Layer>
  );
};
