import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Image, Text } from "grommet";
import { Logout } from "grommet-icons";
import { useAuth } from "api";
import { useMe } from "hooks";
import { useTranslation, tk } from "translations";
import { MemberParams, routes, setRouteParams } from "routes";

export const UserMenu = () => {
  const { t } = useTranslation();
  const { me } = useMe();
  const { logout } = useAuth();
  const { push } = useHistory();

  const handleClickMe = useCallback(() => {
    if (!me?.userId) return;

    const route = setRouteParams<MemberParams>(routes.member, { id: me.userId });
    push(route);
  }, [me, push]);

  if (!me) return null;

  return (
    <Box justify={"start"} flex={"grow"} width={"100%"} margin={{ top: "medium" }}>
      <Box
        direction={"row"}
        align={"center"}
        pad={{ horizontal: "medium", vertical: "small" }}
        hoverIndicator="background"
        onClick={handleClickMe}
      >
        <Image src={me.profileImage} height={60} />
        <Box margin={{ left: "small" }}>
          <Text>
            {me.firstName} {me.lastName}
          </Text>
          <Text size={"small"}>{t(tk.common.admin)}</Text>
        </Box>
      </Box>

      <Button margin={{ bottom: "small" }} hoverIndicator="background" onClick={logout}>
        <Box direction="row" align="center" pad={{ horizontal: "medium", vertical: "small" }}>
          <Logout />
          <Text margin={{ left: "small" }}>{t(tk.common.logout)}</Text>
        </Box>
      </Button>
    </Box>
  );
};
