import React, { useCallback, useMemo } from "react";
import { Box, Button, DataTable, DataTableProps, Image, Main, Select, Text, TextInput } from "grommet";
import { Add, CircleInformation, Edit, FormSearch, Hide, FormView, Filter } from "grommet-icons";
import { useSpaces } from "controllers";
import { ActionsMenu, ResponsiveContainer, Spinner } from "components";
import { tk, useTranslation } from "translations";
import { boxAnimation } from "styles";
import { SpaceState } from "api";

export const Spaces = () => {
  const { t } = useTranslation();

  const { data, state, handlers } = useSpaces();

  type Space = (typeof data.filteredSpaces)[0];

  const columns = useMemo<DataTableProps["columns"]>(() => {
    return [
      {
        property: "name",
        header: <Header value={t(tk.common.spaceName)} />,
        render: ({ id, name, logo, state }: Space) => (
          <Box direction={"row"} align={"center"} onClick={() => handlers.goToDetail(id)}>
            <Image src={logo} height={40} margin={{ right: "small" }} />
            <Text size={"small"} weight={"bold"} color={textColor(state !== SpaceState.Archived)}>
              {name}
            </Text>
          </Box>
        ),
      },
      {
        property: "members",
        header: <Header value={t(tk.common.membersCount)} />,
        align: "center",
        render: ({ members, state }: Space) => (
          <Text size={"small"} color={textColor(state !== SpaceState.Archived)}>
            {members.regular} {t(tk.spaces.members.regular, { count: members.regular })} {members.manager}{" "}
            {t(tk.spaces.members.manager, { count: members.manager })}
          </Text>
        ),
      },
      {
        property: "state",
        header: (
          <Box direction={"row"} align={"center"} justify={"center"} gap={"small"}>
            <Box
              direction={"row"}
              align={"center"}
              gap={"xsmall"}
              onClick={state.stateFilterVisible ? handlers.hideStateFilter : handlers.showStateFilter}
            >
              <Header value={t(tk.common.state)} />
              <Filter size={"small"} />
            </Box>
            <StateFilter
              visible={state.stateFilterVisible}
              value={state.stateFilter}
              onChange={handlers.handleChangeStateFilter}
            />
          </Box>
        ),
        align: "center",
        render: ({ state }: Space) => {
          const value = t(
            state === SpaceState.Draft
              ? tk.spaceState.concept
              : state === SpaceState.Active
              ? tk.spaceState.active
              : tk.spaceState.inactive
          );
          return <Label value={value} isActive={state !== SpaceState.Archived} />;
        },
      },
      {
        property: "actions",
        header: <Header value={t(tk.common.actions)} />,
        align: "center",
        render: ({ id, state }: Space) => (
          <ActionsMenu
            id={id}
            items={[
              {
                icon: state === SpaceState.Archived ? <FormView size={"small"} /> : <Hide size={"small"} />,
                label: t(tk.common[state === SpaceState.Archived ? "activate" : "deactivate"]),
                border: true,
                onClick: state === SpaceState.Archived ? handlers.activate : handlers.deactivate,
              },
              {
                icon: <CircleInformation size={"small"} />,
                label: t(tk.common.spaceDetail),
                onClick: handlers.goToDetail,
              },
              {
                icon: <Edit size={"small"} />,
                label: t(tk.common.editSpace),
                disabled: state === SpaceState.Archived,
                onClick: handlers.edit,
              },
            ]}
            iconColor={textColor(state !== SpaceState.Archived)}
          />
        ),
      },
    ];
  }, [handlers, state.stateFilter, state.stateFilterVisible, t]);

  return (
    <Main align={"center"} pad={"medium"} fill={true}>
      <Text size={"large"}>{t(tk.spaces.heading)}</Text>

      <ResponsiveContainer width={"medium"} margin={{ top: "medium" }}>
        {/** Header - search + buttons */}
        <Box align={"start"}>
          <Text weight={"bold"}>
            {t(tk.spaces.count)} {data.filteredSpaces.length}
          </Text>

          <Box direction={"row"} alignSelf={"stretch"} justify={"between"} margin={{ top: "small" }} wrap={true}>
            <Box width={"medium"}>
              <TextInput
                type={"search"}
                value={state.query}
                placeholder={t(tk.spaces.search.placeholder)}
                icon={state.loading ? <Spinner /> : <FormSearch color={data.hasSpaces() ? "dark-3" : "light-3"} />}
                disabled={!data.hasSpaces && !state.loading}
                onChange={handlers.handleChangeSearch}
              />
            </Box>

            <Button
              label={t(tk.common.createSpace)}
              size={"small"}
              icon={<Add size={"small"} />}
              primary={true}
              disabled={!data.hasSpaces && !state.loading}
              onClick={handlers.goToCreate}
            />
          </Box>
        </Box>

        {/** Table */}
        <Box margin={{ top: "medium" }} elevation={"small"} round={"xsmall"} animation={boxAnimation} overflow={"auto"}>
          <DataTable
            columns={columns}
            data={data.filteredSpaces}
            primaryKey={"id"}
            pad={{ vertical: "small", horizontal: "small" }}
            border={{ body: { side: "bottom", color: "light-4" }, header: { side: "bottom", color: "light-4" } }}
            replace={true}
          />

          {/** No results */}
          {!data.filteredSpaces.length && (
            <Box align={"center"} justify={"center"} height={"small"}>
              {!data.hasSpaces && (
                <>
                  <Text size={"small"}>{t(tk.spaces.empty.noSpaces)}</Text>
                  <Button
                    label={t(tk.common.createSpace)}
                    icon={<Add size={"small"} />}
                    size={"medium"}
                    primary={true}
                    margin={{ top: "small" }}
                    onClick={handlers.goToCreate}
                  />
                </>
              )}

              {data.hasSpaces() && <Text size={"small"}>{t(tk.spaces.empty.search)}</Text>}
            </Box>
          )}
        </Box>
      </ResponsiveContainer>
    </Main>
  );
};

const Header = ({ value, color }: { value: string; color?: string }) => (
  <Text size={"small"} weight={"bold"} color={color}>
    {value}
  </Text>
);

const Label = ({ value, isActive }: { value: string; isActive: boolean }) => (
  <Box
    align={"center"}
    justify={"center"}
    background={"light-2"}
    pad={"xsmall"}
    round={"xsmall"}
    width={{ max: "80px" }}
    margin={{ horizontal: "auto" }}
    style={{ opacity: isActive ? 1 : 0.5 }}
  >
    <Text size={"xsmall"}>{value}</Text>
  </Box>
);

const StateFilter = ({
  visible,
  value,
  onChange,
}: {
  visible: boolean;
  value: string;
  onChange: (value: string) => any;
}) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      { value: "all", label: t(tk.spaces.filter.state.default) },
      { value: "concept", label: t(tk.spaceState.concept) },
      { value: "active", label: t(tk.spaceState.active) },
      { value: "inactive", label: t(tk.spaceState.inactive) },
    ],
    [t]
  );

  const _value = useMemo(() => options.find((option) => option.value === value), [options, value]);

  const handleChange = useCallback(({ value }: any) => onChange(value.value), [onChange]);

  if (!visible) return null;

  return (
    <Select
      options={options}
      value={_value}
      valueKey={"value"}
      labelKey={"label"}
      valueLabel={<Text size={"small"}>{_value?.label}</Text>}
      size={"small"}
      plain={true}
      onChange={handleChange}
    />
  );
};

const textColor = (isActive: boolean) => (!isActive ? "status-disabled" : undefined);
