import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/cs";
import "moment/locale/sk";
import "moment/locale/en-gb";
import "moment/locale/de";

// cs-CZ format is required by moment and i18n to use interpolation correctly
export enum Language {
  Cs = "cs-CZ",
  Sk = "sk-SK",
  En = "en-GB",
  De = "de-DE",
}

export const initTranslations = async (activeLang: Language) => {
  moment.locale(activeLang);

  await i18n.use(initReactI18next).init({
    resources: {
      [Language.Cs]: require("translations/cs.json"),
      [Language.Sk]: require("translations/sk.json"),
      [Language.En]: require("translations/en.json"),
      [Language.De]: require("translations/de.json"),
    },
    lng: activeLang,
    fallbackLng: Language.Cs,

    interpolation: {
      escapeValue: false,
    },
  });
};

export const changeLanguage = async (language: Language) => {
  if (language === i18n.language) return;

  moment.locale(language);
  await i18n.changeLanguage(language);
};

export { useTranslation };
export * from "./keys";
