import { ChangeEvent, useCallback, useState } from "react";

type Event = ChangeEvent<HTMLInputElement>;

export const useTableCheckboxes = (items: { id: string }[]) => {
  const [checked, setChecked] = useState<string[]>([]);

  const check = useCallback(
    (e: Event, id: string) => {
      setChecked(e.target.checked ? [...checked, id] : checked.filter((_id) => _id !== id));
    },
    [checked]
  );

  const checkAll = useCallback(
    (e: Event) => {
      setChecked(e.target.checked ? items.map(({ id }) => id) : []);
    },
    [items]
  );

  const clear = useCallback(() => setChecked([]), []);

  return { checked, check, checkAll, clear };
};
