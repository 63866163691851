import { useCallback, useEffect, useMemo } from "react";
import useStore, { createStore } from "global-hook-store";
import { useLocalStorage } from "react-use";
import { Languages, mapConnection, OwnedOrganizationFragment, resolveError, useAuth, useMeQuery } from "api";
import { changeLanguage, Language } from "translations";

export const LS_ACTIVE_ORGANIZATION = "@moyobo-webadmin/active-organization-id";

const activeOrganizationStore = createStore({ id: "" }, { setId: (_state, id: string) => ({ id }) });

export const useMe = (setDefaultActiveOrganization: boolean = false, cacheAndNetwork: boolean = false) => {
  const { isLoggedIn } = useAuth();
  const { state, actions } = useStore(activeOrganizationStore);
  const [storedActiveOrg, setStoredActiveOrg] = useLocalStorage<string>(LS_ACTIVE_ORGANIZATION, "");

  const { data, loading, refetch } = useMeQuery({
    skip: !isLoggedIn(),
    fetchPolicy: cacheAndNetwork ? "cache-and-network" : undefined,
    onError: (e) => resolveError(e),
  });

  const me = useMemo(() => data?.me, [data]);
  const organizations = useMemo(() => mapConnection<OwnedOrganizationFragment>(me?.ownedOrganizations), [me]);

  const getOrganization = useCallback((id: string) => organizations.find((o) => o.id === id), [organizations]);
  const getActiveOrganization = useCallback(() => getOrganization(state.id), [getOrganization, state.id]);
  const setActiveOrganization = useCallback(
    async (id: string) => {
      setStoredActiveOrg(id);
      await actions.setId(id);
    },
    [actions, setStoredActiveOrg]
  );

  const activeOrganization = useMemo(() => getActiveOrganization(), [getActiveOrganization]);

  const activeOrganizationSpaceCount = useMemo(() => activeOrganization?.spaces.edges.length || 0, [
    activeOrganization,
  ]);

  /**
   * Set default active organization.
   * If active organization in localStorage and it's in my organizations, use stored value, else use first.
   * */
  useEffect(() => {
    if (!setDefaultActiveOrganization || !!state.id || !organizations.length) return;

    if (!!storedActiveOrg && !!getOrganization(storedActiveOrg)) {
      actions.setId(storedActiveOrg).finally();
      return;
    }

    actions.setId(organizations[0].id).finally();
  }, [organizations, state, actions, storedActiveOrg, getOrganization, setDefaultActiveOrganization]);

  useEffect(() => {
    if (!me?.language) return;

    let language: Language = Language.Cs;
    if (me.language === Languages.Sk) language = Language.Sk;
    if (me.language === Languages.En) language = Language.En;
    if (me.language === Languages.De) language = Language.De;

    changeLanguage(language).finally();
  }, [me]);

  return {
    me,
    organizations,
    loading,
    refetch,
    getOrganization,
    activeOrganization,
    activeOrganizationSpaceCount,
    setActiveOrganization,
  };
};
