import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTitle } from "react-use";
import { useMe, useMedia, useNotification } from "hooks";
import { MediaTypes, useOrganizationUpdateMutation } from "api";
import { routes } from "routes";
import { tk, useTranslation } from "translations";

const inputs = { name: "name", officialName: "officialName", crn: "crn", tin: "tin" };

type Value = typeof inputs;

const defaultValue = { [inputs.name]: "", [inputs.officialName]: "", [inputs.crn]: "", [inputs.tin]: "" } as Value;

export const useOrganizationEdit = () => {
  const { t } = useTranslation();

  useTitle(t(tk.organizationEdit.heading) + t(tk.common.documentTitleSuffix));

  const history = useHistory();
  const notification = useNotification();
  const media = useMedia(MediaTypes.OrganizationLogo);

  const { activeOrganization } = useMe();

  const [update, { loading: loadingUpdate }] = useOrganizationUpdateMutation();
  const loading = useMemo(() => loadingUpdate || media.loading, [loadingUpdate, media.loading]);

  const [value, setValue] = useState<Value>(defaultValue);

  const handleSubmit = useCallback(async () => {
    if (!activeOrganization || loading) return;

    try {
      const logo = await media.upload();

      await update({ variables: { input: { organization: activeOrganization.id, ...value, logo } } });

      await notification.show(t(tk.organizationEdit.success));

      history.replace(routes.organization);
    } catch (e) {
      await notification.show(t(tk.errors.general), true);
    }
  }, [activeOrganization, loading, media, update, value, notification, t, history]);

  const handleCancel = useCallback(() => history.replace(routes.organization), [history]);

  const handleChange = useCallback((_value: any) => setValue(_value), []);

  useEffect(() => {
    let isUnmounted = false;

    if (isUnmounted || !activeOrganization) return;

    const { name, officialName, crn, tin } = activeOrganization;
    setValue({ name, officialName, crn, tin: tin ?? "" });

    return () => {
      isUnmounted = true;
    };
  }, [activeOrganization]);

  return {
    inputs,
    media,
    data: {
      activeOrganization,
    },
    state: {
      loading,
      value,
    },
    handlers: {
      handleChange,
      handleSubmit,
      handleCancel,
    },
  };
};
